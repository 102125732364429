import { Component, ViewEncapsulation } from '@angular/core';
import { IComboBase } from '../../../interfaces/base/base.interface';
import { monthOptions } from '../../../utils/helpers';
import { CompanyService } from '../../../services/company.service';
import { EmployeeLocalService } from '../../services/employee.service';
import { Router } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';
import { ModalRejectComponent } from '../../../shared/modals/modal-reject/modal-reject.component';

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EmployeeComponent {
  dataSource: any[] = [];
  mostrar: boolean = false;
  dataToEdit: any = null;
  mostrarModalEditar: boolean = false;
  mostrarModalDetalle: boolean = false;
  dataDetail: any = null;
  originalData: any[] = [];

  viewDeleted: boolean = false;
  employeeId: number = 0;

  // Opciones del combo renovación
  renewOptions: IComboBase[] = [
    { key: '0', text: 'Todos' },
    { key: '1', text: 'Próximos a renovar' },
    { key: '2', text: 'Contrato en vigencia' },
  ];
  currentRenew: IComboBase | null = null;
  selectedRenew: IComboBase | null = null;

  // Opciones del combo
  monthOptions: IComboBase[] = [{ key: '00', text: 'Todos' }, ...monthOptions];
  currentMonth: IComboBase | null = null;
  selectedMonth: IComboBase | null = null;

  isLoading: boolean = true;

  // Término de búsqueda
  searchTerm = '';
  userData: any = null;

  headerClasses: { [key: string]: string } = {
    '1': 'header-keola',
    '2': 'header-inresort',
    '3': 'header-inclub',
    '4': 'header-ribera',
    '5': 'header-keolafood',
    '6': 'header-valle',
  };

  currentHeaderClass: string = 'header-keola';

  constructor(
    private employeeLocalService: EmployeeLocalService,
    private companyService: CompanyService,
    private dialogService: DialogService,
    private router: Router
  ) {
    this.currentMonth = this.monthOptions[0];
    this.currentRenew = this.renewOptions[0];
    this.selectedMonth = { key: '00', text: 'Todos' };
    this.selectedRenew = this.renewOptions[0]; 
  }

  tabs: IComboBase[] = [];
  selectedTabKey: string | null = null;

  displayedColumns = [
    { key: 'names', header: 'Nombres' },
    { key: 'lastNames', header: 'Apellidos' },
    { key: 'employeeDocumentNumber', header: 'DNI' },
    { key: 'cityName', header: 'Ciudad' },
    { key: 'countryName', header: 'País' },
    { key: 'phoneEmergency', header: 'Cont. de emer.' },
    { key: 'email', header: 'Correo' },
    { key: 'employeeState', header: 'Status' },
  ];

  actionButtons = [
    {
      icon: 'pi pi-eye',
      color: '#ED672A',
      tooltip: 'Ver',
      action: (row: any) => {
        this.dataDetail = {
          ...row,
          empresa: this.tabs.find((tab) => tab.key === this.selectedTabKey),
        };
        this.mostrarModalDetalle = true;
      },
      visible: (row: any) => true,
    },
    {
      icon: 'pi pi-pen-to-square',
      color: '#ED672A',
      tooltip: 'Editar',
      action: (row: any) => {
        this.dataToEdit = {
          ...row,
          empresa: this.tabs.find((tab) => tab.key === this.selectedTabKey),
        };
        this.mostrarModalEditar = true;
      },
      visible: (row: any) => true,
    },
    {
      icon: 'pi pi-trash',
      color: '#ED672A',
      tooltip: 'Eliminar',
      action: (row: any) => {
        this.employeeId = row.solicitudId;
        this.viewDeleted = true;
      },
      visible: (row: any) => true,
    },
    {
      icon: 'pi pi-exclamation-triangle',
      color: 'red',
      tooltip: 'Alerta',
      action: (row: any) => {
        this.openTooltipModal(
          'Renovación de contrato',
          `El usuario tiene como fecha de fin de contrato el día ${row.endDate}, revisar su renovación.`
        );
      },
      visible: (row: any) => (this.isEndDateWithinOneMonth(row.endDate) && row.stateLastContractId == 3),
    },
  ];

  ngOnInit(): void {
    const userDataString = localStorage.getItem('access');
    if (userDataString) {
      this.userData = JSON.parse(userDataString);
    }
    this.companyService.comboCompany().subscribe({
      next: (data) => {
        this.tabs = data;
        this.selectedTabKey = this.tabs[0].key;
        this.onTabSelected(this.selectedTabKey);
      },
      error: (err) => {
        console.error('Error al obtener la lista de empresas:', err);
        this.filterData();
      },
    });
  }

  onNewRegister(): void {
    this.router.navigate(['/dashboard/employee/register']);
  }

  onExport(): void {
    console.log('Implementar función de exportar data');
  }

  onDialogClose() {
    this.mostrar = false;
  }

  onDialogDetailClose() {
    this.mostrarModalDetalle = false;
    this.dataDetail = null;
  }

  onMonthChange(selected: IComboBase): void {
    this.selectedMonth = selected;
    this.filterData();
  }

  onRenewChange(selected: IComboBase): void {
    this.selectedRenew = selected;
    this.applyLocalFilters(); // Solo filtrado local sin llamar al servicio
  }

  isWithinOneMonth(endDateString: string): boolean {
    if (!endDateString) return false;

    try {
      const [day, month, year] = endDateString.split('/').map(Number);
      const endDate = new Date(year, month - 1, day);
      const today = new Date();
      const oneMonthFromNow = new Date(today);
      oneMonthFromNow.setMonth(today.getMonth() + 1);

      return endDate >= today && endDate <= oneMonthFromNow;
    } catch (e) {
      console.error('Error parsing date:', endDateString, e);
      return false;
    }
  }
  // Método para parsear una fecha en formato dd/mm/yyyy a un objeto Date
  parseDate(dateString: string): Date {
    const [day, month, year] = dateString.split('/');
    return new Date(`${year}-${month}-${day}`);
  }

  openTooltipModal(title: string, description: string) {
    this.dialogService.open(ModalRejectComponent, {
      closable: false,
      data: {
        title: title,
        text: description,
        icon: 'pi pi-exclamation-triangle',
        showButton: true,
      },
    });
  }

  onSearch(query: string): void {
    this.searchTerm = query;
    this.filterData();
  }

  isEndDateWithinOneMonth(endDate: string): boolean {
    if (!endDate) return false;

    const [day, month, year] = endDate.split('/').map(Number);
    const end = new Date(year, month - 1, day);
    const today = new Date();
    const oneMonthFromNow = new Date(today);
    oneMonthFromNow.setMonth(today.getMonth() + 1);
    return end >= today && end <= oneMonthFromNow;
  }

  filterData(): void {
    this.isLoading = true;
  
    const filters = {
      search: this.searchTerm,
      month: this.selectedMonth?.key == '00' ? '' : this.selectedMonth?.key,
      companyId: this.selectedTabKey || null,
    };
  
    this.employeeLocalService.postEmployeeByCompany(filters).subscribe({
      next: (data: any[]) => {
        this.originalData = [...data];
        this.applyLocalFilters();
        this.isLoading = false;
      },
      error: (err: any) => {
        this.isLoading = false;
        console.error('Error al obtener los datos:', err);
      },
    });
  }

  applyLocalFilters(): void {
    if (!this.originalData || this.originalData.length === 0) return;
  
    let filteredData = [...this.originalData];
  
    if (this.selectedRenew && this.selectedRenew.key !== '0') {
      console.log(`Aplicando filtro: ${this.selectedRenew.text}`);
      
      filteredData = filteredData.filter(item => {
        const stateOk = item.stateLastContractId == 3; 
        
        console.log(`Item ID: ${item.employeeId} | State: ${item.stateLastContractId} | EndDate: ${item.endDate} | StateOk: ${stateOk}`);
  
        if (this.selectedRenew?.key === '1') {
          const withinMonth = this.isWithinOneMonth(item.endDate);
          // console.log(`Próximos a renovar - Within month: ${withinMonth}`);
          return stateOk && withinMonth;
        } else if (this.selectedRenew?.key === '2') {
          const notWithinMonth = !this.isWithinOneMonth(item.endDate);
          // console.log(`Contrato vigente - Not within month: ${notWithinMonth}`);
          return notWithinMonth;
        }
        return true;
      });
    }
  
    console.log('Datos después de filtrar:', filteredData);
    this.dataSource = filteredData;
  }

  onTabSelected(tabKey: string): void {
    this.selectedTabKey = tabKey;
    console.log('this.selectedTabKey ', this.selectedTabKey);

    this.currentHeaderClass = this.headerClasses[tabKey] || 'header-keola';
    this.filterData();
  }

  onRowSelected(row: any): void {}

  onRegisterComplete(): void {
    this.filterData();
  }

  onEditComplete(): void {
    this.filterData();
  }

  onDeleteComplete(): void {
    this.filterData();
  }

  formatDate(dateString: string): string {
    if (!dateString) return '';

    // Mapeo de nombres de meses en inglés a números
    const monthsMap: { [key: string]: string } = {
      January: '01',
      February: '02',
      March: '03',
      April: '04',
      May: '05',
      June: '06',
      July: '07',
      August: '08',
      September: '09',
      October: '10',
      November: '11',
      December: '12',
    };

    // Extraer los datos de la fecha usando una expresión regular
    const dateParts = dateString.match(/(\w+)\s(\d+)\sdel\s(\d+)/);
    if (!dateParts) return dateString;

    const month = monthsMap[dateParts[1]];
    const day = dateParts[2].padStart(2, '0');
    const year = dateParts[3].slice(-2);

    return `${day}/${month}/${year}`;
  }
}
