import imageCompression from 'browser-image-compression';
import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import {
  IComboBase,
  IComboBaseCountry,
} from '../../../interfaces/base/base.interface';
import { CompanyService } from '../../../services/company.service';
import { DialogService } from 'primeng/dynamicdialog';
import { ModalSuccessComponent } from '../../../shared/modals/modal-success/modal-success.component';
import { ModalLoadingComponent } from '../../../shared/modals/modal-loading/modal-loading.component';
import { ModalRejectComponent } from '../../../shared/modals/modal-reject/modal-reject.component';
import { CountryService } from '../../../services/country.service';
import { CityService } from '../../../services/city.service';
import { TypeDocumentService } from '../../../services/type-document.service';
import { EmployeeLocalService } from '../../services/employee.service';
import { Router } from '@angular/router';
import { JobPositionService } from '../../../services/job-position.service';
import { CurrencyService } from '../../../services/currency.service';
import { TypeContractService } from '../../../services/type-contracts.service';
import { WorkingModeService } from '../../../services/working-modes.service';
import { StateContractService } from '../../../services/state-contracts.service';
import { EmployeeService } from '../../../services/employee.service';
import { ContinuityContractsService } from '../../../services/continuity-contracts.service';
import { AmountRenewalContractsService } from '../../../services/amount-renewal-contracts.service';

interface IAllowances {
  computerAllowance?: number | null;
  internetAllowance?: number | null;
  electricityAllowance?: number | null;
  transportationAllowance?: number | null;
}

@Component({
  selector: 'app-employee-register',
  templateUrl: './employee-register.component.html',
  styleUrls: ['./employee-register.component.scss'],
  providers: [DialogService],
})
export class EmployeeRegisterComponent {
  userData: any = null;
  selectedCompany: IComboBase | null = null;
  selectedCountry: IComboBaseCountry | null = null;
  selectedCity: IComboBase | null = null;
  selectedTypeDocument: IComboBase | null = null;
  selectedStatusEmployee: IComboBase | null = null;
  selectedGender: IComboBase | null = null;
  selectedPhoneCode: string | null = null;
  name: string | null = null;
  lastname: string | null = null;
  document: string | null = null;
  phone: number | null = null;
  phoneEmergency: number | null = null;
  address: string | null = null;
  email: string | null = null;
  currentFile: File | null = null;
  currentPhoto: File | null = null;
  selectedPhoto: string | null = null;
  birthDate: Date | null = null;
  minBirthDate: Date;
  maxBirthDate: Date;

  selectedTypeContract: IComboBase | null = null;
  selectedJobPosition: IComboBase | null = null;
  selectedEmployee: IComboBase | null = null;
  selectedRenewContract: IComboBase | null = null;
  selectedCurrency: IComboBase | null = null;
  selectedContinuity: IComboBase | null = null;
  selectedWorkingMode: IComboBase | null = null;
  selectedCurrencyCode: string | null = null;

  filteredOptions: IComboBase[] = [];
  companies: IComboBase[] = [];
  country: IComboBaseCountry[] = [];
  city: IComboBase[] = [];
  typeDocuments: IComboBase[] = [];
  statusEmployee: IComboBase[] = [];
  genders: IComboBase[] = [];

  salary: number | null = null;
  basicSalary: number | null = null;
  performanceBonus: number | null = null;
  computerAllowance: number | null = null;
  internetAllowance: number | null = null;
  electricityAllowance: number | null = null;
  transportationAllowance: number | null = null;

  typeContracts: IComboBase[] = [];
  jobPositions: IComboBase[] = [];
  employees: IComboBase[] = [];
  renewContracts: IComboBase[] = [];
  currencies: IComboBase[] = [];
  workingModes: IComboBase[] = [];
  continues: IComboBase[] = [];
  statusContracts: IComboBase[] = [];

  startDate: Date | null = null;
  endDate: Date | null = null;
  minDate: Date | null = null;
  maxStartDate: Date | null = null;
  maxEndDate: Date | null = null;

  countryId: string | null = null;
  companyId: string | null = null;
  employeeId: string | null = null;
  stateContractId: number | null = null;
  isDisabledEmployee: boolean = true;
  isDisabledContract: boolean = true;
  activeIndex: number = 0;
  maxLength: number | null = null;

  @ViewChild('pdfUploader') pdfUploader: any;
  @ViewChild('imageUploader') imageUploader: any;

  selectedFileName: string | null = null;

  hasInteracted: { [key: string]: boolean } = {
    selectedPhoto: false,
    // selectedFileName: false,
    company: false,
    typeDocument: false,
    document: false,
    name: false,
    lastname: false,
    country: false,
    city: false,
    phone: false,
    email: false,
    address: false,
    phoneEmergency: false,
    statusEmployee: false,
    gender: false,
    birthDate: false,
    currentFile: false,
    jobPosition: false,
    currency: false,
    salary: false,
    basicSalary: false,
    performanceBonus: false,
    computerAllowance: false,
    internetAllowance: false,
    electricityAllowance: false,
    transportationAllowance: false,
  };

  constructor(
    private employeeLocalService: EmployeeLocalService,
    private employeeService: EmployeeService,
    private companyService: CompanyService,
    private countryService: CountryService,
    private cityService: CityService,
    private typeDocumentService: TypeDocumentService,
    private jobPositionService: JobPositionService,
    private currencyService: CurrencyService,
    private typeContractService: TypeContractService,
    private workingModeService: WorkingModeService,
    private stateContractService: StateContractService,
    private continuityContractsService: ContinuityContractsService,
    private amountRenewalContractsService: AmountRenewalContractsService,
    private dialogService: DialogService,
    private router: Router
  ) {
    const today = new Date();

    // Edad mínima: 18 años
    this.maxBirthDate = new Date();
    this.maxBirthDate.setFullYear(today.getFullYear() - 18);

    // Edad máxima: 100 años
    this.minBirthDate = new Date();
    this.minBirthDate.setFullYear(today.getFullYear() - 100);
  }

  ngOnInit(): void {
    this.resetForm();
    this.calculateSalary();

    const userDataString = localStorage.getItem('access');
    if (userDataString) {
      this.userData = JSON.parse(userDataString);
    }
    this.companyService.comboCompany().subscribe({
      next: (data) => {
        this.companies = data;
      },
      error: (err) => {
        console.error('Error al obtener la lista de empresas:', err);
      },
    });

    this.countryService.comboCountry().subscribe({
      next: (data) => {
        this.country = data;
      },
      error: (err) => {
        console.error('Error al obtener la lista de paises:', err);
      },
    });

    this.currencyService.comboCurrency().subscribe({
      next: (data) => {
        this.currencies = data;
      },
      error: (err) => {
        console.error('Error al obtener la lista de monedas:', err);
      },
    });

    this.jobPositionService.comboJobPosition().subscribe({
      next: (data) => {
        this.jobPositions = data;
      },
      error: (err) => {
        console.error('Error al obtener la lista de puestos:', err);
      },
    });

    this.typeContractService.comboTypeContract().subscribe({
      next: (data) => {
        this.typeContracts = data;
      },
      error: (err) => {
        console.error('Error al obtener la lista de contratos:', err);
      },
    });

    this.workingModeService.comboWorkingMode().subscribe({
      next: (data) => {
        this.workingModes = data;
      },
      error: (err) => {
        console.error('Error al obtener la lista de modalidades:', err);
      },
    });

    this.stateContractService.comboStateContract().subscribe({
      next: (data) => {
        this.statusContracts = data;
      },
      error: (err) => {
        console.error('Error al obtener la lista de modalidades:', err);
      },
    });

    this.continuityContractsService.comboContinuityContracts().subscribe({
      next: (data) => {
        this.continues = data;
      },
      error: (err) => {
        console.error('Error al obtener la lista de modalidades:', err);
      },
    });

    this.amountRenewalContractsService.comboAmountRenewalContracts().subscribe({
      next: (data) => {
        this.renewContracts = data;
      },
      error: (err) => {
        console.error('Error al obtener la lista de modalidades:', err);
      },
    });

    this.statusEmployee = [
      { key: '1', text: 'Activo' },
      { key: '2', text: 'Inactivo' },
    ];

    this.genders = [
      { key: '1', text: 'Femenino' },
      { key: '2', text: 'Masculino' },
    ];
  }

  validateFormEmployee() {
    this.isDisabledEmployee = !(
      this.selectedCompany &&
      this.selectedTypeDocument &&
      this.document &&
      this.birthDate &&
      this.selectedGender &&
      this.name &&
      this.lastname &&
      this.selectedCountry &&
      this.selectedCity &&
      this.phone &&
      this.email &&
      this.address &&
      this.phoneEmergency &&
      this.selectedStatusEmployee
    );
  }

  validateFormContract() {
    const baseValid = Boolean(
      this.selectedTypeContract &&
        this.selectedJobPosition &&
        this.selectedWorkingMode &&
        this.startDate &&
        this.endDate &&
        this.selectedContinuity &&
        this.selectedCurrency &&
        this.salary !== null &&
        this.basicSalary !== null &&
        this.performanceBonus !== null
    );

    if (!baseValid) {
      this.isDisabledContract = true;
      return;
    }

    let workingModeValid = true;

    if (this.selectedWorkingMode) {
      console.log(
        `Validating working mode ${
          this.selectedWorkingMode.key
        } bonuses y su tipo ${typeof this.selectedWorkingMode.key}`
      );

      switch (String(this.selectedWorkingMode?.key)) {
        case '1': // Remoto
          workingModeValid =
            this.computerAllowance != null &&
            this.internetAllowance != null &&
            this.electricityAllowance != null;
          break;

        case '2': // Presencial
          workingModeValid = this.transportationAllowance != null;
          break;

        case '3': // Híbrido
          workingModeValid =
            this.computerAllowance != null &&
            this.internetAllowance != null &&
            this.electricityAllowance != null &&
            this.transportationAllowance != null;
          break;

        default:
          workingModeValid = false;
      }
    }

    let salaryValid = false;
    if (
      this.salary !== null &&
      this.basicSalary !== null &&
      this.performanceBonus !== null
    ) {
      salaryValid =
        this.salary > 0 &&
        this.salary >= this.basicSalary + this.performanceBonus;
    }

    this.isDisabledContract = !(baseValid && workingModeValid && salaryValid);
  }

  // Método para filtrar opciones
  filterOptions(event: any, type: string) {
    const query = event.query.toLowerCase();
    let options: IComboBase[] = [];
    switch (type) {
      case 'company':
        options = this.companies;
        break;
      case 'country':
        options = this.country;
        break;
      case 'city':
        options = this.city;
        break;
      case 'typeDocument':
        options = this.typeDocuments;
        break;
      case 'statusEmployee':
        options = this.statusEmployee;
        break;
      case 'gender':
        options = this.genders;
        break;
      case 'renewContract':
        options = this.renewContracts;
        break;
      case 'statusContract':
        options = this.statusContracts;
        break;
      case 'typeContract':
        options = this.typeContracts;
        break;
      case 'employee':
        options = this.employees;
        break;
      case 'currency':
        options = this.currencies;
        break;
      case 'jobPosition':
        options = this.jobPositions;
        break;
      case 'workingMode':
        options = this.workingModes;
        break;
      default:
        console.error('Tipo no reconocido:', type);
    }
    this.filteredOptions = options.filter((option) =>
      option.text.toLowerCase().includes(query)
    );
  }

  // Manejar selección
  onSelect(event: any, type: string) {
    this.hasInteracted[type] = true;

    switch (type) {
      case 'company':
        this.selectedCompany = event.value ?? null;
        this.companyId = this.selectedCompany?.key!;

        this.selectedEmployee = null;

        if (this.companyId) {
          this.employeeService.comboEmployee(this.companyId).subscribe({
            next: (data) => {
              this.employees = data;
            },
            error: (err) => {
              console.error('Error al obtener la lista de colaboradores:', err);
            },
          });
        }
        break;
      case 'country':
        this.selectedCountry = event.value ?? null;
        this.selectedPhoneCode = event.value?.phonecode ?? null;
        this.countryId = this.selectedCountry?.key!;

        this.selectedCity = null;
        this.selectedTypeDocument = null;

        if (this.countryId) {
          this.cityService.comboCity(this.countryId).subscribe({
            next: (data) => {
              this.city = data;
            },
            error: (err) => {
              console.error('Error al obtener la lista de colaboradores:', err);
            },
          });

          this.typeDocumentService.comboTypeDocument(this.countryId).subscribe({
            next: (data) => {
              this.typeDocuments = data;
            },
            error: (err) => {
              console.error('Error al obtener la lista de colaboradores:', err);
            },
          });
        }
        break;
      case 'city':
        this.selectedCity = event.value ?? null;
        break;
      case 'typeDocument':
        this.selectedTypeDocument = event.value ?? null;

        if (this.selectedTypeDocument?.key.toString() === '1') {
          this.maxLength = 8;
        } else {
          this.maxLength = null;
        }
        break;
      case 'statusEmployee':
        this.selectedStatusEmployee = event.value ?? null;
        break;
      case 'gender':
        this.selectedGender = event.value ?? null;
        break;
      case 'renewContract':
        this.selectedRenewContract = event.value ?? null;
        break;
      case 'typeContract':
        this.selectedTypeContract = event.value ?? null;
        break;
      case 'employee':
        this.selectedEmployee = event.value ?? null;
        break;
      case 'currency':
        this.selectedCurrency = event.value ?? null;
        this.selectedCurrencyCode =
          this.selectedCurrency?.key == '1' ? 'S/.' : '$';
        break;
      case 'jobPosition':
        this.selectedJobPosition = event.value ?? null;
        break;
      case 'workingMode':
        this.selectedWorkingMode = event.value ?? null;
        this.calculateSalary();
        break;
      case 'continue':
        this.selectedContinuity = event.value ?? null;
        break;
      default:
        console.error('Tipo no reconocido:', type);
    }

    this.validateFormEmployee();
    this.validateFormContract();
  }

  // Manejar el cambio de archivo
  openFileUpload(type: string) {
    if (type === 'pdf' && this.pdfUploader) {
      this.pdfUploader.choose();
    } else if (type === 'image' && this.imageUploader) {
      this.imageUploader.choose();
    } else {
      console.error('El fileUploader no está disponible.');
    }
  }

  onNext() {
    this.activeIndex = 1;
  }

  getRelevantAllowances(): string[] {
    if (!this.selectedWorkingMode) return [];

    const workingModeKey = String(this.selectedWorkingMode.key);

    const allowances: string[] = [];

    if (workingModeKey == '1' || workingModeKey == '3') {
      allowances.push(
        'computerAllowance',
        'internetAllowance',
        'electricityAllowance'
      );
    }

    if (workingModeKey == '2' || workingModeKey == '3') {
      allowances.push('transportationAllowance');
    }

    return allowances;
  }

  async onRegisterEmployeeAndContract() {
    const loadingRef = this.dialogService.open(ModalLoadingComponent, {
      closable: false,
    });

    const relevantAllowances = this.getRelevantAllowances();

    const request: any = {
      userId: this.userData.employeeId,
      companyId: this.selectedCompany?.key,
      typeDocumentId: this.selectedTypeDocument?.key,
      employeeDocumentNumber: this.document,
      names: this.name,
      lastNames: this.lastname,
      countryId: this.selectedCountry?.key,
      cityId: this.selectedCity?.key,
      address: this.address,
      email: this.email,
      phone: this.phone?.toString(),
      phoneEmergency: this.phoneEmergency?.toString(),
      employeeState: (this.selectedStatusEmployee?.key == '1'
        ? true
        : false
      ).toString(),
      gender: this.selectedGender?.key == '1' ? 'F' : 'M',
      birthDate: this.birthDate?.toISOString().replace('Z', ''),
      jobPositionId: this.selectedJobPosition?.key,
      typeContractId: this.selectedTypeContract?.key,
      workingModeId: this.selectedWorkingMode?.key,
      currencyId: this.selectedCurrency?.key,
      startDate: this.startDate?.toISOString().replace('Z', ''),
      endDate: this.endDate?.toISOString().replace('Z', ''),
      stateContractId: this.stateContractId,
      continuityContractId: this.selectedContinuity?.key,
      // amountrenewalid: this.selectedRenewContract?.key,
      basicSalary: this.basicSalary,
      performanceBonus: this.performanceBonus,
      salary: this.salary,
    };

    relevantAllowances.forEach((allowance) => {
      request[allowance] = (this as IAllowances)[
        allowance as keyof IAllowances
      ];
    });

    const formData = new FormData();

    const appendIfDefined = (key: string, value: any) => {
      if (value !== undefined && value !== null) {
        formData.append(key, value);
      }
    };

    Object.entries(request).forEach(([key, value]) =>
      appendIfDefined(key, value)
    );

    if (this.currentPhoto) {
      formData.append('imageEmployee', this.currentPhoto);
    }

    if (this.currentFile) {
      formData.append('fileContract', this.currentFile);
    }

    // Llamar al servicio para registrar el empleado
    this.employeeLocalService.createEmployeeAndContract(formData).subscribe({
      next: (response) => {
        loadingRef.close();
        const ref = this.dialogService.open(ModalSuccessComponent, {
          header: '',
          data: {
            text: 'Tu registro ha sido realizado con éxito.',
            title: '¡Éxito!',
            icon: 'pi pi-check-circle',
            showButton: true,
            buttonText: 'Entendido',
          },
        });
        this.goBack();
      },

      error: (err) => {
        loadingRef.close();
        let errorMessage =
          'Lo sentimos, ocurrió un error. Por favor, inténtelo nuevamente.';
        if (err.error) {
          if (typeof err.error === 'string') {
            errorMessage = err.error;
          } else if (typeof err.error === 'object') {
            if (err.error.error && typeof err.error.error === 'object') {
              errorMessage = Object.values(err.error.error).join(' ');
            } else {
              errorMessage = Object.values(err.error).join(' ');
            }
          }
        }
        const ref = this.dialogService.open(ModalRejectComponent, {
          header: '',
          data: {
            text: errorMessage,
            title: '¡Lo sentimos!',
            showButton: true,
            buttonText: 'Entendido',
          },
        });
        // this.resetForm();
        console.error('Error al registrar el contrato:', err);
      },
    });
  }

  resetForm() {
    this.selectedCompany = null;
    this.selectedTypeDocument = null;
    this.selectedStatusEmployee = null;
    this.selectedCountry = null;
    this.selectedCity = null;
    this.selectedGender = null;
    this.selectedContinuity = null;
    this.selectedRenewContract = null;
    this.selectedTypeContract = null;
    this.selectedJobPosition = null;
    this.selectedWorkingMode = null;
    this.selectedCurrency = null;
    this.birthDate = null;
    this.name = null;
    this.lastname = null;
    this.email = null;
    this.address = null;
    this.phone = null;
    this.phoneEmergency = null;
    this.startDate = null;
    this.endDate = null;
    this.salary = null;
    this.basicSalary = null;
    this.performanceBonus = null;
    this.computerAllowance = null;
    this.internetAllowance = null;
    this.electricityAllowance = null;
    this.transportationAllowance = null;
    this.document = null;
    this.currentPhoto = null;
    this.currentFile = null;
    this.selectedPhoto = null;
    this.selectedPhoneCode = null;
    this.stateContractId = 4;
    this.selectedFileName = null;

    this.isDisabledEmployee = true;

    this.hasInteracted = {
      selectedPhoto: false,
      // selectedFileName: false,
      company: false,
      typeDocument: false,
      document: false,
      name: false,
      lastname: false,
      country: false,
      city: false,
      phone: false,
      email: false,
      address: false,
      phoneEmergency: false,
      statusEmployee: false,
      gender: false,
      birthDate: false,
      continue: false,
    };

    if (this.pdfUploader) {
      this.pdfUploader.clear();
    }

    if (this.imageUploader) {
      this.imageUploader.clear();
    }

    this.validateFormEmployee();
  }

  onChangeInput(event: any, type: string) {
    this.hasInteracted[type] = true;
    switch (type) {
      case 'company':
        this.selectedCompany = null;
        break;
      case 'country':
        this.selectedCountry = null;
        break;
      case 'city':
        this.selectedCity = null;
        break;
      case 'typeDocument':
        this.selectedTypeDocument = null;
        break;
      case 'statusEmployee':
        this.selectedStatusEmployee = null;
        break;
      case 'gender':
        this.selectedGender = null;
        break;
      case 'birthDate':
        this.birthDate = event;
        break;
      case 'name':
        this.name = event;
        break;
      case 'lastname':
        this.lastname = event;
        break;
      case 'address':
        this.address = event;
        break;
      case 'email':
        this.email = event;
        break;
      case 'phone':
        this.phone = event;
        break;
      case 'phoneEmergency':
        this.phoneEmergency = event;
        break;
      case 'document':
        this.document = event;
        break;
      case 'basicSalary':
        this.basicSalary = event;
        this.calculateSalary();
        break;
      case 'performanceBonus':
        this.performanceBonus = event;
        this.calculateSalary();
        break;
      case 'computerAllowance':
        this.computerAllowance = event;
        this.calculateSalary();
        break;
      case 'internetAllowance':
        this.internetAllowance = event;
        this.calculateSalary();
        break;
      case 'electricityAllowance':
        this.electricityAllowance = event;
        this.calculateSalary();
        break;
      case 'transportationAllowance':
        this.transportationAllowance = event;
        this.calculateSalary();
        break;
      case 'salary':
        this.salary = event;
        break;
      case 'startDate':
        this.startDate = event;
        break;
      case 'endDate':
        this.endDate = event;
        break;
      case 'currency':
        this.selectedCurrency = null;
        break;
      case 'workingMode':
        this.selectedWorkingMode = null;
        this.calculateSalary();
        break;
      case 'typeContract':
        this.selectedTypeContract = null;
        break;
      case 'jobPosition':
        this.selectedJobPosition = null;
        break;
      default:
        console.error('Tipo no reconocido:', type);
    }
    this.validateFormEmployee();
    this.validateFormContract();
  }

  validateField(field: string) {
    this.hasInteracted[field] = true;
  }

  onChangeFile(event: any) {
    const selectedFile = event.files[0];
    if (selectedFile) {
      const maxSize = 10 * 1024 * 1024;
      const allowedTypes = ['application/pdf'];

      if (!allowedTypes.includes(selectedFile.type)) {
        const ref = this.dialogService.open(ModalRejectComponent, {
          header: '',
          data: {
            text: 'El archivo no es válido. Solo se permiten archivos PDF.',
            title: '¡Upss!',
            showButton: true,
            buttonText: 'Entendido',
          },
        });
        this.currentFile = null;
        this.selectedFileName = null;
        this.stateContractId = 4;
        return;
      }

      if (selectedFile.size > maxSize) {
        const ref = this.dialogService.open(ModalRejectComponent, {
          header: '',
          data: {
            text: 'El archivo es demasiado grande. El tamaño máximo permitido es de 10 MB.',
            title: '¡Upss!',
            showButton: true,
            buttonText: 'Entendido',
          },
        });
        this.currentFile = null;
        this.selectedFileName = null;
        this.stateContractId = 4;
        return;
      }

      this.currentFile = selectedFile;
      this.selectedFileName = selectedFile.name;
      this.stateContractId = 2;
    } else {
      this.currentFile = null;
      this.selectedFileName = null;
      this.stateContractId = 4;
    }
  }

  onPhotoSelect(event: any) {
    const file = event.files[0];
    if (file) {
      const maxSize = 10 * 1024 * 1024;
      if (file.size > maxSize) {
        const ref = this.dialogService.open(ModalRejectComponent, {
          header: '',
          data: {
            text: 'La imagen es demasiado grande. El tamaño máximo permitido es de 10 MB.',
            title: '¡Upss!',
            showButton: true,
            buttonText: 'Entendido',
          },
        });
        this.validateFormEmployee();
        return;
      }

      this.currentPhoto = file;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.selectedPhoto = e.target.result;
        this.validateFormEmployee();
      };
      reader.readAsDataURL(file);
    }
  }

  editPhoto() {
    this.openFileUpload('image');
    this.validateFormEmployee();
  }

  addPhoto() {
    this.openFileUpload('image');
  }

  removePhoto() {
    this.selectedPhoto = null;
    this.currentPhoto = null;
    this.validateFormEmployee();
  }

  calculateSalary() {
    let total = 0;

    if (this.basicSalary) total += this.basicSalary;
    if (this.performanceBonus) total += this.performanceBonus;

    // Sumar bonos según workingMode
    if (this.selectedWorkingMode) {
      if (
        this.selectedWorkingMode.key == '1' ||
        this.selectedWorkingMode.key == '3'
      ) {
        if (this.computerAllowance) total += this.computerAllowance;
        if (this.internetAllowance) total += this.internetAllowance;
        if (this.electricityAllowance) total += this.electricityAllowance;
      }

      if (
        this.selectedWorkingMode.key == '2' ||
        this.selectedWorkingMode.key == '3'
      ) {
        if (this.transportationAllowance) total += this.transportationAllowance;
      }
    }

    this.salary = total;
    this.validateFormContract();
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    event.dataTransfer!.dropEffect = 'copy';
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();

    if (event.dataTransfer && event.dataTransfer.files.length > 0) {
      const droppedFile = event.dataTransfer.files[0];

      if (this.isValidFile(droppedFile)) {
        this.currentFile = droppedFile;
        this.selectedFileName = droppedFile.name;
        this.stateContractId = 2;
      } else {
        this.currentFile = null;
        this.selectedFileName = null;
        this.stateContractId = 4;

        const ref = this.dialogService.open(ModalRejectComponent, {
          header: '',
          data: {
            text: 'El archivo no es válido. Solo se permiten archivos PDF, DOC o DOCX de hasta 10 MB.',
            title: '¡Upss!',
            showButton: true,
            buttonText: 'Entendido',
          },
        });
      }
    } else {
      this.currentFile = null;
      this.selectedFileName = null;
      this.stateContractId = 4;
    }
  }

  isValidFile(file: File): boolean {
    const allowedExtensions = [
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ];
    const maxSize = 10 * 1024 * 1024;

    if (!allowedExtensions.includes(file.type)) {
      console.log('Tipo de archivo no permitido:', file.type);
      return false;
    }

    if (file.size > maxSize) {
      console.log('Archivo demasiado grande:', file.size);
      return false;
    }

    return true;
  }

  onStartDateChange(selectedStartDate: Date): void {
    if (selectedStartDate) {
      this.startDate = selectedStartDate;

      this.maxEndDate = null;
      this.endDate = null;
    } else {
      this.startDate = null;
      this.maxEndDate = null;
    }
    this.validateFormContract();
  }

  onEndDateChange(selectedEndDate: Date): void {
    if (selectedEndDate && this.startDate && selectedEndDate < this.startDate) {
      this.endDate = null;
    } else {
      this.endDate = selectedEndDate;
    }
    this.validateFormContract();
  }

  onCheckboxChange(value: IComboBase) {
    if (this.selectedContinuity?.key === value.key) {
      this.selectedContinuity = null;
    } else {
      this.selectedContinuity = value;
    }

    this.hasInteracted['continue'] = true;
    this.validateFormContract();
  }

  onlyNumbers(event: KeyboardEvent) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  }

  backStep() {
    this.activeIndex = 0;
  }

  goBack() {
    this.resetForm();
    this.router.navigate(['/dashboard/employee/list']);
  }
}
