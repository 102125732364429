import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IComboBase } from '../interfaces/base/base.interface';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CityService {
  private apiUrl = `${environment.apiUrl}/cities`;

  constructor(private http: HttpClient) {}

  comboCity(countryId: string): Observable<IComboBase[]> {
    return this.http.get<IComboBase[]>(this.apiUrl + '/combo', {
      params: { countryId: countryId },
    });
  }
}
