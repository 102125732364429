<p-dialog
  header="Ver Registro"
  [(visible)]="visible"
  [modal]="true"
  (onHide)="closeDialog()"
  [closable]="true"
  [style]="{ width: '70%', height: '55vh' }"
>
  <div class="loading-spinner" *ngIf="isLoading">
    <p-progressSpinner styleClass="custom-spinner"></p-progressSpinner>
  </div>

  <div *ngIf="!isLoading" class="row">
    <div class="column">
      <div class="row">
        <div class="column">
          <!-- Carga de Imagen -->
          <div class="field">
            <label for="fotoPerfil" class="font-semibold">Foto de perfil</label>
            <div class="profile-photo-container">
              <div class="photo-preview">
                <img
                  *ngIf="selectedPhoto"
                  [src]="selectedPhoto"
                  alt="Foto de perfil"
                  class="profile-photo"
                />
                <i *ngIf="!selectedPhoto" class="pi pi-user"></i>
              </div>
            </div>
          </div>
        </div>

        <div class="column-name">
          <!-- Nombres -->
          <div class="field">
            <label for="name" class="font-semibold">Nombres</label>
            <input
              pInputText
              id="name"
              type="text"
              [(ngModel)]="name"
              [placeholder]="'Ingresa nombres completos'"
              [disabled]="true"
            />
          </div>

          <!-- Apellidos -->
          <div class="field">
            <label for="lastname" class="font-semibold">Apellidos</label>
            <input
              pInputText
              id="lastname"
              type="text"
              [(ngModel)]="lastname"
              [placeholder]="'Ingresa apellidos completos'"
              [disabled]="true"
            />
          </div>
        </div>
      </div>

      <!-- Empresa -->
      <div class="field">
        <label for="company" class="font-semibold">Empresa</label>
        <p-autoComplete
          id="company"
          [(ngModel)]="selectedCompany"
          [suggestions]="filteredOptions"
          [dropdown]="true"
          [forceSelection]="true"
          [placeholder]="'Selecciona una empresa'"
          [style]="{ width: '100%' }"
          [field]="'text'"
          [disabled]="true"
        ></p-autoComplete>
      </div>

      <!-- Contenedor de Puesto y Modalidad -->
      <div class="field-container">
        <!-- País -->
        <div class="field">
          <label for="jobPosition" class="font-semibold">Puesto</label>
          <p-autoComplete
            id="jobPosition"
            [(ngModel)]="selectedJobPosition"
            [suggestions]="filteredOptions"
            [dropdown]="true"
            [forceSelection]="true"
            [placeholder]="'Selecciona un puesto'"
            [style]="{ width: '100%' }"
            [field]="'text'"
            [disabled]="true"
          ></p-autoComplete>
        </div>

        <!-- Modalidad -->
        <div class="field">
          <label for="workingMode" class="font-semibold">Modalidad</label>
          <p-autoComplete
            id="workingMode"
            [(ngModel)]="selectedWorkingMode"
            [suggestions]="filteredOptions"
            [dropdown]="true"
            [forceSelection]="true"
            [placeholder]="'Selecciona una modalidad'"
            [style]="{ width: '100%' }"
            [field]="'text'"
            [disabled]="true"
          ></p-autoComplete>
        </div>
      </div>

      <!-- Contenedor de País y Ciudad -->
      <div class="field-container">
        <!-- País -->
        <div class="field">
          <label for="country" class="font-semibold">País</label>
          <p-autoComplete
            id="country"
            [(ngModel)]="selectedCountry"
            [suggestions]="filteredOptions"
            [dropdown]="true"
            [forceSelection]="true"
            [placeholder]="'Selecciona un país'"
            [style]="{ width: '100%' }"
            [field]="'text'"
            [disabled]="true"
          ></p-autoComplete>
        </div>

        <!-- Ciudad -->
        <div class="field">
          <label for="city" class="font-semibold">Ciudad</label>
          <p-autoComplete
            id="city"
            [(ngModel)]="selectedCity"
            [suggestions]="filteredOptions"
            [dropdown]="true"
            [forceSelection]="true"
            [placeholder]="'Selecciona una ciudad'"
            [style]="{ width: '100%' }"
            [field]="'text'"
            [disabled]="true"
          ></p-autoComplete>
        </div>
      </div>
    </div>

    <div class="column">
      <div class="field-container">
        <!-- Fecha de Cumpleaños -->
        <div class="calendar-item">
          <label for="birthDate">Fecha de Nacimiento</label>
          <p-calendar
            id="birthDate"
            [(ngModel)]="birthDate"
            [showIcon]="true"
            dateFormat="dd/mm/yy"
            placeholder="DD/MM/YYYY"
            [disabled]="true"
          ></p-calendar>
        </div>

        <!-- Género -->
        <div class="field">
          <label for="gender" class="font-semibold">Género</label>
          <p-autoComplete
            id="gender"
            [(ngModel)]="selectedGender"
            [suggestions]="filteredOptions"
            [dropdown]="true"
            [forceSelection]="true"
            [placeholder]="'Selecciona'"
            [style]="{ width: '100%' }"
            [field]="'text'"
            [disabled]="true"
          ></p-autoComplete>
        </div>
      </div>

      <div class="field-container">
        <!-- Tipo de Documento -->
        <div class="field">
          <label for="typeDocument" class="font-semibold"
            >Tipo de Documento</label
          >
          <p-autoComplete
            id="typeDocument"
            [(ngModel)]="selectedTypeDocument"
            [suggestions]="filteredOptions"
            [dropdown]="true"
            [forceSelection]="true"
            [placeholder]="'Selecciona un tipo'"
            [style]="{ width: '100%' }"
            [field]="'text'"
            [disabled]="true"
          ></p-autoComplete>
        </div>

        <!-- Documento -->
        <div class="field">
          <label for="document" class="font-semibold"
            >Número de Documento</label
          >
          <input
            id="document"
            type="text"
            pInputText
            [(ngModel)]="document"
            placeholder="Ingresa número de documento"
            [disabled]="true"
          />
        </div>
      </div>

      <div class="field-container">
        <!-- Celular -->
        <div class="field">
          <label for="phone" class="font-semibold">Celular</label>
          <p-inputGroup>
            <p-inputGroupAddon>
              <span *ngIf="selectedPhoneCode" class="phone-prefix"
                >+{{ selectedPhoneCode }}</span
              >
            </p-inputGroupAddon>
            <p-inputNumber
              id="phone"
              mode="decimal"
              inputId="withoutgrouping"
              [useGrouping]="false"
              [(ngModel)]="phone"
              [placeholder]="'Ingresa celular'"
              [disabled]="true"
            ></p-inputNumber>
          </p-inputGroup>
        </div>

        <!-- Correo -->
        <div class="field">
          <label for="email" class="font-semibold">Correo</label>
          <input
            pInputText
            id="email"
            type="email"
            [(ngModel)]="email"
            [placeholder]="'Ingresa correo'"
            [disabled]="true"
          />
        </div>
      </div>

      <!-- Dirección -->
      <div class="field">
        <label for="address" class="font-semibold">Dirección</label>
        <input
          pInputText
          id="address"
          type="text"
          [(ngModel)]="address"
          [placeholder]="'Ingresa dirección actual'"
          [disabled]="true"
        />
      </div>

      <div class="field-container">
        <!-- Celular Emergencia -->
        <div class="field">
          <label for="phone" class="font-semibold"
            >Contacto de emergencia</label
          >
          <p-inputGroup>
            <p-inputGroupAddon>
              <span *ngIf="selectedPhoneCode" class="phone-prefix"
                >+{{ selectedPhoneCode }}</span
              >
            </p-inputGroupAddon>
            <p-inputNumber
              id="phoneEmergency"
              mode="decimal"
              inputId="withoutgrouping"
              [useGrouping]="false"
              [(ngModel)]="phoneEmergency"
              [placeholder]="'Ingresa celular'"
              [disabled]="true"
            ></p-inputNumber>
          </p-inputGroup>
        </div>

        <!-- Status Empleado -->
        <div class="field">
          <label for="email" class="font-semibold">Status del Empleado</label>
          <p-autoComplete
            id="statusEmployee"
            [(ngModel)]="selectedStatusEmployee"
            [suggestions]="filteredOptions"
            [dropdown]="true"
            [forceSelection]="true"
            [placeholder]="'Selecciona un estado'"
            [style]="{ width: '100%' }"
            [field]="'text'"
            [disabled]="true"
          ></p-autoComplete>
        </div>
      </div>
    </div>
  </div>
</p-dialog>
