<div class="card">
  <div class="column">
    <h2 class="label">
      <button type="button" pButton (click)="goBack()" class="btn-back">
        <i class="pi pi-arrow-circle-left"></i>
      </button>
      Volver
    </h2>
    <p-tabView [(activeIndex)]="activeIndex" styleClass="tabview-custom">
      <p-tabPanel>
        <ng-template pTemplate="header">
          <div class="flex align-items-center gap-2">
            <p-badge value="1" />
            <span class="font-bold white-space-nowrap m-0">
              Agregar empleado
            </span>
          </div>
        </ng-template>
        <div class="row">
          <div class="column">
            <!-- Carga de Imagen -->
            <div class="field">
              <label for="fotoPerfil" class="font-semibold"
                >Foto de perfil</label
              >
              <div class="profile-photo-container">
                <div class="photo-preview" (click)="openFileUpload('image')">
                  <img
                    *ngIf="selectedPhoto"
                    [src]="selectedPhoto"
                    alt="Foto de perfil"
                    class="profile-photo"
                  />
                  <i *ngIf="!selectedPhoto" class="pi pi-user"></i>
                </div>
                <div class="photo-actions">
                  <div (click)="editPhoto()" class="photo-action">
                    <i class="pi pi-pencil"></i> Editar
                  </div>
                  <div (click)="addPhoto()" class="photo-action">
                    <i class="pi pi-camera"></i> Añadir foto
                  </div>
                  <div (click)="removePhoto()" class="photo-action">
                    <i class="pi pi-trash"></i> Eliminar
                  </div>
                </div>
              </div>
              <div
                style="
                  color: grey;
                  font-size: 0.75rem;
                  text-align: center;
                  display: flex;
                  flex-direction: column;
                "
              >
                <p style="padding: 0; margin: 0">
                  Tamaño máximo de archivo: 10 MB.
                </p>
              </div>
              <p-fileUpload
                #imageUploader
                [accept]="'image/*'"
                [maxFileSize]="10000000"
                (onSelect)="onPhotoSelect($event)"
                [style]="{ display: 'none' }"
              ></p-fileUpload>
            </div>
            <!-- Empresa -->
            <div class="field">
              <label for="company" class="font-semibold">Empresa</label>
              <p-autoComplete
                id="company"
                [(ngModel)]="selectedCompany"
                [suggestions]="filteredOptions"
                (completeMethod)="filterOptions($event, 'company')"
                (input)="onChangeInput($event, 'company')"
                [dropdown]="true"
                [forceSelection]="true"
                [placeholder]="'Selecciona una empresa...'"
                (onSelect)="onSelect($event, 'company')"
                [style]="{ width: '100%' }"
                [field]="'text'"
              ></p-autoComplete>
              <div
                *ngIf="!selectedCompany && hasInteracted['company']"
                class="error-message"
                style="color: red; font-size: 0.875rem; margin-top: 5px"
              >
                Este campo es obligatorio.
              </div>
            </div>

            <!-- Contenedor de País y Ciudad -->
            <div class="field-container">
              <!-- País -->
              <div class="field">
                <label for="country" class="font-semibold">País</label>
                <p-autoComplete
                  id="country"
                  [(ngModel)]="selectedCountry"
                  [suggestions]="filteredOptions"
                  (completeMethod)="filterOptions($event, 'country')"
                  (input)="onChangeInput($event, 'country')"
                  [dropdown]="true"
                  [forceSelection]="true"
                  [placeholder]="'Selecciona un país'"
                  (onSelect)="onSelect($event, 'country')"
                  [style]="{ width: '100%' }"
                  [field]="'text'"
                ></p-autoComplete>
                <div
                  *ngIf="!selectedCountry && hasInteracted['country']"
                  class="error-message"
                >
                  Este campo es obligatorio.
                </div>
              </div>

              <!-- Ciudad -->
              <div class="field">
                <label for="city" class="font-semibold">Ciudad</label>
                <p-autoComplete
                  id="city"
                  [(ngModel)]="selectedCity"
                  [suggestions]="filteredOptions"
                  (completeMethod)="filterOptions($event, 'city')"
                  (input)="onChangeInput($event, 'city')"
                  [dropdown]="true"
                  [forceSelection]="true"
                  [placeholder]="'Selecciona una ciudad'"
                  (onSelect)="onSelect($event, 'city')"
                  [style]="{ width: '100%' }"
                  [field]="'text'"
                ></p-autoComplete>
                <div
                  *ngIf="!selectedCity && hasInteracted['city']"
                  class="error-message"
                >
                  Este campo es obligatorio.
                </div>
              </div>
            </div>

            <div class="field-container">
              <!-- Tipo de Documento -->
              <div class="field">
                <label for="typeDocument" class="font-semibold"
                  >Tipo de Documento</label
                >
                <p-autoComplete
                  id="typeDocument"
                  [(ngModel)]="selectedTypeDocument"
                  [suggestions]="filteredOptions"
                  (completeMethod)="filterOptions($event, 'typeDocument')"
                  (input)="onChangeInput($event, 'typeDocument')"
                  [dropdown]="true"
                  [forceSelection]="true"
                  [placeholder]="'Selecciona un tipo'"
                  (onSelect)="onSelect($event, 'typeDocument')"
                  [style]="{ width: '100%' }"
                  [field]="'text'"
                ></p-autoComplete>
                <div
                  *ngIf="!selectedTypeDocument && hasInteracted['typeDocument']"
                  style="color: red; font-size: 0.875rem; margin-top: 5px"
                >
                  Este campo es obligatorio.
                </div>
              </div>

              <!-- Documento -->
              <div class="field">
                <label for="document" class="font-semibold"
                  >Número de Documento</label
                >
                <input
                  id="document"
                  type="text"
                  pInputText
                  [(ngModel)]="document"
                  (ngModelChange)="onChangeInput($event, 'document')"
                  (keypress)="onlyNumbers($event)"
                  placeholder="Ingresa número de documento"
                  [maxlength]="maxLength"
                />
                <div
                  *ngIf="!document && hasInteracted['document']"
                  style="color: red; font-size: 0.875rem; margin-top: 5px"
                >
                  Este campo es obligatorio.
                </div>
              </div>
            </div>
          </div>

          <div class="column">
            <!-- Nombres -->
            <div class="field">
              <label for="name" class="font-semibold">Nombres</label>
              <input
                pInputText
                id="name"
                type="text"
                [(ngModel)]="name"
                (ngModelChange)="onChangeInput($event, 'name')"
                [placeholder]="'Ingresa nombres completos'"
              />
              <div
                *ngIf="!name && hasInteracted['name']"
                style="color: red; font-size: 0.875rem; margin-top: 5px"
              >
                Este campo es obligatorio.
              </div>
            </div>
            <!-- Apellidos -->
            <div class="field">
              <label for="lastname" class="font-semibold">Apellidos</label>
              <input
                pInputText
                id="lastname"
                type="text"
                [(ngModel)]="lastname"
                (ngModelChange)="onChangeInput($event, 'lastname')"
                [placeholder]="'Ingresa apellidos completos'"
              />
              <div
                *ngIf="!lastname && hasInteracted['lastname']"
                style="color: red; font-size: 0.875rem; margin-top: 5px"
              >
                Este campo es obligatorio.
              </div>
            </div>

            <div class="field-container">
              <!-- Fecha de Cumpleaños -->
              <div class="calendar-item">
                <label for="birthDate">Fecha de Nacimiento</label>
                <p-calendar
                  id="birthDate"
                  [(ngModel)]="birthDate"
                  [icon]="'pi pi-pencil'"
                  [showIcon]="true"
                  [minDate]="minBirthDate"
                  [maxDate]="maxBirthDate"
                  dateFormat="dd/mm/yy"
                  placeholder="DD/MM/YYYY"
                  (onSelect)="validateFormEmployee()"
                  (onBlur)="validateField('birthDate')"
                  [styleClass]="'custom-birthdate-calendar'"
                >
                </p-calendar>
                <div
                  *ngIf="!birthDate && hasInteracted['birthDate']"
                  style="color: red; font-size: 0.875rem; margin-top: 5px"
                >
                  Este campo es obligatorio.
                </div>
              </div>

              <!-- Status Empleado -->
              <div class="field">
                <label for="gender" class="font-semibold">Género</label>
                <p-autoComplete
                  id="gender"
                  [(ngModel)]="selectedGender"
                  [suggestions]="filteredOptions"
                  (completeMethod)="filterOptions($event, 'gender')"
                  (input)="onChangeInput($event, 'gender')"
                  [dropdown]="true"
                  [forceSelection]="true"
                  [placeholder]="'Selecciona'"
                  (onSelect)="onSelect($event, 'gender')"
                  [style]="{ width: '100%' }"
                  [field]="'text'"
                ></p-autoComplete>
                <div
                  *ngIf="!selectedGender && hasInteracted['gender']"
                  class="error-message"
                >
                  Este campo es obligatorio.
                </div>
              </div>
            </div>

            <div class="field-container">
              <!-- Celular -->
              <div class="field">
                <label for="phone" class="font-semibold">Celular</label>
                <p-inputGroup>
                  <p-inputGroupAddon>
                    <span *ngIf="selectedPhoneCode" class="phone-prefix"
                      >+{{ selectedPhoneCode }}</span
                    >
                  </p-inputGroupAddon>
                  <p-inputNumber
                    id="phone"
                    mode="decimal"
                    inputId="withoutgrouping"
                    [useGrouping]="false"
                    [(ngModel)]="phone"
                    (ngModelChange)="onChangeInput($event, 'phone')"
                    [placeholder]="'Ingresa celular'"
                  ></p-inputNumber>
                </p-inputGroup>
                <div
                  *ngIf="!phone && hasInteracted['phone']"
                  style="color: red; font-size: 0.875rem; margin-top: 5px"
                >
                  Este campo es obligatorio.
                </div>
              </div>
              <!-- Correo -->
              <div class="field">
                <label for="email" class="font-semibold">Correo</label>
                <input
                  pInputText
                  id="email"
                  type="email"
                  [(ngModel)]="email"
                  (ngModelChange)="onChangeInput($event, 'email')"
                  [placeholder]="'Ingresa correo'"
                />
                <div
                  *ngIf="!email && hasInteracted['email']"
                  style="color: red; font-size: 0.875rem; margin-top: 5px"
                >
                  Este campo es obligatorio.
                </div>
              </div>
            </div>

            <!-- Dirección -->
            <div class="field">
              <label for="address" class="font-semibold">Dirección</label>
              <input
                pInputText
                id="address"
                type="text"
                [(ngModel)]="address"
                (ngModelChange)="onChangeInput($event, 'address')"
                [placeholder]="'Ingresa dirección actual'"
              />
              <div
                *ngIf="!address && hasInteracted['address']"
                style="color: red; font-size: 0.875rem; margin-top: 5px"
              >
                Este campo es obligatorio.
              </div>
            </div>

            <div class="field-container">
              <!-- Celular Emergencia -->
              <div class="field">
                <label for="phone" class="font-semibold"
                  >Contacto de emergencia</label
                >
                <p-inputGroup>
                  <p-inputGroupAddon>
                    <span *ngIf="selectedPhoneCode" class="phone-prefix"
                      >+{{ selectedPhoneCode }}</span
                    >
                  </p-inputGroupAddon>
                  <p-inputNumber
                    id="phoneEmergency"
                    mode="decimal"
                    inputId="withoutgrouping"
                    [useGrouping]="false"
                    [(ngModel)]="phoneEmergency"
                    (ngModelChange)="onChangeInput($event, 'phoneEmergency')"
                    [placeholder]="'Ingresa celular'"
                  ></p-inputNumber>
                </p-inputGroup>
              </div>

              <!-- Status Empleado -->
              <div class="field">
                <label for="email" class="font-semibold"
                  >Status del Empleado</label
                >
                <p-autoComplete
                  id="statusEmployee"
                  [(ngModel)]="selectedStatusEmployee"
                  [suggestions]="filteredOptions"
                  (completeMethod)="filterOptions($event, 'statusEmployee')"
                  (input)="onChangeInput($event, 'statusEmployee')"
                  [dropdown]="true"
                  [forceSelection]="true"
                  [placeholder]="'Selecciona un estado'"
                  (onSelect)="onSelect($event, 'statusEmployee')"
                  [style]="{ width: '100%' }"
                  [field]="'text'"
                ></p-autoComplete>
                <div
                  *ngIf="
                    !selectedStatusEmployee && hasInteracted['statusEmployee']
                  "
                  class="error-message"
                >
                  Este campo es obligatorio.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="display: flex; justify-content: center; margin-top: 1rem">
          <!-- Botón Siguiente Registro Empleado -->
          <button
            type="button"
            pButton
            label="Siguiente"
            (click)="onNext()"
            [disabled]="isDisabledEmployee"
            class="btn"
          ></button>
        </div>
      </p-tabPanel>

      <p-tabPanel header="Header II">
        [disabled]="isDisabledEmployee"
        <ng-template pTemplate="header">
          <div class="flex align-items-center gap-2">
            <p-badge value="2" />
            <span class="font-bold white-space-nowrap m-0">
              Agregar contrato
            </span>
          </div>
        </ng-template>
        <div class="row">
          <div class="column">
            <div class="field-container">
              <!-- Puesto -->
              <div class="field">
                <label for="jobPosition" class="font-semibold"
                  >Puesto o Cargo</label
                >
                <p-autoComplete
                  id="jobPosition"
                  [(ngModel)]="selectedJobPosition"
                  [suggestions]="filteredOptions"
                  (completeMethod)="filterOptions($event, 'jobPosition')"
                  (input)="onChangeInput($event, 'jobPosition')"
                  [dropdown]="true"
                  [forceSelection]="true"
                  [placeholder]="'Selecciona un tipo'"
                  (onSelect)="onSelect($event, 'jobPosition')"
                  [style]="{ width: '100%' }"
                  [field]="'text'"
                ></p-autoComplete>
                <div
                  *ngIf="!selectedJobPosition && hasInteracted['jobPosition']"
                  class="error-message"
                  style="color: red; font-size: 0.875rem; margin-top: 2px"
                >
                  Este campo es obligatorio.
                </div>
              </div>
            </div>

            <div class="field-container">
              <!-- Tipo de Contrato -->
              <div class="field">
                <label for="typeContract" class="font-semibold"
                  >Tipo de Contrato</label
                >
                <p-autoComplete
                  id="typeContract"
                  [(ngModel)]="selectedTypeContract"
                  [suggestions]="filteredOptions"
                  (completeMethod)="filterOptions($event, 'typeContract')"
                  (input)="onChangeInput($event, 'typeContract')"
                  [dropdown]="true"
                  [forceSelection]="true"
                  [placeholder]="'Selecciona un tipo'"
                  (onSelect)="onSelect($event, 'typeContract')"
                  [style]="{ width: '100%' }"
                  [field]="'text'"
                ></p-autoComplete>
                <div
                  *ngIf="!selectedTypeContract && hasInteracted['typeContract']"
                  class="error-message"
                  style="color: red; font-size: 0.875rem; margin-top: 2px"
                >
                  Este campo es obligatorio.
                </div>
              </div>

              <!-- Modalidad -->
              <div class="field">
                <label for="workingMode" class="font-semibold">Modalidad</label>
                <p-autoComplete
                  id="mode"
                  [(ngModel)]="selectedWorkingMode"
                  [suggestions]="filteredOptions"
                  (completeMethod)="filterOptions($event, 'workingMode')"
                  (input)="onChangeInput($event, 'workingMode')"
                  [dropdown]="true"
                  [forceSelection]="true"
                  [placeholder]="'Selecciona un tipo'"
                  (onSelect)="onSelect($event, 'workingMode')"
                  [style]="{ width: '100%' }"
                  [field]="'text'"
                ></p-autoComplete>
                <div
                  *ngIf="!selectedWorkingMode && hasInteracted['workingMode']"
                  class="error-message"
                  style="color: red; font-size: 0.875rem; margin-top: 2px"
                >
                  Este campo es obligatorio.
                </div>
              </div>
            </div>

            <!-- Selección de Fechas -->
            <div class="calendar-row">
              <div class="calendar-item">
                <label for="startDate">Fecha de Inicio</label>
                <p-calendar
                  id="startDate"
                  [(ngModel)]="startDate"
                  [icon]="'pi pi-pencil'"
                  [showIcon]="true"
                  (ngModelChange)="onStartDateChange($event)"
                  [maxDate]="maxStartDate"
                  dateFormat="dd/mm/yy"
                  placeholder="DD/MM/YYYY"
                  (onBlur)="validateField('startDate')"
                ></p-calendar>
              </div>
              <div class="calendar-item">
                <label for="endDate">Fecha de Finalización</label>
                <p-calendar
                  id="endDate"
                  [(ngModel)]="endDate"
                  [icon]="'pi pi-pencil'"
                  [showIcon]="true"
                  (ngModelChange)="onEndDateChange($event)"
                  [minDate]="startDate"
                  [maxDate]="maxEndDate"
                  dateFormat="dd/mm/yy"
                  placeholder="DD/MM/YYYY"
                  (onBlur)="validateField('endDate')"
                ></p-calendar>
              </div>
            </div>

            <!-- Confirmación de continuidad -->
            <div class="field">
              <label for="confirmation"
                >¿Proseguirá el empleado en la empresa?</label
              >
              <div *ngFor="let continue of continues" class="confirmation">
                <p-checkbox
                  [ngModel]="selectedContinuity?.key === continue.key"
                  inputId="confirmation-{{ continue.key }}"
                  [label]="continue.text"
                  [binary]="true"
                  [value]="continue"
                  (onChange)="onCheckboxChange(continue)"
                />
              </div>
              <div
                *ngIf="!selectedContinuity && hasInteracted['continue']"
                class="error-message"
              >
                Este campo es obligatorio.
              </div>
            </div>
          </div>

          <div class="column">
            <!-- <div class="field">
              <label for="renewContract" class="font-semibold"
                >¿Cuántas veces se renovó el contrato?</label
              >
              <p-autoComplete
                id="renewContract"
                [(ngModel)]="selectedRenewContract"
                [suggestions]="filteredOptions"
                (completeMethod)="filterOptions($event, 'renewContract')"
                (input)="onChangeInput($event, 'renewContract')"
                [dropdown]="true"
                [forceSelection]="true"
                [placeholder]="'Selecciona una opción'"
                (onSelect)="onSelect($event, 'renewContract')"
                [style]="{ width: '100%' }"
                [field]="'text'"
              ></p-autoComplete>
              <div
                *ngIf="!selectedRenewContract && hasInteracted['renewContract']"
                class="error-message"
              >
                Este campo es obligatorio.
              </div>
            </div> -->

            <div class="field-container">
              <!-- Moneda -->
              <div class="field">
                <label for="currency" class="font-semibold">Moneda</label>
                <p-autoComplete
                  id="currency"
                  [(ngModel)]="selectedCurrency"
                  [suggestions]="filteredOptions"
                  (completeMethod)="filterOptions($event, 'currency')"
                  (input)="onChangeInput($event, 'currency')"
                  [dropdown]="true"
                  [forceSelection]="true"
                  [placeholder]="'Selecciona una moneda'"
                  (onSelect)="onSelect($event, 'currency')"
                  [style]="{ width: '100%' }"
                  [field]="'text'"
                ></p-autoComplete>
                <div
                  *ngIf="!selectedCurrency && hasInteracted['currency']"
                  class="error-message"
                >
                  Este campo es obligatorio.
                </div>
              </div>

              <!-- Sueldo básico -->
              <div class="field">
                <label for="basicSalary" class="font-semibold">Sueldo Básico</label>
                <p-inputGroup>
                  <p-inputGroupAddon>
                    <span *ngIf="selectedCurrencyCode" class="phone-prefix">{{
                      selectedCurrencyCode
                    }}</span>
                  </p-inputGroupAddon>
                  <p-inputNumber
                    id="basicSalary"
                    mode="decimal"
                    [minFractionDigits]="2"
                    [maxFractionDigits]="2"
                    inputId="withoutgrouping"
                    [useGrouping]="false"
                    [(ngModel)]="basicSalary"
                    (ngModelChange)="onChangeInput($event, 'basicSalary')"
                    [placeholder]="'Ingresa sueldo básico'"
                  ></p-inputNumber>
                </p-inputGroup>
                <div
                  *ngIf="!basicSalary && hasInteracted['basicSalary']"
                  style="color: red; font-size: 0.875rem; margin-top: 2px"
                >
                  Este campo es obligatorio.
                </div>
              </div>
            </div>

            <div class="field-container" *ngIf="selectedWorkingMode?.key == '1' || selectedWorkingMode?.key == '3'">
              <!-- Bono de PC -->
              <div class="field">
                <label for="computerAllowance" class="font-semibold">Bono de PC</label>
                <p-inputGroup>
                  <p-inputGroupAddon>
                    <span *ngIf="selectedCurrencyCode" class="phone-prefix">{{
                      selectedCurrencyCode
                    }}</span>
                  </p-inputGroupAddon>
                  <p-inputNumber
                    id="computerAllowance"
                    mode="decimal"
                    [minFractionDigits]="2"
                    [maxFractionDigits]="2"
                    inputId="withoutgrouping"
                    [useGrouping]="false"
                    [(ngModel)]="computerAllowance"
                    (ngModelChange)="onChangeInput($event, 'computerAllowance')"
                    [placeholder]="'Ingresa bono de PC'"
                  ></p-inputNumber>
                </p-inputGroup>
                <div
                  *ngIf="!computerAllowance && hasInteracted['computerAllowance']"
                  style="color: red; font-size: 0.875rem; margin-top: 2px"
                >
                  Este campo es obligatorio.
                </div>
              </div>

              <!-- Bono de Internet -->
              <div class="field">
                <label for="internetAllowance" class="font-semibold"
                  >Bono de Internet</label
                >
                <p-inputGroup>
                  <p-inputGroupAddon>
                    <span *ngIf="selectedCurrencyCode" class="phone-prefix">{{
                      selectedCurrencyCode
                    }}</span>
                  </p-inputGroupAddon>
                  <p-inputNumber
                    id="internetAllowance"
                    mode="decimal"
                    [minFractionDigits]="2"
                    [maxFractionDigits]="2"
                    inputId="withoutgrouping"
                    [useGrouping]="false"
                    [(ngModel)]="internetAllowance"
                    (ngModelChange)="onChangeInput($event, 'internetAllowance')"
                    [placeholder]="'Ingresa bono de internet'"
                  ></p-inputNumber>
                </p-inputGroup>
                <div
                  *ngIf="!internetAllowance && hasInteracted['internetAllowance']"
                  style="color: red; font-size: 0.875rem; margin-top: 2px"
                >
                  Este campo es obligatorio.
                </div>
              </div>
            </div>

            <div class="field-container" *ngIf="selectedWorkingMode?.key == '1' || selectedWorkingMode?.key == '2'|| selectedWorkingMode?.key == '3'">
              <!-- Bono de luz -->
              <div class="field" *ngIf="selectedWorkingMode?.key == '1' || selectedWorkingMode?.key == '3'">
                <label for="electricityAllowance" class="font-semibold">Bono de luz</label>
                <p-inputGroup>
                  <p-inputGroupAddon>
                    <span *ngIf="selectedCurrencyCode" class="phone-prefix">{{
                      selectedCurrencyCode
                    }}</span>
                  </p-inputGroupAddon>
                  <p-inputNumber
                    id="electricityAllowance"
                    mode="decimal"
                    [minFractionDigits]="2"
                    [maxFractionDigits]="2"
                    inputId="withoutgrouping"
                    [useGrouping]="false"
                    [(ngModel)]="electricityAllowance"
                    (ngModelChange)="onChangeInput($event, 'electricityAllowance')"
                    [placeholder]="'Ingresa bono de luz'"
                  ></p-inputNumber>
                </p-inputGroup>
                <div
                  *ngIf="!electricityAllowance && hasInteracted['electricityAllowance']"
                  style="color: red; font-size: 0.875rem; margin-top: 2px"
                >
                  Este campo es obligatorio.
                </div>
              </div>

              <!-- Bono de Movilidad -->
              <div class="field" *ngIf="selectedWorkingMode?.key == '2' || selectedWorkingMode?.key == '3'">
                <label for="transportationAllowance" class="font-semibold">Bono de Movilidad</label>
                <p-inputGroup>
                  <p-inputGroupAddon>
                    <span *ngIf="selectedCurrencyCode" class="phone-prefix">{{
                      selectedCurrencyCode
                    }}</span>
                  </p-inputGroupAddon>
                  <p-inputNumber
                    id="transportationAllowance"
                    mode="decimal"
                    [minFractionDigits]="2"
                    [maxFractionDigits]="2"
                    inputId="withoutgrouping"
                    [useGrouping]="false"
                    [(ngModel)]="transportationAllowance"
                    (ngModelChange)="onChangeInput($event, 'transportationAllowance')"
                    [placeholder]="'Ingresa bono de movilidad'"
                  ></p-inputNumber>
                </p-inputGroup>
                <div
                  *ngIf="!transportationAllowance && hasInteracted['transportationAllowance']"
                  style="color: red; font-size: 0.875rem; margin-top: 2px"
                >
                  Este campo es obligatorio.
                </div>
              </div>
            </div>

            <div class="field-container">
              <!-- Bono de cumplimiento -->
              <div class="field">
                <label for="performanceBonus" class="font-semibold"
                  >Bono Cumplimiento</label
                >
                <p-inputGroup>
                  <p-inputGroupAddon>
                    <span *ngIf="selectedCurrencyCode" class="phone-prefix">{{
                      selectedCurrencyCode
                    }}</span>
                  </p-inputGroupAddon>
                  <p-inputNumber
                    id="performanceBonus"
                    mode="decimal"
                    [minFractionDigits]="2"
                    [maxFractionDigits]="2"
                    inputId="withoutgrouping"
                    [useGrouping]="false"
                    [(ngModel)]="performanceBonus"
                    (ngModelChange)="onChangeInput($event, 'performanceBonus')"
                    [placeholder]="'Ingresa bono productividad'"
                  ></p-inputNumber>
                </p-inputGroup>
                <div
                  *ngIf="!performanceBonus && hasInteracted['performanceBonus']"
                  style="color: red; font-size: 0.875rem; margin-top: 2px"
                >
                  Este campo es obligatorio.
                </div>
              </div>

              <!-- Salario Total -->
              <div class="field">
                <label for="salary" class="font-semibold">Salario Total</label>
                <p-inputGroup>
                  <p-inputGroupAddon>
                    <span *ngIf="selectedCurrencyCode" class="phone-prefix">{{
                      selectedCurrencyCode
                    }}</span>
                  </p-inputGroupAddon>
                  <p-inputNumber
                    id="salary"
                    mode="decimal"
                    [minFractionDigits]="2"
                    [maxFractionDigits]="2"
                    inputId="withoutgrouping"
                    [useGrouping]="false"
                    [(ngModel)]="salary"
                    [disabled]="true"
                    (ngModelChange)="onChangeInput($event, 'salary')"
                  ></p-inputNumber>
                </p-inputGroup>
                <div
                  *ngIf="!salary && hasInteracted['salary']"
                  style="color: red; font-size: 0.875rem; margin-top: 2px"
                >
                  Este campo es obligatorio.
                </div>
              </div>
            </div>

            <!-- Carga de PDF -->
            <div class="field">
              <label for="selectedFileName" class="font-semibold"
                >Subir contrato</label
              >
              <div
                class="file-container"
                (dragover)="onDragOver($event)"
                (dragleave)="onDragLeave($event)"
                (drop)="onDrop($event)"
              >
                <div class="file-preview" (click)="openFileUpload('pdf')">
                  <div class="custom-file-display">
                    <div class="custom-file-top">
                      <i
                        [ngClass]="
                          selectedFileName
                            ? 'pi pi-file-pdf'
                            : 'pi pi-plus-circle'
                        "
                      ></i>
                      <span>{{ selectedFileName || "Subir un archivo" }}</span>
                    </div>

                    <p-divider
                      *ngIf="!selectedFileName"
                      align="center"
                      type="solid"
                    >
                      o
                    </p-divider>

                    <span *ngIf="!selectedFileName">Arrástralo aquí</span>
                  </div>
                </div>
              </div>

              <div
                style="
                  color: grey;
                  font-size: 0.75rem;
                  text-align: center;
                  display: flex;
                  flex-direction: column;
                "
              >
                <p style="padding: 0; margin: 0">Formatos admitidos: pdf. Tamaño máximo de archivo: 10 MB.</p>
              </div>

              <p-fileUpload
                #pdfUploader
                [accept]="'application/pdf'"
                [maxFileSize]="10000000"
                (onSelect)="onChangeFile($event)"
                [style]="{ display: 'none' }"
              ></p-fileUpload>
            </div>
          </div>
        </div>

        <div
          style="
            display: flex;
            justify-content: center;
            gap: 1rem;
            margin-top: 1rem;
          "
        >
          <button
            type="button"
            pButton
            label="Atrás"
            (click)="backStep()"
            class="btn-back-step"
          ></button>

          <button
            type="button"
            pButton
            label="Registrar usuario"
            (click)="onRegisterEmployeeAndContract()"
            [disabled]="isDisabledContract || isDisabledEmployee"
            class="btn"
          ></button>
        </div>
      </p-tabPanel>
    </p-tabView>
  </div>
</div>
