<div class="boletas-container">
  <!-- Título y subtítulo -->
  <app-page-header
    [title]="'Gestor de empleados'"
    [subtitle]="'Administra y genera las vacaciones de los empleados'"
  >
  </app-page-header>

  <!-- Mat-Tab con selección dinámica -->
  <mat-tab-group
    class="tab-group"
    [selectedIndex]="selectedTabKey - 1"
    (selectedIndexChange)="onTabSelected($event)"
  >
    <mat-tab *ngFor="let tab of tabs" [label]="tab.text | capitalize"></mat-tab>
  </mat-tab-group>

  <router-outlet></router-outlet>
</div>
