<p-dialog
  header="Editar Registro"
  [(visible)]="visible"
  [modal]="true"
  (onHide)="closeDialog()"
  [closable]="true"
  [style]="{ width: '65%', height: '80vh' }"
>
  <div class="loading-spinner" *ngIf="isLoading">
    <p-progressSpinner styleClass="custom-spinner"></p-progressSpinner>
  </div>

  <div *ngIf="!isLoading" class="row">
    <div class="column">
      <!-- Carga de Imagen -->
      <div class="field">
        <label for="fotoPerfil" class="font-semibold">Foto de perfil</label>
        <div class="profile-photo-container">
          <div class="photo-preview" (click)="openFileUpload('image')">
            <img
              *ngIf="selectedPhoto"
              [src]="selectedPhoto"
              alt="Foto de perfil"
              class="profile-photo"
            />
            <i *ngIf="!selectedPhoto" class="pi pi-user"></i>
          </div>
          <div class="photo-actions">
            <div class="photo-actions-start">
              <div (click)="editPhoto()" class="photo-action">
                <i class="pi pi-pencil"></i> Editar
              </div>
              <div (click)="addPhoto()" class="photo-action">
                <i class="pi pi-camera"></i> Añadir foto
              </div>
            </div>
            <div (click)="removePhoto()" class="photo-action">
              <i class="pi pi-trash"></i> Eliminar
            </div>
          </div>
        </div>
        <p-fileUpload
          #imageUploader
          [accept]="'image/*'"
          [maxFileSize]="10000000"
          (onSelect)="onPhotoSelect($event)"
          [style]="{ display: 'none' }"
        ></p-fileUpload>
      </div>

      <!-- Empresa -->
      <div class="field">
        <label for="company" class="font-semibold">Empresa</label>
        <p-autoComplete
          id="company"
          [(ngModel)]="selectedCompany"
          [suggestions]="filteredOptions"
          (completeMethod)="filterOptions($event, 'company')"
          (input)="onChangeInput($event, 'company')"
          [dropdown]="true"
          [forceSelection]="true"
          [placeholder]="'Selecciona una empresa'"
          (onSelect)="onSelect($event, 'company')"
          [style]="{ width: '100%' }"
          [field]="'text'"
        ></p-autoComplete>
      </div>

      <!-- Contenedor de Puesto y Modalidad -->
      <div class="field-container">
        <!-- País -->
        <div class="field">
          <label for="jobPosition" class="font-semibold">Puesto</label>
          <p-autoComplete
            id="jobPosition"
            [(ngModel)]="selectedJobPosition"
            [suggestions]="filteredOptions"
            (completeMethod)="filterOptions($event, 'jobPosition')"
            (input)="onChangeInput($event, 'jobPosition')"
            [dropdown]="true"
            [forceSelection]="true"
            [placeholder]="'Selecciona un puesto'"
            (onSelect)="onSelect($event, 'jobPosition')"
            [style]="{ width: '100%' }"
            [field]="'text'"
          ></p-autoComplete>
        </div>

        <!-- Modalidad -->
        <div class="field">
          <label for="workingMode" class="font-semibold">Modalidad</label>
          <p-autoComplete
            id="workingMode"
            [(ngModel)]="selectedWorkingMode"
            [suggestions]="filteredOptions"
            (completeMethod)="filterOptions($event, 'workingMode')"
            (input)="onChangeInput($event, 'workingMode')"
            [dropdown]="true"
            [forceSelection]="true"
            [placeholder]="'Selecciona una modalidad'"
            (onSelect)="onSelect($event, 'workingMode')"
            [style]="{ width: '100%' }"
            [field]="'text'"
          ></p-autoComplete>
        </div>
      </div>

      <!-- Contenedor de País y Ciudad -->
      <div class="field-container">
        <!-- País -->
        <div class="field">
          <label for="country" class="font-semibold">País</label>
          <p-autoComplete
            id="country"
            [(ngModel)]="selectedCountry"
            [suggestions]="filteredOptions"
            (completeMethod)="filterOptions($event, 'country')"
            (input)="onChangeInput($event, 'country')"
            [dropdown]="true"
            [forceSelection]="true"
            [placeholder]="'Selecciona un país'"
            (onSelect)="onSelect($event, 'country')"
            [style]="{ width: '100%' }"
            [field]="'text'"
          ></p-autoComplete>
        </div>

        <!-- Ciudad -->
        <div class="field">
          <label for="city" class="font-semibold">Ciudad</label>
          <p-autoComplete
            id="city"
            [(ngModel)]="selectedCity"
            [suggestions]="filteredOptions"
            (completeMethod)="filterOptions($event, 'city')"
            (input)="onChangeInput($event, 'city')"
            [dropdown]="true"
            [forceSelection]="true"
            [placeholder]="'Selecciona una ciudad'"
            (onSelect)="onSelect($event, 'city')"
            [style]="{ width: '100%' }"
            [field]="'text'"
          ></p-autoComplete>
        </div>
      </div>
    </div>

    <div class="column">
      <!-- Nombres -->
      <div class="field">
        <label for="name" class="font-semibold">Nombres</label>
        <input
          pInputText
          id="name"
          type="text"
          [(ngModel)]="name"
          (ngModelChange)="onChangeInput($event, 'name')"
          [placeholder]="'Ingresa nombres completos'"
        />
      </div>

      <!-- Apellidos -->
      <div class="field">
        <label for="lastname" class="font-semibold">Apellidos</label>
        <input
          pInputText
          id="lastname"
          type="text"
          [(ngModel)]="lastname"
          (ngModelChange)="onChangeInput($event, 'lastname')"
          [placeholder]="'Ingresa apellidos completos'"
        />
      </div>

      <div class="field-container">
        <!-- Tipo de Documento -->
        <div class="field">
          <label for="typeDocument" class="font-semibold"
            >Tipo de Documento</label
          >
          <p-autoComplete
            id="typeDocument"
            [(ngModel)]="selectedTypeDocument"
            [suggestions]="filteredOptions"
            (completeMethod)="filterOptions($event, 'typeDocument')"
            (input)="onChangeInput($event, 'typeDocument')"
            [dropdown]="true"
            [forceSelection]="true"
            [placeholder]="'Selecciona un tipo'"
            (onSelect)="onSelect($event, 'typeDocument')"
            [style]="{ width: '100%' }"
            [field]="'text'"
          ></p-autoComplete>
        </div>

        <!-- Documento -->
        <div class="field">
          <label for="document" class="font-semibold"
            >Número de Documento</label
          >
          <input
            id="document"
            type="text"
            pInputText
            [(ngModel)]="document"
            (ngModelChange)="onChangeInput($event, 'document')"
            (keypress)="onlyNumbers($event)"
            [maxlength]="maxLength"
            placeholder="Ingresa número de documento"
          />
        </div>
      </div>

      <div class="field-container">
        <!-- Fecha de Cumpleaños -->
        <div class="calendar-item">
          <label for="birthDate">Fecha de Nacimiento</label>
          <p-calendar
            id="birthDate"
            [(ngModel)]="birthDate"
            [showIcon]="true"
            dateFormat="dd/mm/yy"
            placeholder="DD/MM/YYYY"
            (onSelect)="validateFormEmployee()"
            (onBlur)="validateField('birthDate')"
            [styleClass]="'custom-birthdate-calendar'"
          ></p-calendar>
        </div>

        <!-- Género -->
        <div class="field">
          <label for="gender" class="font-semibold">Género</label>
          <p-autoComplete
            id="gender"
            [(ngModel)]="selectedGender"
            [suggestions]="filteredOptions"
            (completeMethod)="filterOptions($event, 'gender')"
            (input)="onChangeInput($event, 'gender')"
            [dropdown]="true"
            [forceSelection]="true"
            [placeholder]="'Selecciona'"
            (onSelect)="onSelect($event, 'gender')"
            [style]="{ width: '100%' }"
            [field]="'text'"
          ></p-autoComplete>
        </div>
      </div>

      <div class="field-container">
        <!-- Celular -->
        <div class="field">
          <label for="phone" class="font-semibold">Celular</label>
          <p-inputGroup>
            <p-inputGroupAddon>
              <span *ngIf="selectedPhoneCode" class="phone-prefix"
                >+{{ selectedPhoneCode }}</span
              >
            </p-inputGroupAddon>
            <p-inputNumber
              id="phone"
              mode="decimal"
              inputId="withoutgrouping"
              [useGrouping]="false"
              [(ngModel)]="phone"
              (ngModelChange)="onChangeInput($event, 'phone')"
              [placeholder]="'Ingresa celular'"
            ></p-inputNumber>
          </p-inputGroup>
        </div>

        <!-- Correo -->
        <div class="field">
          <label for="email" class="font-semibold">Correo</label>
          <input
            pInputText
            id="email"
            type="email"
            [(ngModel)]="email"
            (ngModelChange)="onChangeInput($event, 'email')"
            [placeholder]="'Ingresa correo'"
          />
        </div>
      </div>

      <!-- Dirección -->
      <div class="field">
        <label for="address" class="font-semibold">Dirección</label>
        <input
          pInputText
          id="address"
          type="text"
          [(ngModel)]="address"
          (ngModelChange)="onChangeInput($event, 'address')"
          [placeholder]="'Ingresa dirección actual'"
        />
      </div>

      <div class="field-container">
        <!-- Celular Emergencia -->
        <div class="field">
          <label for="phone" class="font-semibold"
            >Contacto de emergencia</label
          >
          <p-inputGroup>
            <p-inputGroupAddon>
              <span *ngIf="selectedPhoneCode" class="phone-prefix"
                >+{{ selectedPhoneCode }}</span
              >
            </p-inputGroupAddon>
            <p-inputNumber
              id="phoneEmergency"
              mode="decimal"
              inputId="withoutgrouping"
              [useGrouping]="false"
              [(ngModel)]="phoneEmergency"
              (ngModelChange)="onChangeInput($event, 'phoneEmergency')"
              [placeholder]="'Ingresa celular'"
            ></p-inputNumber>
          </p-inputGroup>
        </div>

        <!-- Status Empleado -->
        <div class="field">
          <label for="email" class="font-semibold">Status del Empleado</label>
          <p-autoComplete
            id="statusEmployee"
            [(ngModel)]="selectedStatusEmployee"
            [suggestions]="filteredOptions"
            (completeMethod)="filterOptions($event, 'statusEmployee')"
            (input)="onChangeInput($event, 'statusEmployee')"
            [dropdown]="true"
            [forceSelection]="true"
            [placeholder]="'Selecciona un estado'"
            (onSelect)="onSelect($event, 'statusEmployee')"
            [style]="{ width: '100%' }"
            [field]="'text'"
          ></p-autoComplete>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!isLoading" style="display: flex; justify-content: center; margin-top: 1rem;">
    <!-- Botón Guardar Cambios -->
    <button
      type="button"
      pButton
      (click)="onSaveChanges()"
      [disabled]="isDisabledEmployee"
      label="Guardar cambios"
      class="btn"
    ></button>
  </div>
</p-dialog>
