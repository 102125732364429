import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  OnChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import imageCompression from 'browser-image-compression';
import { IComboBase } from '../../../interfaces/base/base.interface';
import { CompanyService } from '../../../services/company.service';
import { EmployeeService } from '../../../services/employee.service';
import { DialogService } from 'primeng/dynamicdialog';
import { forkJoin } from 'rxjs';
import { CurrencyService } from '../../../services/currency.service';
import { CountryService } from '../../../services/country.service';
import { CityService } from '../../../services/city.service';
import { TypeDocumentService } from '../../../services/type-document.service';
import { JobPositionService } from '../../../services/job-position.service';
import { WorkingModeService } from '../../../services/working-modes.service';
import { StateContractService } from '../../../services/state-contracts.service';
import { ContinuityContractsService } from '../../../services/continuity-contracts.service';
import { AmountRenewalContractsService } from '../../../services/amount-renewal-contracts.service';
import { ModalLoadingComponent } from '../../../shared/modals/modal-loading/modal-loading.component';
import { EmployeeLocalService } from '../../services/employee.service';
import { ModalSuccessComponent } from '../../../shared/modals/modal-success/modal-success.component';
import { ModalRejectComponent } from '../../../shared/modals/modal-reject/modal-reject.component';

@Component({
  selector: 'app-employee-edit-modal',
  templateUrl: './employee-edit-modal.component.html',
  styleUrls: ['./employee-edit-modal.component.scss'],
  providers: [DialogService],
})
export class EmployeeEditModalComponent implements OnChanges {
  @Input() visible: boolean = false;
  @Input() dataToEdit: any | null = null;
  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() editComplete = new EventEmitter<void>();

  constructor(
    private employeeLocalService: EmployeeLocalService,
    private currencyService: CurrencyService,
    private companyService: CompanyService,
    private employeeService: EmployeeService,
    private countryService: CountryService,
    private cityService: CityService,
    private typeDocumentService: TypeDocumentService,
    private jobPositionService: JobPositionService,
    private workingModeService: WorkingModeService,
    private stateContractService: StateContractService,
    private continuityContractsService: ContinuityContractsService,
    private amountRenewalContractsService: AmountRenewalContractsService,
    private dialogService: DialogService,
    private cdr: ChangeDetectorRef
  ) {}

  isLoading: boolean = false;
  isDisabledEmployee: boolean = true;
  filteredOptions: IComboBase[] = [];

  // Listas de opciones
  companies: IComboBase[] = [];
  country: IComboBase[] = [];
  city: IComboBase[] = [];
  typeDocuments: IComboBase[] = [];
  statusEmployee: IComboBase[] = [];
  genders: IComboBase[] = [];
  currencies: IComboBase[] = [];
  jobPositions: IComboBase[] = [];
  typeContracts: IComboBase[] = [];
  workingModes: IComboBase[] = [];
  statusContracts: IComboBase[] = [];
  continues: IComboBase[] = [];
  renewContracts: IComboBase[] = [];

  // Valores seleccionados
  selectedCompany: IComboBase | null = null;
  selectedCountry: IComboBase | null = null;
  selectedCity: IComboBase | null = null;
  selectedTypeDocument: IComboBase | null = null;
  selectedStatusEmployee: IComboBase | null = null;
  selectedGender: IComboBase | null = null;
  selectedCurrency: IComboBase | null = null;
  selectedJobPosition: IComboBase | null = null;
  selectedTypeContract: IComboBase | null = null;
  selectedWorkingMode: IComboBase | null = null;
  selectedContinuity: IComboBase | null = null;
  selectedRenewContract: IComboBase | null = null;
  selectedPhoneCode: string | null = null;

  // Datos del empleado
  name: string | null = null;
  lastname: string | null = null;
  document: string | null = null;
  phone: string | null = null;
  phoneEmergency: string | null = null;
  address: string | null = null;
  email: string | null = null;
  birthDate: Date | null = null;
  selectedPhoto: string | null = null;
  countryId: string | null = null;
  companyId: string | null = null;
  currentPhoto: File | null = null;

  maxLength: number | null = null;

  @ViewChild('imageUploader') imageUploader: any;

  hasInteracted: { [key: string]: boolean } = {
    selectedPhoto: false,
    company: false,
    typeDocument: false,
    document: false,
    name: false,
    lastname: false,
    country: false,
    city: false,
    phone: false,
    email: false,
    address: false,
    phoneEmergency: false,
    statusEmployee: false,
    gender: false,
    birthDate: false,
    currentPhoto: false,
  };

  ngOnChanges(): void {
    if (this.visible && this.dataToEdit) {
      this.isLoading = true;
      const employeeId = this.dataToEdit.employeeId;

      this.employeeService.getEmployeeById(employeeId).subscribe({
        next: (employee) => {
          const countryId = employee.countryId;
          const observables = {
            companies: this.companyService.comboCompany(),
            country: this.countryService.comboCountry(),
            city: this.cityService.comboCity(countryId),
            currencies: this.currencyService.comboCurrency(),
            jobPositions: this.jobPositionService.comboJobPosition(),
            workingModes: this.workingModeService.comboWorkingMode(),
            statusContracts: this.stateContractService.comboStateContract(),
            continues:
              this.continuityContractsService.comboContinuityContracts(),
            renewContracts:
              this.amountRenewalContractsService.comboAmountRenewalContracts(),
            typeDocuments:
              this.typeDocumentService.comboTypeDocument(countryId),
          };

          forkJoin(observables).subscribe({
            next: (data) => {
              this.companies = data.companies;
              this.country = data.country;
              this.city = data.city;
              this.currencies = data.currencies;
              this.jobPositions = data.jobPositions;
              this.workingModes = data.workingModes;
              this.statusContracts = data.statusContracts;
              this.continues = data.continues;
              this.renewContracts = data.renewContracts;
              this.typeDocuments = data.typeDocuments;

              this.statusEmployee = [
                { key: '1', text: 'Activo' },
                { key: '2', text: 'Inactivo' },
              ];

              this.genders = [
                { key: '1', text: 'Femenino' },
                { key: '2', text: 'Masculino' },
              ];

              this.selectedCompany =
                this.companies.find(
                  (c) => c.key.toString() === employee.companyId.toString()
                ) || null;

              this.selectedCountry =
                this.country.find(
                  (c) => c.key.toString() === employee.countryId.toString()
                ) || null;

              this.selectedCity =
                this.city.find(
                  (c) => c.key.toString() === employee.cityId.toString()
                ) || null;

              this.selectedJobPosition =
                this.jobPositions.find(
                  (c) => c.key.toString() === employee.jobPositionId.toString()
                ) || null;

              this.selectedWorkingMode =
                this.workingModes.find(
                  (t) => t.key.toString() === employee.workingModeId.toString()
                ) || null;

              this.selectedTypeDocument =
                this.typeDocuments.find(
                  (t) => t.key.toString() === employee.typedocumentid.toString()
                ) || null;

              if (this.selectedTypeDocument?.key.toString() === '1') {
                this.maxLength = 8;
              } else {
                this.maxLength = null;
              }

              type Gender = 'F' | 'M';

              const genderMap: Record<Gender, string> = {
                F: '1', // Femenino
                M: '2', // Masculino
              };

              const genderKey = genderMap[employee.gender as Gender];

              this.selectedGender =
                this.genders.find((g) => g.key === genderKey) || null;

              const statusMap = {
                true: '1', // Activo
                false: '2', // Inactivo
              };

              const statusKey =
                statusMap[
                  employee.employeeState.toString() as keyof typeof statusMap
                ];

              this.selectedStatusEmployee =
                this.statusEmployee.find((s) => s.key === statusKey) || null;

              this.name = employee.names;
              this.lastname = employee.lastNames;
              this.document = employee.employeeDocumentNumber;
              this.selectedPhoneCode = employee.phoneCode;
              this.phone = employee.phone;
              this.phoneEmergency = employee.phoneEmergency;
              this.address = employee.address;
              this.email = employee.email;

              const [day, month, year] = employee.birthDate
                .split('/')
                .map(Number);
              this.birthDate = new Date(year, month - 1, day);

              this.selectedPhoto = employee.imageEmployee;

              this.isLoading = false;
              this.validateFormEmployee();
              this.cdr.detectChanges();
            },
            error: (err) => {
              this.isLoading = false;
              console.error('Error al cargar las listas de opciones:', err);
            },
          });
        },
        error: (err) => {
          this.isLoading = false;
          console.error('Error al cargar los datos del empleado:', err);
        },
      });
    }
  }

  // Método para filtrar opciones
  filterOptions(event: any, type: string) {
    const query = event.query.toLowerCase();
    let options: IComboBase[] = [];
    switch (type) {
      case 'company':
        options = this.companies;
        break;
      case 'country':
        options = this.country;
        break;
      case 'city':
        options = this.city;
        break;
      case 'typeDocument':
        options = this.typeDocuments;
        break;
      case 'statusEmployee':
        options = this.statusEmployee;
        break;
      case 'gender':
        options = this.genders;
        break;
      case 'renewContract':
        options = this.renewContracts;
        break;
      case 'statusContract':
        options = this.statusContracts;
        break;
      case 'typeContract':
        options = this.typeContracts;
        break;
      case 'currency':
        options = this.currencies;
        break;
      case 'jobPosition':
        options = this.jobPositions;
        break;
      case 'workingMode':
        options = this.workingModes;
        break;
      default:
        console.error('Tipo no reconocido:', type);
    }
    this.filteredOptions = options.filter((option) =>
      option.text.toLowerCase().includes(query)
    );
  }

  onChangeInput(event: any, type: string) {
    this.hasInteracted[type] = true;
    switch (type) {
      case 'company':
        this.selectedCompany = null;
        break;
      case 'country':
        this.selectedCountry = null;
        break;
      case 'city':
        this.selectedCity = null;
        break;
      case 'typeDocument':
        this.selectedTypeDocument = null;
        break;
      case 'statusEmployee':
        this.selectedStatusEmployee = null;
        break;
      case 'gender':
        this.selectedGender = null;
        break;
      case 'birthDate':
        this.birthDate = event;
        break;
      case 'name':
        this.name = event;
        break;
      case 'lastname':
        this.lastname = event;
        break;
      case 'address':
        this.address = event;
        break;
      case 'email':
        this.email = event;
        break;
      case 'phone':
        this.phone = event;
        break;
      case 'phoneEmergency':
        this.phoneEmergency = event;
        break;
      case 'document':
        this.document = event;
        break;
      case 'salary':
        this.document = event;
        break;
      default:
        console.error('Tipo no reconocido:', type);
    }
    this.validateFormEmployee();
  }

  onSelect(event: any, type: string) {
    this.hasInteracted[type] = true;

    switch (type) {
      case 'company':
        this.selectedCompany = event.value ?? null;
        break;
      case 'country':
        this.selectedCountry = event.value ?? null;
        this.selectedPhoneCode = event.value?.phonecode ?? null;
        this.countryId = this.selectedCountry?.key!;

        this.selectedCity = null;
        this.selectedTypeDocument = null;

        if (this.countryId) {
          this.cityService.comboCity(this.countryId).subscribe({
            next: (data) => {
              this.city = data;
            },
            error: (err) => {
              console.error('Error al obtener la lista de ciudades:', err);
            },
          });

          this.typeDocumentService.comboTypeDocument(this.countryId).subscribe({
            next: (data) => {
              this.typeDocuments = data;
            },
            error: (err) => {
              console.error('Error al obtener los tipos de documentos:', err);
            },
          });
        }
        break;
      case 'city':
        this.selectedCity = event.value ?? null;
        break;
      case 'typeDocument':
        this.selectedTypeDocument = event.value ?? null;

        if (this.selectedTypeDocument?.key.toString() === '1') {
          this.maxLength = 8;
        } else {
          this.maxLength = null;
          this.document = null;
        }
        break;
      case 'statusEmployee':
        this.selectedStatusEmployee = event.value ?? null;
        break;
      case 'gender':
        this.selectedGender = event.value ?? null;
        break;
      case 'renewContract':
        this.selectedRenewContract = event.value ?? null;
        break;
      case 'jobPosition':
        this.selectedJobPosition = event.value ?? null;
        break;
      case 'workingMode':
        this.selectedWorkingMode = event.value ?? null;
        break;
      default:
        console.error('Tipo no reconocido:', type);
    }

    this.validateFormEmployee();
  }

  validateField(field: string) {
    this.hasInteracted[field] = true;
  }

  validateFormEmployee() {
    this.isDisabledEmployee = !(
      this.selectedCompany &&
      this.selectedTypeDocument &&
      this.document &&
      this.birthDate &&
      this.selectedGender &&
      this.name &&
      this.lastname &&
      this.selectedCountry &&
      this.selectedCity &&
      this.phone &&
      this.email &&
      this.address &&
      this.phoneEmergency &&
      this.selectedStatusEmployee
    );
  }

  onPhotoSelect(event: any) {
    const file = event.files[0];
    if (file) {
      const maxSize = 10 * 1024 * 1024;
      if (file.size > maxSize) {
        const ref = this.dialogService.open(ModalRejectComponent, {
          header: '',
          data: {
            text: 'La imagen es demasiado grande. El tamaño máximo permitido es de 10 MB.',
            title: '¡Upss!',
            showButton: true,
            buttonText: 'Entendido',
          },
        });
        this.validateFormEmployee();
        return;
      }

      this.currentPhoto = file;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.selectedPhoto = e.target.result;
        this.validateFormEmployee();
      };
      reader.readAsDataURL(file);
    }
  }

  openFileUpload(type: string) {
    if (type === 'image' && this.imageUploader) {
      this.imageUploader.choose();
    } else {
      console.error('El fileUploader no está disponible.');
    }
  }

  editPhoto() {
    this.openFileUpload('image');
    this.validateFormEmployee();
  }

  addPhoto() {
    this.openFileUpload('image');
  }

  removePhoto() {
    this.selectedPhoto = null;
    this.currentPhoto = null;
    this.validateFormEmployee();
  }

  onlyNumbers(event: KeyboardEvent) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  }

  async compressImage(file: File, maxSizeMB: number): Promise<File> {
    if (file.size / (1024 * 1024) <= maxSizeMB) {
      console.log(
        `📌 La imagen ya es menor a ${maxSizeMB}MB, no es necesario comprimirla.`
      );
      return file;
    }

    const options = {
      maxSizeMB,
      maxWidthOrHeight: 1024,
      useWebWorker: true, // Usa Web Workers para no bloquear la UI
    };

    try {
      return await imageCompression(file, options);
    } catch (error) {
      console.error('❌ Error al comprimir la imagen:', error);
      return file; // En caso de error, usa la imagen original
    }
  }

  // ✅ Método para guardar los cambios
  async onSaveChanges() {
    const loadingRef = this.dialogService.open(ModalLoadingComponent, {
      closable: false,
    });

    const request = {
      companyId: this.selectedCompany?.key,
      typeDocumentId: this.selectedTypeDocument?.key,
      employeeDocumentNumber: this.document,
      names: this.name,
      lastNames: this.lastname,
      countryId: this.selectedCountry?.key,
      cityId: this.selectedCity?.key,
      address: this.address,
      email: this.email,
      phone: this.phone?.toString(),
      phoneEmergency: this.phoneEmergency?.toString(),
      employeeState: (this.selectedStatusEmployee?.key == '1'
        ? true
        : false
      ).toString(),
      gender: this.selectedGender?.key == '1' ? 'F' : 'M',
      birthDate: this.birthDate?.toISOString().replace('Z', ''),
      jobPositionId: this.selectedJobPosition?.key,
      typeContractId: this.selectedTypeContract?.key,
      workingModeId: this.selectedWorkingMode?.key,
      currencyId: this.selectedCurrency?.key,
      continuityContractId: this.selectedContinuity?.key,
    };

    const formData = new FormData();

    const appendIfDefined = (key: string, value: any) => {
      if (value !== undefined && value !== null) {
        formData.append(key, value);
      }
    };

    // Agregar datos al FormData
    Object.entries(request).forEach(([key, value]) =>
      appendIfDefined(key, value)
    );

    if (this.currentPhoto) {
      // ✅ Comprimir la imagen antes de agregarla al FormData
      this.currentPhoto = await this.compressImage(this.currentPhoto, 1);

      formData.append('file', this.currentPhoto);
    }

    // ✅ Llamar al servicio para actualizar el empleado
    this.employeeLocalService
      .updateEmployee(this.dataToEdit.employeeId, formData)
      .subscribe({
        next: () => {
          loadingRef.close();
          this.closeDialog(); // Cierra el diálogo después de la actualización exitosa

          this.dialogService.open(ModalSuccessComponent, {
            header: '',
            data: {
              text: 'El empleado se ha actualizado con éxito.',
              title: '¡Éxito!',
              icon: 'pi pi-check-circle',
              showButton: true,
              buttonText: 'Entendido',
            },
          });

          this.editComplete.emit();
        },
        error: (err) => {
          loadingRef.close();
          this.closeDialog(); // Cierra el diálogo incluso si hay error
          let errorMessage =
            'Lo sentimos, ocurrió un error. Por favor, inténtelo nuevamente.';

          if (err.error) {
            errorMessage =
              typeof err.error === 'string'
                ? err.error
                : Object.values(err.error.error || err.error).join(' ');
          }

          console.error('❌ Error al actualizar el empleado:', err);

          this.dialogService.open(ModalRejectComponent, {
            header: '',
            data: {
              text: errorMessage,
              title: '¡Lo sentimos!',
              showButton: true,
              buttonText: 'Entendido',
            },
          });
        },
      });
  }

  // ✅ Método para cerrar el diálogo
  closeDialog(): void {
    this.visible = false;
    this.visibleChange.emit(this.visible);
  }
}
