import { Component, ViewChild } from '@angular/core';
import { IComboBase } from '../../../interfaces/base/base.interface';
import { CompanyService } from '../../../services/company.service';
import { DialogService } from 'primeng/dynamicdialog';
import { ModalSuccessComponent } from '../../../shared/modals/modal-success/modal-success.component';
import { ModalLoadingComponent } from '../../../shared/modals/modal-loading/modal-loading.component';
import { ModalRejectComponent } from '../../../shared/modals/modal-reject/modal-reject.component';
import { EmployeeLocalService } from '../../services/employee.service';
import { Router } from '@angular/router';
import { EmployeeService } from '../../../services/employee.service';
import { CurrencyService } from '../../../services/currency.service';
import { JobPositionService } from '../../../services/job-position.service';
import { TypeContractService } from '../../../services/type-contracts.service';
import { WorkingModeService } from '../../../services/working-modes.service';
import { StateContractService } from '../../../services/state-contracts.service';

@Component({
  selector: 'app-contract-register',
  templateUrl: './contract-register.component.html',
  styleUrls: ['./contract-register.component.scss'],
  providers: [DialogService],
})
export class ContractRegisterComponent {
  userData: any = null;
  selectedCompany: IComboBase | null = null;
  selectedTypeContract: IComboBase | null = null;
  selectedJobPosition: IComboBase | null = null;
  selectedEmployee: IComboBase | null = null;
  selectedRenewContract: IComboBase | null = null;
  selectedCurrency: IComboBase | null = null;
  selectedContinuity: IComboBase | null = null;
  selectedWorkingMode: IComboBase | null = null;
  selectedStatusContract: IComboBase | null = null;
  selectedCurrencyCode: string | null = null;
  filteredOptions: IComboBase[] = [];

  salary: number | null = null;
  currentFile: File | null = null;

  companies: IComboBase[] = [];
  typeContracts: IComboBase[] = [];
  jobPositions: IComboBase[] = [];
  employees: IComboBase[] = [];
  renewContracts: IComboBase[] = [];
  currencies: IComboBase[] = [];
  workingModes: IComboBase[] = [];
  continues: IComboBase[] = [];
  statusContracts: IComboBase[] = [];

  companyId: string | null = null;
  employeeId: number | null = null;
  stateContractId: number | null = null;
  isDisabled: boolean = true;

  startDate: Date | null = null;
  endDate: Date | null = null;
  minDate: Date | null = null;
  maxStartDate: Date | null = null;
  maxEndDate: Date | null = null;

  @ViewChild('fileUploader') fileUploader: any;
  selectedFileName: string | null = null;

  hasInteracted: { [key: string]: boolean } = {
    typeContract: false,
    currency: false,
    startDate: false,
    endDate: false,
    salary: false,
    statusContract: false,
    continue: false,
    currentFile: false,
  };

  constructor(
    private employeeLocalService: EmployeeLocalService,
    private companyService: CompanyService,
    private jobPositionService: JobPositionService,
    private currencyService: CurrencyService,
    private typeContractService: TypeContractService,
    private workingModeService: WorkingModeService,
    private stateContractService: StateContractService,
    private dialogService: DialogService,
    private router: Router
  ) {}

  ngOnInit(): void {
    const userDataString = localStorage.getItem('access');
    if (userDataString) {
      this.userData = JSON.parse(userDataString);
    }

    const state = history.state as { employeeId: string };
    console.log('this.employeeId state ' + state?.employeeId);

    if (state && state.employeeId) {
      this.employeeId = Number(state.employeeId);
      console.log('this.employeeId ' + this.employeeId);
    }

    this.resetForm();

    this.companyService.comboCompany().subscribe({
      next: (data) => {
        this.companies = data;
      },
      error: (err) => {
        console.error('Error al obtener la lista de empresas:', err);
      },
    });

    this.currencyService.comboCurrency().subscribe({
      next: (data) => {
        this.currencies = data;
      },
      error: (err) => {
        console.error('Error al obtener la lista de monedas:', err);
      },
    });

    this.jobPositionService.comboJobPosition().subscribe({
      next: (data) => {
        this.jobPositions = data;
      },
      error: (err) => {
        console.error('Error al obtener la lista de puestos:', err);
      },
    });

    this.typeContractService.comboTypeContract().subscribe({
      next: (data) => {
        this.typeContracts = data;
      },
      error: (err) => {
        console.error('Error al obtener la lista de contratos:', err);
      },
    });

    this.workingModeService.comboWorkingMode().subscribe({
      next: (data) => {
        this.workingModes = data;
      },
      error: (err) => {
        console.error('Error al obtener la lista de modalidades:', err);
      },
    });

    this.stateContractService.comboStateContract().subscribe({
      next: (data) => {
        this.statusContracts = data;
      },
      error: (err) => {
        console.error('Error al obtener la lista de modalidades:', err);
      },
    });

    this.stateContractId = 2;

    this.continues = [
      { key: '1', text: 'Sí, actualmente tiene este cargo' },
      { key: '2', text: 'No, solo estará durante los meses de prueba' },
      { key: '3', text: 'Finalizó su contrato' },
    ];

    this.renewContracts = [
      { key: '1', text: '1 vez' },
      { key: '2', text: '2 veces' },
      { key: '3', text: '+ de 3 veces' },
      { key: '4', text: 'Plazo definido' },
    ];
  }

  validateForm() {
    this.isDisabled = !(
      this.selectedTypeContract &&
      this.startDate &&
      this.endDate &&
      this.selectedContinuity &&
      this.selectedCurrency &&
      this.salary
    );
  }

  // Método para filtrar opciones
  filterOptions(event: any, type: string) {
    const query = event.query.toLowerCase();
    let options: IComboBase[] = [];
    switch (type) {
      case 'renewContract':
        options = this.renewContracts;
        break;
      case 'statusContract':
        options = this.statusContracts;
        break;
      case 'typeContract':
        options = this.typeContracts;
        break;
      case 'currency':
        options = this.currencies;
        break;
      default:
        console.error('Tipo no reconocido:', type);
    }
    this.filteredOptions = options.filter((option) =>
      option.text.toLowerCase().includes(query)
    );
  }

  // Manejar selección
  onSelect(event: any, type: string) {
    this.hasInteracted[type] = true;

    switch (type) {
      case 'statusContracts':
        this.selectedStatusContract = event.value ?? null;
        break;
      case 'renewContract':
        this.selectedRenewContract = event.value ?? null;
        break;
      case 'typeContract':
        this.selectedTypeContract = event.value ?? null;
        break;
      case 'currency':
        this.selectedCurrency = event.value ?? null;
        this.selectedCurrencyCode =
          this.selectedCurrency?.key == '1' ? 'S/.' : '$';
        break;
      default:
        console.error('Tipo no reconocido:', type);
    }

    this.validateForm();
  }

  // Manejar el cambio de archivo
  openFileUpload() {
    this.fileUploader.choose();
  }

  onRegister() {
    const loadingRef = this.dialogService.open(ModalLoadingComponent, {
      closable: false,
    });

    const request = {
      userAdminId: this.userData.employeeId,
      employeeId: this.employeeId,
      typeContractId: this.selectedTypeContract?.key,
      currencyId: this.selectedCurrency?.key,
      continuityContractId: this.selectedContinuity?.key,
      startDate: this.startDate?.toISOString().replace('Z', ''),
      endDate: this.endDate?.toISOString().replace('Z', ''),
      stateContractId: this.stateContractId,
      salary: this.salary?.toString(),
      currentFile: this.currentFile,
    };

    const formData = new FormData();

    const appendIfDefined = (key: string, value: any) => {
      if (value !== undefined) {
        formData.append(key, value);
      }
    };

    // Agregar los datos adicionales
    appendIfDefined('userId', request.userAdminId);
    appendIfDefined('employeeId', request.employeeId);
    appendIfDefined('typeContractId', request.typeContractId);
    appendIfDefined('currencyId', request.currencyId);
    appendIfDefined('continuityContractId', request.continuityContractId);
    appendIfDefined('startDate', request.startDate);
    appendIfDefined('endDate', request.endDate);
    appendIfDefined('stateContractId', request.stateContractId);
    appendIfDefined('salary', request.salary);

    if (!this.currentFile) {
      // formData.append('file', '');
    } else {
      appendIfDefined('file', request.currentFile);
    }

    // Llamar al servicio para registrar el recibo de pago
    this.employeeLocalService.createContract(formData).subscribe({
      next: (response) => {
        loadingRef.close();
        const ref = this.dialogService.open(ModalSuccessComponent, {
          header: '',
          data: {
            text: 'Tu renovación se ha realizado con éxito.',
            title: '¡Éxito!',
            icon: 'pi pi-check-circle',
            showButton: true,
            buttonText: 'Entendido',
          },
        });
        this.goBack();
      },

      error: (err) => {
        loadingRef.close();
        let errorMessage =
          'Lo sentimos, ocurrió un error. Por favor, inténtelo nuevamente.';
        if (err.error) {
          if (typeof err.error === 'string') {
            errorMessage = err.error;
          } else if (typeof err.error === 'object') {
            if (err.error.error && typeof err.error.error === 'object') {
              errorMessage = Object.values(err.error.error).join(' ');
            } else {
              errorMessage = Object.values(err.error).join(' ');
            }
          }
        }
        const ref = this.dialogService.open(ModalRejectComponent, {
          header: '',
          data: {
            text: errorMessage,
            title: '¡Lo sentimos!',
            showButton: true,
            buttonText: 'Entendido',
          },
        });
        this.resetForm();
        console.error('Error al registrar el contrato:', err);
      },
    });
  }

  // Limpiar los campos del modal
  resetForm() {
    this.selectedTypeContract = null;
    this.selectedRenewContract = null;
    this.startDate = null;
    this.endDate = null;
    this.selectedContinuity = null;
    this.selectedRenewContract = null;
    this.selectedCurrency = null;
    this.salary = null;
    this.selectedStatusContract = null;
    this.selectedCurrencyCode = null;
    this.currentFile = null;
    this.selectedFileName = null;
    this.stateContractId = 4;

    this.hasInteracted = {
      typeContract: false,
      currency: false,
      startDate: false,
      endDate: false,
      salary: false,
      statusContract: false,
      continue: false,
      currentFile: false,
    };

    if (this.fileUploader) {
      this.fileUploader.clear();
    }

    this.validateForm();
  }

  onChangeInput(event: any, type: string) {
    this.hasInteracted[type] = true;
    switch (type) {
      case 'typeContract':
        this.selectedTypeContract = null;
        break;
      case 'renewContract':
        this.selectedRenewContract = null;
        break;
      case 'currency':
        this.selectedCurrency = null;
        break;
      case 'statusContract':
        this.selectedStatusContract = null;
        break;
      case 'salary':
        this.salary = event;
        break;
      default:
        console.error('Tipo no reconocido:', type);
    }
    this.validateForm();
  }

  validateField(field: string) {
    this.hasInteracted[field] = true;
  }

  onChangeFile(event: any) {
    const selectedFile = event.files[0];
    if (selectedFile) {
      const maxSize = 10 * 1024 * 1024;
      const allowedTypes = ['application/pdf'];

      if (!allowedTypes.includes(selectedFile.type)) {
        const ref = this.dialogService.open(ModalRejectComponent, {
          header: '',
          data: {
            text: 'El archivo no es válido. Solo se permiten archivos PDF.',
            title: '¡Upss!',
            showButton: true,
            buttonText: 'Entendido',
          },
        });
        this.currentFile = null;
        this.selectedFileName = null;
        this.stateContractId = 4;
        this.validateForm();
        return;
      }

      if (selectedFile.size > maxSize) {
        const ref = this.dialogService.open(ModalRejectComponent, {
          header: '',
          data: {
            text: 'El archivo es demasiado grande. El tamaño máximo permitido es de 10 MB.',
            title: '¡Upss!',
            showButton: true,
            buttonText: 'Entendido',
          },
        });
        this.currentFile = null;
        this.selectedFileName = null;
        this.stateContractId = 4;
        this.validateForm();
        return;
      }

      this.currentFile = selectedFile;
      this.selectedFileName = selectedFile.name;
      this.stateContractId = 2;
      this.validateForm();
    }
  }

  goBack() {
    this.resetForm();
    this.router.navigate(['/dashboard/employee/contract']);
  }

  onStartDateChange(selectedStartDate: Date): void {
    if (selectedStartDate) {
      this.startDate = selectedStartDate;

      this.maxEndDate = null;
      this.endDate = null;
    } else {
      this.startDate = null;
      this.maxEndDate = null;
    }
  }

  onEndDateChange(selectedEndDate: Date): void {
    if (selectedEndDate && this.startDate && selectedEndDate < this.startDate) {
      this.endDate = null;
    } else {
      this.endDate = selectedEndDate;
    }
  }

  onCheckboxChange(value: IComboBase) {
    this.selectedContinuity = value;
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    event.dataTransfer!.dropEffect = 'copy';
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();

    if (event.dataTransfer && event.dataTransfer.files.length > 0) {
      const droppedFile = event.dataTransfer.files[0];

      if (this.isValidFile(droppedFile)) {
        this.currentFile = droppedFile;
        this.selectedFileName = droppedFile.name;
        this.stateContractId = 2;
        this.validateForm();
      } else {
        this.currentFile = null;
        this.selectedFileName = null;
        this.stateContractId = 4;
        this.validateForm();

        const ref = this.dialogService.open(ModalRejectComponent, {
          header: '',
          data: {
            text: 'El archivo no es válido. Solo se permiten archivos PDF, DOC o DOCX de hasta 10 MB.',
            title: '¡Upss!',
            showButton: true,
            buttonText: 'Entendido',
          },
        });
      }
    }
  }

  isValidFile(file: File): boolean {
    const allowedExtensions = [
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ];
    const maxSize = 10 * 1024 * 1024;

    if (!allowedExtensions.includes(file.type)) {
      console.log('Tipo de archivo no permitido:', file.type);
      return false;
    }

    if (file.size > maxSize) {
      console.log('Archivo demasiado grande:', file.size);
      return false;
    }

    return true;
  }
}
