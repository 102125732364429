<div class="card">
  <div class="column">
    <h2 class="label">
      <button type="button" pButton (click)="goBack()" class="btn-back">
        <i class="pi pi-arrow-circle-left"></i>
      </button>
      Agregar renovación
    </h2>
    <div class="row">
      <div class="column">
        <div class="field-container">
          <!-- Empresa -->
          <!-- <div class="field">
            <label for="company" class="font-semibold">Empresa</label>
            <p-autoComplete
              id="company"
              [(ngModel)]="selectedCompany"
              [suggestions]="filteredOptions"
              (completeMethod)="filterOptions($event, 'company')"
              (input)="onChangeInput($event, 'company')"
              [dropdown]="true"
              [forceSelection]="true"
              [placeholder]="'Selecciona una empresa'"
              (onSelect)="onSelect($event, 'company')"
              [style]="{ width: '100%' }"
              [field]="'text'"
            ></p-autoComplete>
            <div
              *ngIf="!selectedCompany && hasInteracted['company']"
              class="error-message"
              style="color: red; font-size: 0.875rem; margin-top: 2px"
            >
              Este campo es obligatorio.
            </div>
          </div> -->

          <!-- Documento -->
          <div class="field">
            <label for="typeContract" class="font-semibold"
              >Tipo de Contrato</label
            >
            <p-autoComplete
              id="typeContract"
              [(ngModel)]="selectedTypeContract"
              [suggestions]="filteredOptions"
              (completeMethod)="filterOptions($event, 'typeContract')"
              (input)="onChangeInput($event, 'typeContract')"
              [dropdown]="true"
              [forceSelection]="true"
              [placeholder]="'Selecciona un tipo'"
              (onSelect)="onSelect($event, 'typeContract')"
              [style]="{ width: '100%' }"
              [field]="'text'"
            ></p-autoComplete>
            <div
              *ngIf="!selectedTypeContract && hasInteracted['typeContract']"
              class="error-message"
              style="color: red; font-size: 0.875rem; margin-top: 2px"
            >
              Este campo es obligatorio.
            </div>
          </div>
        </div>

        <!-- Empresa -->
        <!-- <div class="field">
          <label for="employee" class="font-semibold">Empleado</label>
          <p-autoComplete
            id="employee"
            [(ngModel)]="selectedEmployee"
            [suggestions]="filteredOptions"
            (completeMethod)="filterOptions($event, 'employee')"
            (input)="onChangeInput($event, 'employee')"
            [dropdown]="true"
            [forceSelection]="true"
            [placeholder]="'Selecciona un empleado'"
            (onSelect)="onSelect($event, 'employee')"
            [style]="{ width: '100%' }"
            [field]="'text'"
          ></p-autoComplete>
          <div
            *ngIf="!selectedEmployee && hasInteracted['employee']"
            class="error-message"
            style="color: red; font-size: 0.875rem; margin-top: 2px"
          >
            Este campo es obligatorio.
          </div>
        </div> -->

        <!-- <div class="field-container">
    
          <div class="field">
            <label for="jobPosition" class="font-semibold"
              >Puesto o Cargo</label
            >
            <p-autoComplete
              id="jobPosition"
              [(ngModel)]="selectedJobPosition"
              [suggestions]="filteredOptions"
              (completeMethod)="filterOptions($event, 'jobPosition')"
              (input)="onChangeInput($event, 'jobPosition')"
              [dropdown]="true"
              [forceSelection]="true"
              [placeholder]="'Selecciona un tipo'"
              (onSelect)="onSelect($event, 'jobPosition')"
              [style]="{ width: '100%' }"
              [field]="'text'"
            ></p-autoComplete>
            <div
              *ngIf="!selectedJobPosition && hasInteracted['jobPosition']"
              class="error-message"
              style="color: red; font-size: 0.875rem; margin-top: 2px"
            >
              Este campo es obligatorio.
            </div>
          </div>

        
          <div class="field">
            <label for="workingMode" class="font-semibold">Modalidad</label>
            <p-autoComplete
              id="mode"
              [(ngModel)]="selectedWorkingMode"
              [suggestions]="filteredOptions"
              (completeMethod)="filterOptions($event, 'workingMode')"
              (input)="onChangeInput($event, 'workingMode')"
              [dropdown]="true"
              [forceSelection]="true"
              [placeholder]="'Selecciona un tipo'"
              (onSelect)="onSelect($event, 'workingMode')"
              [style]="{ width: '100%' }"
              [field]="'text'"
            ></p-autoComplete>
            <div
              *ngIf="!selectedWorkingMode && hasInteracted['workingMode']"
              class="error-message"
              style="color: red; font-size: 0.875rem; margin-top: 2px"
            >
              Este campo es obligatorio.
            </div>
          </div>
        </div> -->

        <!-- Selección de Fechas -->
        <div class="calendar-row">
          <div class="calendar-item">
            <label for="startDate">Fecha de Inicio</label>
            <p-calendar
              id="startDate"
              [(ngModel)]="startDate"
              [icon]="'pi pi-pencil'"
              [showIcon]="true"
              (ngModelChange)="onStartDateChange($event)"
              [maxDate]="maxStartDate"
              dateFormat="dd/mm/yy"
              placeholder="DD/MM/YYYY"
              (onBlur)="validateField('startDate')"
            ></p-calendar>
          </div>
          <div class="calendar-item">
            <label for="endDate">Fecha de Finalización</label>
            <p-calendar
              id="endDate"
              [(ngModel)]="endDate"
              [icon]="'pi pi-pencil'"
              [showIcon]="true"
              (ngModelChange)="onEndDateChange($event)"
              [minDate]="startDate"
              [maxDate]="maxEndDate"
              dateFormat="dd/mm/yy"
              placeholder="DD/MM/YYYY"
              (onBlur)="validateField('endDate')"
            ></p-calendar>
          </div>
        </div>

        <!-- Confirmación de continuidad -->
        <div class="field">
          <label for="confirmation"
            >¿Proseguirá el empleado en la empresa?</label
          >
          <div *ngFor="let continue of continues" class="confirmation">
            <p-checkbox
            [ngModel]="selectedContinuity?.key === continue.key"
            inputId="confirmation-{{ continue.key }}"
            [label]="continue.text"
            [binary]="true"
            [value]="continue"
            (onChange)="onCheckboxChange(continue)"
            />
          </div>
          <div
            *ngIf="!selectedContinuity && hasInteracted['continue']"
            class="error-message"
          >
            Este campo es obligatorio.
          </div>
        </div>
      </div>

      <div class="column">
        <!-- <div class="field">
          <label for="renewContract" class="font-semibold"
            >¿Cuántas veces se renovó el contrato?</label
          >
          <p-autoComplete
            id="renewContract"
            [(ngModel)]="selectedRenewContract"
            [suggestions]="filteredOptions"
            (completeMethod)="filterOptions($event, 'renewContract')"
            (input)="onChangeInput($event, 'renewContract')"
            [dropdown]="true"
            [forceSelection]="true"
            [placeholder]="'Selecciona una opción'"
            (onSelect)="onSelect($event, 'renewContract')"
            [style]="{ width: '100%' }"
            [field]="'text'"
          ></p-autoComplete>
          <div
            *ngIf="!selectedRenewContract && hasInteracted['renewContract']"
            class="error-message"
          >
            Este campo es obligatorio.
          </div>
        </div> -->

        <div class="field-container">
          <!-- Moneda -->
          <div class="field">
            <label for="currency" class="font-semibold">Moneda</label>
            <p-autoComplete
              id="currency"
              [(ngModel)]="selectedCurrency"
              [suggestions]="filteredOptions"
              (completeMethod)="filterOptions($event, 'currency')"
              (input)="onChangeInput($event, 'currency')"
              [dropdown]="true"
              [forceSelection]="true"
              [placeholder]="'Selecciona una moneda'"
              (onSelect)="onSelect($event, 'currency')"
              [style]="{ width: '100%' }"
              [field]="'text'"
            ></p-autoComplete>
            <div
              *ngIf="!selectedCurrency && hasInteracted['currency']"
              class="error-message"
            >
              Este campo es obligatorio.
            </div>
          </div>

          <!-- Salario -->
          <div class="field">
            <label for="salary" class="font-semibold">Salario</label>
            <p-inputGroup>
              <p-inputGroupAddon>
                <span *ngIf="selectedCurrencyCode" class="phone-prefix">{{
                  selectedCurrencyCode
                }}</span>
              </p-inputGroupAddon>
              <p-inputNumber
                id="salary"
                mode="decimal"
                [minFractionDigits]="2"
                [maxFractionDigits]="2"
                inputId="withoutgrouping"
                [useGrouping]="false"
                [(ngModel)]="salary"
                (ngModelChange)="onChangeInput($event, 'salary')"
                [placeholder]="'Ingresa salario'"
              ></p-inputNumber>
            </p-inputGroup>
            <div
              *ngIf="!salary && hasInteracted['salary']"
              style="color: red; font-size: 0.875rem; margin-top: 2px"
            >
              Este campo es obligatorio.
            </div>
          </div>
        </div>

        <!-- Status Contrato -->
        <!-- <div class="field">
          <label for="statusContract" class="font-semibold"
            >Status del Contrato</label
          >
          <p-autoComplete
            id="statusContract"
            [(ngModel)]="selectedStatusContract"
            [suggestions]="filteredOptions"
            (completeMethod)="filterOptions($event, 'statusContract')"
            (input)="onChangeInput($event, 'statusContract')"
            [dropdown]="true"
            [forceSelection]="true"
            [placeholder]="'Selecciona un estado'"
            (onSelect)="onSelect($event, 'statusContract')"
            [style]="{ width: '100%' }"
            [field]="'text'"
          ></p-autoComplete>
          <div
            *ngIf="!selectedStatusContract && hasInteracted['statusContract']"
            class="error-message"
          >
            Este campo es obligatorio.
          </div>
        </div> -->

        <!-- Carga de PDF -->
        <div class="field">
          <label for="fotoPerfil" class="font-semibold">Subir contrato</label>
          <div
            class="profile-photo-container"
            (dragover)="onDragOver($event)"
            (dragleave)="onDragLeave($event)"
            (drop)="onDrop($event)"
          >
            <div class="photo-preview" (click)="openFileUpload()">
              <div class="custom-file-display">
                <div class="custom-file-top">
                  <i
                    [ngClass]="
                      selectedFileName ? 'pi pi-file-pdf' : 'pi pi-plus-circle'
                    "
                  ></i>
                  <span>{{ selectedFileName || "Subir un archivo" }}</span>
                </div>

                <p-divider
                  *ngIf="!selectedFileName"
                  align="center"
                  type="solid"
                >
                  o
                </p-divider>

                <span *ngIf="!selectedFileName">Arrástralo aquí</span>
              </div>
            </div>
          </div>

          <div
            style="
              color: grey;
              font-size: 0.75rem;
              text-align: center;
              display: flex;
              flex-direction: column;
            "
          >
            <p style="padding: 0; margin: 0">Formatos admitidos: pdf.</p>
            <p style="padding: 0; margin: 0; line-height: 0.5">
              Tamaño máximo de archivo: 10 MB.
            </p>
          </div>

          <p-fileUpload
            #fileUploader
            [accept]="'application/pdf'"
            [maxFileSize]="10000000"
            (onSelect)="onChangeFile($event)"
            [style]="{ display: 'none' }"
          ></p-fileUpload>

          <div
            *ngIf="!currentFile && hasInteracted['currentFile']"
            style="color: red; font-size: 0.875rem; margin-top: 2px"
          >
            Este campo es obligatorio.
          </div>
        </div>
      </div>
    </div>

    <div style="display: flex; justify-content: center; margin-top: 1rem;">
      <!-- Botón Aceptar -->
      <button
        type="button"
        pButton
        label="Agregar contrato"
        (click)="onRegister()"
        [disabled]="isDisabled"
        class="btn"
      ></button>
    </div>
  </div>
</div>
