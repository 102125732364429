import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EmployeeRegisterComponent } from './components/employee-register/employee-register.component';
import { EmployeeContainerComponent } from './employee-container.component';
import { EmployeeComponent } from './components/employee/employee.component';
import { ContractComponent } from './components/contract/contract.component';
import { ContractRegisterComponent } from './components/contract-register/contract-register.component';
import { ContractDetailComponent } from './components/contract-detail/contract-detail.component';

const routes: Routes = [
  {
    path: '',
    component: EmployeeContainerComponent,
    children: [
      { path: '', redirectTo: 'list', pathMatch: 'full' }, // Redirige a 'list'
      { path: 'list', component: EmployeeComponent },
      { path: 'contract', component: ContractComponent },
      { path: 'details/:id', component: ContractDetailComponent },
      { path: 'register', component: EmployeeRegisterComponent },
      { path: 'register-contract', component: ContractRegisterComponent }, // Ruta que quieres usar
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EmployeeRoutingModule {}
