<p-dialog
  header="Validar Estado"
  [(visible)]="visible"
  [modal]="true"
  (onHide)="closeDialog()"
  [closable]="true"
  [style]="{ width: '30%', height: '90vh' }"
  
>
  <div class="loading-spinner" *ngIf="isLoading">
    <p-progressSpinner styleClass="custom-spinner"></p-progressSpinner>
  </div>
  <div class="content-container" *ngIf="!isLoading">
    <!-- Estado de la solicitud -->
    <div class="progress-bar">
      <div class="line-background"></div>
      <div
        class="line-active"
        [style.width.%]="
          status === 'aprobado' || status === 'rechazado'
            ? ((currentStep ) / (steps.length - 1)) * 99
            : (currentStep / (steps.length - 1)) * 99
        "
        [ngClass]="{
          'line-accepted': status === 'aprobado',
          'line-rejected': status === 'rechazado'
        }"
      ></div>

      <div *ngFor="let item of visibleSteps; let i = index" class="step">
        <div class="circle-container">
          <div
            class="circle"
            [ngClass]="{
              active:
                i < currentStep ||
                (status === 'rechazado' && i < currentStep - 1),
              accepted: status === 'aprobado',
              rejected: i === currentStep - 1 && status === 'rechazado'
            }"
          ></div>
        </div>

        <div class="label">
          {{
            status === "aprobado" && i === 3
              ? ""
              
              : i === 2 && status === "rechazado"
              ? "Rechazado"
              : item.label
          }}
        </div>
      </div>
    </div>

    <!-- Detalles de la solicitud -->
    <div class="p-field mt-4">
      <p class="text-orange-400">Nombre del colaborador</p>
      <p>
        {{ collaborator | capitalize }}
      </p>

      <div class="flex flex-column gap-5">
        <div>
          <p class="text-orange-400">Fecha de Inicio</p>
          <p>{{ dateStart }}</p>
        </div>

        <div>
          <p class="text-orange-400">Fecha de Fin</p>
          <p>{{ dateEnd }}</p>
        </div>
      </div>
    </div>

    <!-- Carga de PDF -->
    <div class="field">
      <label for="selectedFileName" class="font-semibold"
        >Subir o actualizar contrato</label
      >
      <div
        class="file-container"
        [ngClass]="{ 'disabled-upload': stateContract.stateContractId === 3 }"
        (dragover)="stateContract.stateContractId !== 3 && onDragOver($event)"
        (dragleave)="stateContract.stateContractId !== 3 && onDragLeave($event)"
        (drop)="stateContract.stateContractId !== 3 && onDrop($event)"
      >
        <div class="file-preview" (click)="stateContract.stateContractId !== 3 && openFileUpload('pdf')">
          <div class="custom-file-display">
            <div class="custom-file-top">
              <i
                [ngClass]="
                  selectedFileName ? 'pi pi-file-pdf' : 'pi pi-plus-circle'
                "
              ></i>
              <span>{{ selectedFileName || "Subir un archivo" }}</span>
            </div>

            <p-divider *ngIf="!selectedFileName" align="center" type="solid">
              o
            </p-divider>

            <span *ngIf="!selectedFileName">Arrástralo aquí</span>
          </div>
        </div>
      </div>

      <div
        style="
          color: grey;
          font-size: 0.75rem;
          text-align: center;
          display: flex;
          flex-direction: column;
        "
      >
        <p style="padding: 0; margin: 0">Formatos admitidos: pdf.</p>
        <p style="padding: 0; margin: 0; line-height: 0.5">
          Tamaño máximo de archivo: 10 MB.
        </p>
      </div>

      <div *ngIf="selectedFileName" class="download">
        <button
          pButton
          type="button"
          label="Ver documento"
          class="btn-download"
          [disabled]="(this.viewDocument || this.viewDocument != '') ? false : true"
          (click)="downloadFile()"
        ></button>
      </div>

      <p-fileUpload
        #pdfUploader
        [accept]="'application/pdf'"
        [maxFileSize]="10000000"
        (onSelect)="onChangeFile($event)"
        [style]="{ display: 'none' }"
        [disabled]="stateContract.stateContractId === 3"
      ></p-fileUpload>
    </div>

    <!-- Confirmación -->
    <div class="confirmation" *ngIf="showConfirmation">
      <p-checkbox
        [(ngModel)]="confirmationChecked"
        binary="true"
        inputId="confirmation"
      />
      <label for="confirmation">
        He revisado detalladamente la solicitud
      </label>
    </div>

    <!-- Botones de aprobación / rechazo -->
    <div class="submit-button" *ngIf="showButtons">
      <button
        pButton
        type="button"
        [label]="primaryButtonLabel"
        class="approve-button"
        (click)="onPrimaryButtonClick()"
        [disabled]="validateForm()"
      ></button>
      <button
        pButton
        type="button"
        label="RECHAZAR"
        class="reject-button"
        (click)="onRejectClick()"
        [disabled]="validateForm()"
      ></button>
    </div>
  </div>
</p-dialog>
