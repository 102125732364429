import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  ChangeDetectorRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { ModalSuccessComponent } from '../../../shared/modals/modal-success/modal-success.component';
import { ModalRejectComponent } from '../../../shared/modals/modal-reject/modal-reject.component';
import { ModalLoadingComponent } from '../../../shared/modals/modal-loading/modal-loading.component';
import { EmployeeLocalService } from '../../services/employee.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-contract-validate-modal',
  templateUrl: './contract-validate-modal.component.html',
  styleUrls: ['./contract-validate-modal.component.scss'],
  providers: [DialogService],
})
export class UpdateStateContractModalComponent implements OnInit, OnChanges {
  @Input() visible: boolean = false;
  @Input() dataToView: any | null = null;
  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() updateStateComplete = new EventEmitter<void>();

  showButtons: boolean = true;
  showConfirmation: boolean = true;
  showTextarea: boolean = true;
  primaryButtonLabel: string = 'PENDIENTE DE FIRMA';
  userData: any = null;

  currentFile: File | null = null;
  selectedFileName: string | null = null;
  @ViewChild('pdfUploader') pdfUploader: any;

  viewDocument: string = '';

  steps = [
    { label: 'Enviado', key: 1 },
    { label: 'Pendiente', key: 2 },
    { label: 'Aprobado', key: 3 },
    { label: '', key: 4 },
  ];

  get visibleSteps() {
    if (this.status === 'aprobado') {
      return this.steps.slice(0, 4); // Muestra solo los primeros 3 labels
    }
    if (this.status === 'rechazado') {
      return this.steps.slice(0, 4);
    }
    return this.steps;
  }

  currentStep = 1;
  status: 'pendiente' | 'aprobado' | 'no renovado' | 'rechazado' = 'pendiente';

  stateContract = {
    stateContractId: 1,
    stateContractName: 'En evaluación',
  };

  isLoading: boolean = false;
  dateStart: Date = new Date();
  dateEnd: Date = new Date();
  collaborator: string = '';
  confirmationChecked = false;

  hasInteracted: { [key: string]: boolean } = {
    currentFile: false,
    stateContract: false,
    selectedFileName: false,
  };
  constructor(
    private employeeLocalService: EmployeeLocalService,
    private sanitizer: DomSanitizer,
    private dialogService: DialogService,
    private cdr: ChangeDetectorRef
  ) {}

  closeDialog() {
    this.visible = false;
    this.visibleChange.emit(this.visible);
  }

  ngOnInit(): void {
    const userDataString = localStorage.getItem('access');
    if (userDataString) {
      this.userData = JSON.parse(userDataString);
    }
  }
  ngOnChanges(): void {
    if (this.visible && this.dataToView) {
      this.isLoading = true;
      const contractId = this.dataToView.contractId;

      this.employeeLocalService.getContractByContractId(contractId).subscribe({
        next: (contract) => {
          this.stateContract = {
            stateContractId: contract.stateContractId,
            stateContractName: contract.stateContractName,
          };
          this.updateState(contract.stateContractId);
          this.collaborator = this.dataToView?.employeeName;
          this.dateStart = contract.startDateContract;
          this.dateEnd = contract.endDate;

          this.selectedFileName = contract.fileContract
            ? this.extractFileName(contract.fileContract)
            : null;

          this.viewDocument = contract?.fileContract;

          this.isLoading = false;
          this.cdr.detectChanges();
        },
        error: (err) => {
          this.isLoading = false;
          console.error('Error al cargar los datos del empleado:', err);
        },
      });
    }
  }

  updateState(stateId: number): void {
    switch (stateId) {
      case 1: // No Renovado
        this.currentStep = 3;
        this.status = 'no renovado';
        this.showButtons = false;
        this.showConfirmation = false;
        this.showTextarea = false;
        break;
      case 2: // Enviado
        this.currentStep = 1;
        this.status = 'pendiente';
        this.primaryButtonLabel = 'PENDIENTE DE FIRMA';
        this.showButtons = true;
        this.showConfirmation = true;
        this.showTextarea = true;
        break;
      case 3: // Aprobado (Firmado)
        this.currentStep = 3;
        this.status = 'aprobado';
        this.showButtons = false;
        this.showConfirmation = false;
        this.showTextarea = false;
        break;
      case 4: // Pendiente de envío
        this.currentStep = 1;
        this.status = 'pendiente';
        this.primaryButtonLabel = 'PENDIENTE DE FIRMA';
        this.showButtons = true;
        this.showConfirmation = true;
        this.showTextarea = true;
        break;
      case 5: // Pendiente de firma
        this.currentStep = 2;
        this.status = 'pendiente';
        this.primaryButtonLabel = 'PENDIENTE DE VALIDACIÓN';
        this.showButtons = true;
        this.showConfirmation = true;
        this.showTextarea = true;
        break;
      case 6: // Pendiente de validación
        this.currentStep = 2;
        this.status = 'pendiente';
        this.primaryButtonLabel = 'APROBAR';
        this.showButtons = true;
        this.showConfirmation = true;
        this.showTextarea = true;
        break;
      case 7: // No Aprobado
        this.currentStep = 2;
        this.status = 'pendiente';
        this.primaryButtonLabel = 'PENDIENTE DE FIRMA';
        this.showButtons = true;
        this.showConfirmation = true;
        this.showTextarea = true;
        break;
      default:
        console.warn('Estado no manejado:', stateId);
    }
  }

  parseCustomDate(dateString: string): Date {
    const [day, month, year] = dateString.split('/').map(Number);
    const fullYear = year < 100 ? 2000 + year : year;
    return new Date(fullYear, month - 1, day);
  }

  validateForm(): boolean {
    return !(this.selectedFileName && this.confirmationChecked);
  }

  onButtonClick() {
    this.closeDialog();
  }

  resetForm() {
    this.confirmationChecked = false;
  }

  onPrimaryButtonClick(): void {
    const loadingRef = this.dialogService.open(ModalLoadingComponent, {
      closable: false,
    });

    let stateId: number;
    let title: string;

    switch (this.stateContract.stateContractId) {
      case 2: // Enviado -> Pendiente de firma
        stateId = 5;
        title = '¡Enviado!';
        break;
      case 4:
        stateId = 5;
        title = '¡Enviado!';
        break;
      case 5: // Pendiente de firma -> Pendiente de validación
        stateId = 6;
        title = '¡Firmado!';
        break;
      case 6: // Pendiente de validación -> Firmado (Aprobado)
        stateId = 3;
        title = '¡Aprobado!';
        break;
      default:
        console.warn('Estado no manejado:', this.stateContract.stateContractId);
        return;
    }

    const request = {
      stateContractId: stateId,
      userAdminId: this.userData.employeeId,
    };

    const formData = new FormData();

    const appendIfDefined = (key: string, value: any) => {
      if (value !== undefined && value !== null) {
        formData.append(key, value);
      }
    };

    appendIfDefined('stateContractId', request.stateContractId);
    appendIfDefined('userId', request.userAdminId)

    // console.log('currentFile:', {
    //   name: this.currentFile?.name,
    //   size: this.currentFile?.size,
    //   type: this.currentFile?.type,
    //   lastModified: this.currentFile?.lastModified,
    // });

    if (this.currentFile) {
      appendIfDefined('file', this.currentFile);
    }

    this.employeeLocalService
      .updateChangeStateContract(this.dataToView.contractId, formData)
      .subscribe({
        next: (response) => {
          loadingRef.close();
          this.closeDialog();
          const successMessage = 'Enviado exitosamente';
          const ref = this.dialogService.open(ModalSuccessComponent, {
            header: '',
            data: {
              text: successMessage,
              title: title,
              icon: 'pi pi-check-circle',
              showButton: true,
              buttonText: 'Entendido',
            },
          });
          this.updateStateComplete.emit();
        },
        error: (err) => {
          loadingRef.close();
          const ref = this.dialogService.open(ModalRejectComponent, {
            header: '',
            data: {
              text:
                err.error?.error ||
                'Lo sentimos, ocurrió un error. Por favor, inténtelo nuevamente.',
              title: '¡Lo sentimos!',
              showButton: true,
              buttonText: 'Entendido',
            },
          });
          console.error('Error al actualizar el estado:', err);
        },
      });
    this.closeDialog();
  }

  onRejectClick(): void {
    const loadingRef = this.dialogService.open(ModalLoadingComponent, {
      closable: false,
    });

    const stateId = 7; // No aprobado
    const request = {
      stateContractId: stateId,
    };

    const formData = new FormData();
    formData.append('stateContractId', request.stateContractId.toString());

    if (this.currentFile) {
      formData.append('file', this.currentFile);
    }

    this.employeeLocalService
      .updateChangeStateContract(this.dataToView.contractId, formData)
      .subscribe({
        next: (response) => {
          loadingRef.close();
          const successMessage = response.message;
          const ref = this.dialogService.open(ModalSuccessComponent, {
            closable: false,
            header: '',
            data: {
              text: successMessage,
              title: '¡Rechazado!',
              icon: 'pi pi-check-circle',
              showButton: true,
              buttonText: 'Entendido',
            },
          });
          this.resetForm();
          this.updateStateComplete.emit();
          this.closeDialog();
        },
        error: (err) => {
          loadingRef.close();
          const ref = this.dialogService.open(ModalRejectComponent, {
            header: '',
            data: {
              text:
                err.error?.error ||
                'Lo sentimos, ocurrió un error. Por favor, inténtelo nuevamente.',
              title: '¡Lo sentimos!',
              showButton: true,
              buttonText: 'Entendido',
            },
          });
          this.resetForm();
          console.error('Error al rechazar la solicitud:', err);
        },
      });
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    event.dataTransfer!.dropEffect = 'copy';
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();

    if (event.dataTransfer && event.dataTransfer.files.length > 0) {
      const droppedFile = event.dataTransfer.files[0];

      if (this.isValidFile(droppedFile)) {
        this.currentFile = droppedFile;
        this.selectedFileName = droppedFile.name;
      } else {
        this.currentFile = null;
        this.selectedFileName = null;

        const ref = this.dialogService.open(ModalRejectComponent, {
          header: '',
          data: {
            text: 'El archivo no es válido. Solo se permiten archivos PDF, DOC o DOCX de hasta 10 MB.',
            title: '¡Upss!',
            showButton: true,
            buttonText: 'Entendido',
          },
        });
      }
    }
  }

  isValidFile(file: File): boolean {
    const allowedExtensions = [
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ];
    const maxSize = 10 * 1024 * 1024;

    if (!allowedExtensions.includes(file.type)) {
      console.log('Tipo de archivo no permitido:', file.type);
      return false;
    }

    if (file.size > maxSize) {
      console.log('Archivo demasiado grande:', file.size);
      return false;
    }

    return true;
  }

  openFileUpload(type: string) {
    if (type === 'pdf' && this.pdfUploader) {
      this.pdfUploader.choose();
    } else {
      console.error('El fileUploader no está disponible.');
    }
  }

  onChangeFile(event: any) {
    const selectedFile = event.files[0];
    if (selectedFile) {
      const maxSize = 10 * 1024 * 1024;
      const allowedTypes = ['application/pdf'];

      if (!allowedTypes.includes(selectedFile.type)) {
        const ref = this.dialogService.open(ModalRejectComponent, {
          header: '',
          data: {
            text: 'El archivo no es válido. Solo se permiten archivos PDF.',
            title: '¡Upss!',
            showButton: true,
            buttonText: 'Entendido',
          },
        });
        this.currentFile = null;
        this.selectedFileName = null;
        this.hasInteracted['currentFile'] = false;
        this.validateForm();
        return;
      }

      if (selectedFile.size > maxSize) {
        const ref = this.dialogService.open(ModalRejectComponent, {
          header: '',
          data: {
            text: 'El archivo es demasiado grande. El tamaño máximo permitido es de 10 MB.',
            title: '¡Upss!',
            showButton: true,
            buttonText: 'Entendido',
          },
        });
        this.currentFile = null;
        this.selectedFileName = null;
        this.hasInteracted['currentFile'] = false;
        this.validateForm();
        return;
      }

      this.currentFile = selectedFile;
      this.selectedFileName = selectedFile.name;
      this.hasInteracted['currentFile'] = false;
      this.validateForm();
    }
    this.validateForm();
  }

  downloadFile() {
    if (this.viewDocument) {
      const link = document.createElement('a');
      link.href = this.viewDocument;
      window.open(link.href, '_blank');
      link.download = this.selectedFileName || 'documento.pdf';
    }
  }

  extractFileName(fileUrl: string): string {
    const lastDashIndex = fileUrl.lastIndexOf('-', fileUrl.lastIndexOf('.'));
    const fileName = fileUrl.substring(lastDashIndex + 1);
    return fileName;
  }
}
