import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-employee-container',
  templateUrl: './employee-container.component.html',
  styleUrls: ['./employee-container.component.scss'],
})
export class EmployeeContainerComponent implements OnInit {
  tabs = [
    { key: '1', text: 'Empleados', route: 'list' },
    { key: '2', text: 'Contratos y renovaciones', route: 'contract' },
  ];
  selectedTabKey: number = 1;

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.updateSelectedTabKey(this.router.url);

    this.router.events
      .pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd
        )
      )
      .subscribe((event: NavigationEnd) => {
        this.updateSelectedTabKey(event.url);
      });
  }

  updateSelectedTabKey(url: string): void {
    const urlSegments = url.split('/');
    const currentRoute = urlSegments[urlSegments.length - 1];
    const previousRoute = urlSegments[urlSegments.length - 2];

    if (currentRoute === 'contract' || previousRoute === 'details' || currentRoute === 'register-contract') {
      this.selectedTabKey = 2;
    } else if (currentRoute === 'list') {
      this.selectedTabKey = 1;
    } else {
      this.selectedTabKey = 1;
    }
  }

  onTabSelected(index: number): void {
    const selectedTab = this.tabs[index];
    if (selectedTab) {
      this.selectedTabKey = Number(selectedTab.key);
      this.router.navigate([`/dashboard/employee/${selectedTab.route}`]);
    }
  }
}
