import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  OnChanges,
  ViewChild,
} from '@angular/core';
import { IComboBase } from '../../../interfaces/base/base.interface';
import { DialogService } from 'primeng/dynamicdialog';
import { forkJoin } from 'rxjs';
import { CurrencyService } from '../../../services/currency.service';
import { StateContractService } from '../../../services/state-contracts.service';
import { ContinuityContractsService } from '../../../services/continuity-contracts.service';
import { AmountRenewalContractsService } from '../../../services/amount-renewal-contracts.service';
import { ModalLoadingComponent } from '../../../shared/modals/modal-loading/modal-loading.component';
import { EmployeeLocalService } from '../../services/employee.service';
import { ModalSuccessComponent } from '../../../shared/modals/modal-success/modal-success.component';
import { ModalRejectComponent } from '../../../shared/modals/modal-reject/modal-reject.component';
import { TypeContractService } from '../../../services/type-contracts.service';
import { WorkingModeService } from '../../../services/working-modes.service';

interface IAllowances {
  computerAllowance?: number | null;
  internetAllowance?: number | null;
  electricityAllowance?: number | null;
  transportationAllowance?: number | null;
}

@Component({
  selector: 'app-contract-edit-modal',
  templateUrl: './contract-edit-modal.component.html',
  styleUrls: ['./contract-edit-modal.component.scss'],
  providers: [DialogService],
})
export class ContractEditModalComponent implements OnChanges {
  @Input() visible: boolean = false;
  @Input() dataToEdit: any | null = null;
  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() editComplete = new EventEmitter<void>();
  userData: any = null;

  constructor(
    private employeeLocalService: EmployeeLocalService,
    private currencyService: CurrencyService,
    private stateContractService: StateContractService,
    private typeContractService: TypeContractService,
    private continuityContractsService: ContinuityContractsService,
    private workingModeService: WorkingModeService,
    private amountRenewalContractsService: AmountRenewalContractsService,
    private dialogService: DialogService,
    private cdr: ChangeDetectorRef
  ) {}

  isLoading: boolean = false;
  isDisabledContract: boolean = true;
  filteredOptions: IComboBase[] = [];

  // Listas de opciones
  currencies: IComboBase[] = [];
  typeContracts: IComboBase[] = [];
  statusContracts: IComboBase[] = [];
  continues: IComboBase[] = [];
  renewContracts: IComboBase[] = [];
  workingModes: IComboBase[] = [];

  // Valores seleccionados
  selectedCurrency: IComboBase | null = null;
  selectedTypeContract: IComboBase | null = null;
  selectedRenewContract: IComboBase | null = null;
  selectedContinuity: IComboBase | null = null;
  selectedStatusContract: IComboBase | null = null;
  selectedCurrencyCode: string | null = null;
  selectedWorkingMode: IComboBase | null = null;

  // Datos del contrato
  salary: number | null = null;
  basicSalary: number | null = null;
  performanceBonus: number | null = null;
  computerAllowance: number | null = null;
  internetAllowance: number | null = null;
  electricityAllowance: number | null = null;
  transportationAllowance: number | null = null;

  currentFile: File | null = null;
  stateContractId: number | null = null;
  employeeId: number | null = null;

  startDate: Date | null = null;
  endDate: Date | null = null;
  minDate: Date | null = null;
  maxStartDate: Date | null = null;
  maxEndDate: Date | null = null;

  @ViewChild('pdfUploader') pdfUploader: any;

  selectedFileName: string | null = null;

  hasInteracted: { [key: string]: boolean } = {
    typeContract: false,
    startDate: false,
    endDate: false,
    currentFile: false,
    continue: false,
    renewContract: false,
    currency: false,
    stateContract: false,
    selectedFileName: false,
    salary: false,
    basicSalary: false,
    performanceBonus: false,
    computerAllowance: false,
    internetAllowance: false,
    electricityAllowance: false,
    transportationAllowance: false,
  };

  ngOnInit(): void {
    const userDataString = localStorage.getItem('access');
    if (userDataString) {
      this.userData = JSON.parse(userDataString);
    }

    this.calculateSalary();
  }

  ngOnChanges(): void {
    if (this.visible && this.dataToEdit) {
      this.isLoading = true;
      const contractId = this.dataToEdit.contractId;
      const employeeId = this.dataToEdit.employeeId;

      this.employeeLocalService.getContractByContractId(contractId).subscribe({
        next: (contract) => {
          const observables = {
            currencies: this.currencyService.comboCurrency(),
            typeContracts: this.typeContractService.comboTypeContract(),
            statusContracts: this.stateContractService.comboStateContract(),
            continues:
              this.continuityContractsService.comboContinuityContracts(),
            workingModes: this.workingModeService.comboWorkingMode(),
            // renewContracts:
            //   this.amountRenewalContractsService.comboAmountRenewalContracts(),
          };

          forkJoin(observables).subscribe({
            next: async (data) => {
              this.employeeId = employeeId;
              this.currencies = data.currencies;
              this.typeContracts = data.typeContracts;
              this.statusContracts = data.statusContracts;
              this.continues = data.continues;
              this.workingModes = data.workingModes;
              // this.renewContracts = data.renewContracts;

              this.selectedTypeContract =
                this.typeContracts.find(
                  (tc) =>
                    tc.key.toString() === contract.typeContractId.toString()
                ) || null;

              this.selectedCurrency =
                this.currencies.find(
                  (c) => c.key.toString() === contract.currencyId.toString()
                ) || null;

              this.selectedCurrencyCode =
                this.selectedCurrency?.key == '1' ? 'S/.' : '$';

              // this.selectedContinuity =
              //   this.continues.find(
              //     (c) =>
              //       c.key.toString() ===
              //       contract.continuityContractId.toString()
              //   ) || null;

              this.selectedStatusContract =
                this.statusContracts.find(
                  (c) =>
                    c.key.toString() === contract.stateContractId.toString()
                ) || null;

              this.selectedWorkingMode =
                this.workingModes.find(
                  (c) => c.key.toString() === contract.workingModeId.toString()
                ) || null;

              // this.selectedRenewContract =
              //   this.renewContracts.find(
              //     (r) =>
              //       r.key.toString() === contract.amountrenewalid.toString()
              //   ) || null;

              this.salary = contract.salary;
              this.basicSalary = contract.basicSalary;
              this.performanceBonus = contract.performanceBonus;
              this.computerAllowance = contract.computerAllowance
                ? contract.computerAllowance
                : 0;
              this.internetAllowance = contract.internetAllowance
                ? contract.internetAllowance
                : 0;
              this.electricityAllowance = contract.electricityAllowance
                ? contract.electricityAllowance
                : 0;
              this.transportationAllowance = contract.transportationAllowance
                ? contract.transportationAllowance
                : 0;
              this.startDate = this.convertToDate(contract.startDateContract);
              this.endDate = this.convertToDate(contract.endDate);

              this.selectedFileName = contract.fileContract
                ? this.extractFileName(contract.fileContract)
                : null;

              console.log('this.selectedFileName = ' + this.selectedFileName);

              this.isLoading = false;
              this.validateFormContract();
              this.cdr.detectChanges();
            },
            error: (err) => {
              this.isLoading = false;
              console.error('Error al cargar las listas de opciones:', err);
            },
          });
        },
        error: (err) => {
          this.isLoading = false;
          console.error('Error al cargar los datos del empleado:', err);
        },
      });
    }
  }

  convertToDate(dateString: string): Date | null {
    if (!dateString) return null;
    const [day, month, year] = dateString.split('/').map(Number);
    return new Date(year, month - 1, day);
  }

  // Método para filtrar opciones
  filterOptions(event: any, type: string) {
    const query = event.query.toLowerCase();
    let options: IComboBase[] = [];
    switch (type) {
      // case 'renewContract':
      //   options = this.renewContracts;
      //   break;
      case 'statusContract':
        options = this.statusContracts;
        break;
      case 'typeContract':
        options = this.typeContracts;
        break;
      case 'currency':
        options = this.currencies;
        break;
      case 'workingMode':
        options = this.workingModes;
        break;
      default:
        console.error('Tipo no reconocido:', type);
    }
    this.filteredOptions = options.filter((option) =>
      option.text.toLowerCase().includes(query)
    );
  }

  onChangeInput(event: any, type: string) {
    this.hasInteracted[type] = true;
    switch (type) {
      case 'typeContract':
        this.selectedTypeContract = null;
        break;
      case 'renewContract':
        this.selectedRenewContract = null;
        break;
      case 'currency':
        this.selectedCurrency = null;
        break;
      case 'workingMode':
        this.selectedWorkingMode = null;
        this.calculateSalary();
        break;
      case 'statusContract':
        this.selectedStatusContract = null;
        break;
      case 'basicSalary':
        this.basicSalary = event;
        this.calculateSalary();
        break;
      case 'performanceBonus':
        this.performanceBonus = event;
        this.calculateSalary();
        break;
      case 'computerAllowance':
        this.computerAllowance = event;
        this.calculateSalary();
        break;
      case 'internetAllowance':
        this.internetAllowance = event;
        this.calculateSalary();
        break;
      case 'electricityAllowance':
        this.electricityAllowance = event;
        this.calculateSalary();
        break;
      case 'transportationAllowance':
        this.transportationAllowance = event;
        this.calculateSalary();
        break;
      case 'salary':
        this.salary = event;
        break;
      default:
        console.error('Tipo no reconocido:', type);
    }
    this.validateFormContract();
  }

  onSelect(event: any, type: string) {
    this.hasInteracted[type] = true;

    switch (type) {
      case 'renewContract':
        this.selectedRenewContract = event.value ?? null;
        break;
      case 'currency':
        this.selectedCurrency = event.value ?? null;
        this.selectedCurrencyCode =
          this.selectedCurrency?.key == '1' ? 'S/.' : '$';
        break;
      case 'typeContract':
        this.selectedTypeContract = event.value ?? null;
        break;
      case 'statusContract':
        this.selectedStatusContract = event.value ?? null;
        break;
      case 'workingMode':
        this.selectedWorkingMode = event.value ?? null;
        this.calculateSalary();
        break;
      default:
        console.error('Tipo no reconocido:', type);
    }

    this.validateFormContract();
  }

  validateField(field: string) {
    this.hasInteracted[field] = true;
  }

  onChangeFile(event: any) {
    const selectedFile = event.files[0];
    if (selectedFile) {
      const maxSize = 10 * 1024 * 1024;
      const allowedTypes = ['application/pdf'];

      if (!allowedTypes.includes(selectedFile.type)) {
        const ref = this.dialogService.open(ModalRejectComponent, {
          header: '',
          data: {
            text: 'El archivo no es válido. Solo se permiten archivos PDF.',
            title: '¡Upss!',
            showButton: true,
            buttonText: 'Entendido',
          },
        });
        this.currentFile = null;
        this.selectedFileName = null;
        this.stateContractId = 4;
        this.hasInteracted['currentFile'] = false;
        this.validateFormContract();
        return;
      }

      if (selectedFile.size > maxSize) {
        const ref = this.dialogService.open(ModalRejectComponent, {
          header: '',
          data: {
            text: 'El archivo es demasiado grande. El tamaño máximo permitido es de 10 MB.',
            title: '¡Upss!',
            showButton: true,
            buttonText: 'Entendido',
          },
        });
        this.currentFile = null;
        this.selectedFileName = null;
        this.stateContractId = 4;
        this.hasInteracted['currentFile'] = false;
        this.validateFormContract();
        return;
      }

      this.currentFile = selectedFile;
      this.selectedFileName = selectedFile.name;
      this.stateContractId = 2;
      this.validateFormContract();
      this.hasInteracted['currentFile'] = true;
    }
    this.validateFormContract();
  }

  validateFormContract() {
    const basicFieldsValid = Boolean(
      this.selectedTypeContract &&
        this.selectedStatusContract &&
        this.selectedWorkingMode &&
        this.startDate &&
        this.endDate &&
        this.selectedContinuity &&
        this.selectedCurrency &&
        this.salary !== null &&
        this.basicSalary !== null &&
        this.performanceBonus !== null
    );

    if (!basicFieldsValid) {
      this.isDisabledContract = true;
      return;
    }
    const fileRequired =
      this.selectedStatusContract?.key?.toString() !== '1' &&
      this.selectedStatusContract?.key?.toString() !== '4' &&
      this.selectedStatusContract?.key?.toString() !== '7';

    const fileValid = fileRequired
      ? this.currentFile !== null || this.selectedFileName !== null
      : true;

    let workingModeValid = true;

    if (this.selectedWorkingMode) {
      console.log(
        `Validating working mode ${
          this.selectedWorkingMode.key
        } bonuses y su tipo ${typeof this.selectedWorkingMode.key}`
      );

      switch (String(this.selectedWorkingMode?.key)) {
        case '1': // Remoto
          workingModeValid =
            this.computerAllowance != null &&
            this.internetAllowance != null &&
            this.electricityAllowance != null;
          break;

        case '2': // Presencial
          workingModeValid = this.transportationAllowance != null;
          break;

        case '3': // Híbrido
          workingModeValid =
            this.computerAllowance != null &&
            this.internetAllowance != null &&
            this.electricityAllowance != null &&
            this.transportationAllowance != null;
          break;

        default:
          workingModeValid = false;
      }
    }

    let salaryValid = false;
    if (
      this.salary !== null &&
      this.basicSalary !== null &&
      this.performanceBonus !== null
    ) {
      salaryValid =
        this.salary > 0 &&
        this.salary >= this.basicSalary + this.performanceBonus;
    }

    console.log(
      'this.isDisabledContract:',
      basicFieldsValid,
      workingModeValid,
      salaryValid,
      fileValid
    );

    this.isDisabledContract = !(
      basicFieldsValid &&
      workingModeValid &&
      salaryValid &&
      fileValid
    );
  }

  openFileUpload(type: string) {
    if (type === 'pdf' && this.pdfUploader) {
      this.pdfUploader.choose();
    } else {
      console.error('El fileUploader no está disponible.');
    }
  }

  onStartDateChange(selectedStartDate: Date): void {
    if (selectedStartDate) {
      this.startDate = selectedStartDate;

      this.maxEndDate = null;
      this.endDate = null;
    } else {
      this.startDate = null;
      this.maxEndDate = null;
    }
  }

  onEndDateChange(selectedEndDate: Date): void {
    if (selectedEndDate && this.startDate && selectedEndDate < this.startDate) {
      this.endDate = null;
    } else {
      this.endDate = selectedEndDate;
    }
  }

  onCheckboxChange(value: IComboBase) {
    this.selectedContinuity = value;
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    event.dataTransfer!.dropEffect = 'copy';
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();

    if (event.dataTransfer && event.dataTransfer.files.length > 0) {
      const droppedFile = event.dataTransfer.files[0];

      if (this.isValidFile(droppedFile)) {
        this.currentFile = droppedFile;
        this.selectedFileName = droppedFile.name;
        this.stateContractId = 2;
        this.validateFormContract();
      } else {
        this.currentFile = null;
        this.selectedFileName = null;
        this.stateContractId = 4;
        this.validateFormContract();

        const ref = this.dialogService.open(ModalRejectComponent, {
          header: '',
          data: {
            text: 'El archivo no es válido. Solo se permiten archivos PDF, DOC o DOCX de hasta 10 MB.',
            title: '¡Upss!',
            showButton: true,
            buttonText: 'Entendido',
          },
        });
      }
    }
  }

  isValidFile(file: File): boolean {
    const allowedExtensions = [
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ];
    const maxSize = 10 * 1024 * 1024;

    if (!allowedExtensions.includes(file.type)) {
      console.log('Tipo de archivo no permitido:', file.type);
      return false;
    }

    if (file.size > maxSize) {
      console.log('Archivo demasiado grande:', file.size);
      return false;
    }

    return true;
  }

  extractFileName(fileUrl: string): string {
    const lastDashIndex = fileUrl.lastIndexOf('-', fileUrl.lastIndexOf('.'));
    const fileName = fileUrl.substring(lastDashIndex + 1);
    return fileName;
  }

  calculateSalary() {
    let total = 0;

    if (this.basicSalary) total += this.basicSalary;
    if (this.performanceBonus) total += this.performanceBonus;

    // Sumar bonos según workingMode
    if (this.selectedWorkingMode) {
      if (
        this.selectedWorkingMode.key == '1' ||
        this.selectedWorkingMode.key == '3'
      ) {
        if (this.computerAllowance) total += this.computerAllowance;
        if (this.internetAllowance) total += this.internetAllowance;
        if (this.electricityAllowance) total += this.electricityAllowance;
      }

      if (
        this.selectedWorkingMode.key == '2' ||
        this.selectedWorkingMode.key == '3'
      ) {
        if (this.transportationAllowance) total += this.transportationAllowance;
      }
    }

    this.salary = total;
    this.validateFormContract();
  }

  getRelevantAllowances(): string[] {
    if (!this.selectedWorkingMode) return [];

    const workingModeKey = String(this.selectedWorkingMode.key);

    const allowances: string[] = [];

    if (workingModeKey == '1' || workingModeKey == '3') {
      allowances.push(
        'computerAllowance',
        'internetAllowance',
        'electricityAllowance'
      );
    }

    if (workingModeKey == '2' || workingModeKey == '3') {
      allowances.push('transportationAllowance');
    }

    return allowances;
  }

  onSaveChanges() {
    const loadingRef = this.dialogService.open(ModalLoadingComponent, {
      closable: false,
    });

    const relevantAllowances = this.getRelevantAllowances();

    const request: any = {
      userId: this.userData.employeeId,
      typeContractId: this.selectedTypeContract?.key,
      currencyId: this.selectedCurrency?.key,
      continuityContractId: this.selectedContinuity?.key,
      amountrenewalid: this.selectedRenewContract?.key,
      workingModeId: this.selectedWorkingMode?.key,
      startDate: this.startDate?.toISOString().replace('Z', ''),
      endDate: this.endDate?.toISOString().replace('Z', ''),
      stateContractId: this.selectedStatusContract?.key,
      basicSalary: this.basicSalary,
      performanceBonus: this.performanceBonus,
      salary: this.salary,
    };

    relevantAllowances.forEach((allowance) => {
      request[allowance] = (this as IAllowances)[
        allowance as keyof IAllowances
      ];
    });

    const formData = new FormData();

    const appendIfDefined = (key: string, value: any) => {
      if (value !== undefined && value !== null) {
        formData.append(key, value);
      }
    };

    Object.entries(request).forEach(([key, value]) =>
      appendIfDefined(key, value)
    );
    console.log('----this.selectedFileName ' + this.selectedFileName);
    console.log('this.currentFile ' + this.currentFile);

    if (this.currentFile) {
      formData.append('file', this.currentFile);
    }

    // Llamar al servicio para actualizar el contrato
    this.employeeLocalService
      .updateContract(this.dataToEdit.contractId, formData)
      .subscribe({
        next: (response) => {
          loadingRef.close();
          this.closeDialog();
          const ref = this.dialogService.open(ModalSuccessComponent, {
            header: '',
            data: {
              text: 'El contrato se ha actualizado con éxito.',
              title: '¡Éxito!',
              icon: 'pi pi-check-circle',
              showButton: true,
              buttonText: 'Entendido',
            },
          });
          this.editComplete.emit();
        },
        error: (err) => {
          loadingRef.close();
          let errorMessage =
            'Lo sentimos, ocurrió un error. Por favor, inténtelo nuevamente.';
          if (err.error) {
            if (typeof err.error === 'string') {
              errorMessage = err.error;
            } else if (typeof err.error === 'object') {
              if (err.error.error && typeof err.error.error === 'object') {
                errorMessage = Object.values(err.error.error).join(' ');
              } else {
                errorMessage = Object.values(err.error).join(' ');
              }
            }
          }
          const ref = this.dialogService.open(ModalRejectComponent, {
            header: '',
            data: {
              text: errorMessage,
              title: '¡Lo sentimos!',
              showButton: true,
              buttonText: 'Entendido',
            },
          });
          console.error('Error al actualizar el contrato:', err);
        },
      });
    this.closeDialog();
  }

  closeDialog(): void {
    this.visible = false;
    this.visibleChange.emit(this.visible);
  }
}
