<p-dialog
  header="Editar Contrato"
  [(visible)]="visible"
  [modal]="true"
  (onHide)="closeDialog()"
  [closable]="true"
  [style]="{ width: '40%', maxHeight: '90%', height: '100%' }"
  styleClass="contract-edit-modal" 
>
  <div class="loading-spinner" *ngIf="isLoading">
    <p-progressSpinner styleClass="custom-spinner"></p-progressSpinner>
  </div>

  <div *ngIf="!isLoading" class="row">
    <div class="column">
      <div class="field-container">
        <!-- Tipo de Contrato -->
        <div class="field">
          <label for="typeContract" class="font-semibold"
            >Tipo de Contrato</label
          >
          <p-autoComplete
            id="typeContract"
            [(ngModel)]="selectedTypeContract"
            [suggestions]="filteredOptions"
            (completeMethod)="filterOptions($event, 'typeContract')"
            (input)="onChangeInput($event, 'typeContract')"
            [dropdown]="true"
            [forceSelection]="true"
            [placeholder]="'Selecciona un tipo'"
            (onSelect)="onSelect($event, 'typeContract')"
            [style]="{ width: '100%' }"
            [field]="'text'"
          ></p-autoComplete>
        </div>

        <!-- Estado de Contrato -->
        <div class="field">
          <label for="statusContract" class="font-semibold"
            >Estado del Contrato</label
          >
          <p-autoComplete
            id="statusContract"
            [(ngModel)]="selectedStatusContract"
            [suggestions]="filteredOptions"
            (completeMethod)="filterOptions($event, 'statusContract')"
            (input)="onChangeInput($event, 'statusContract')"
            [dropdown]="true"
            [forceSelection]="true"
            [placeholder]="'Selecciona un tipo'"
            (onSelect)="onSelect($event, 'statusContract')"
            [style]="{ width: '100%' }"
            [field]="'text'"
          ></p-autoComplete>
        </div>
      </div>

      <!-- Selección de Fechas -->
      <div class="calendar-row">
        <div class="calendar-item">
          <label for="startDate">Fecha de Inicio</label>
          <p-calendar
            id="startDate"
            [(ngModel)]="startDate"
            [icon]="'pi pi-pencil'"
            [showIcon]="true"
            (ngModelChange)="onStartDateChange($event)"
            [maxDate]="maxStartDate"
            dateFormat="dd/mm/yy"
            placeholder="DD/MM/YYYY"
            (onBlur)="validateField('startDate')"
          ></p-calendar>
        </div>
        <div class="calendar-item">
          <label for="endDate">Fecha de Finalización</label>
          <p-calendar
            id="endDate"
            [(ngModel)]="endDate"
            [icon]="'pi pi-pencil'"
            [showIcon]="true"
            (ngModelChange)="onEndDateChange($event)"
            [minDate]="startDate"
            [maxDate]="maxEndDate"
            dateFormat="dd/mm/yy"
            placeholder="DD/MM/YYYY"
            (onBlur)="validateField('endDate')"
          ></p-calendar>
        </div>
      </div>

      <!-- <div class="field">
        <label for="renewContract" class="font-semibold"
          >¿Cuántas veces se renovó el contrato?</label
        >
        <p-autoComplete
          id="renewContract"
          [(ngModel)]="selectedRenewContract"
          [suggestions]="filteredOptions"
          (completeMethod)="filterOptions($event, 'renewContract')"
          (input)="onChangeInput($event, 'renewContract')"
          [dropdown]="true"
          [forceSelection]="true"
          [placeholder]="'Selecciona una opción'"
          (onSelect)="onSelect($event, 'renewContract')"
          [style]="{ width: '100%' }"
          [field]="'text'"
        ></p-autoComplete>
      </div> -->

      <div class="field-container">
        <!-- Modalidad -->
        <div class="field">
          <label for="workingMode" class="font-semibold">Modalidad</label>
          <p-autoComplete
            id="mode"
            [(ngModel)]="selectedWorkingMode"
            [suggestions]="filteredOptions"
            (completeMethod)="filterOptions($event, 'workingMode')"
            (input)="onChangeInput($event, 'workingMode')"
            [dropdown]="true"
            [forceSelection]="true"
            [placeholder]="'Selecciona un tipo'"
            (onSelect)="onSelect($event, 'workingMode')"
            [style]="{ width: '100%' }"
            [field]="'text'"
          ></p-autoComplete>
          <div
            *ngIf="!selectedWorkingMode && hasInteracted['workingMode']"
            class="error-message"
            style="color: red; font-size: 0.875rem; margin-top: 2px"
          >
            Este campo es obligatorio.
          </div>
        </div>

        <!-- Moneda -->
        <div class="field">
          <label for="currency" class="font-semibold">Moneda</label>
          <p-autoComplete
            id="currency"
            [(ngModel)]="selectedCurrency"
            [suggestions]="filteredOptions"
            (completeMethod)="filterOptions($event, 'currency')"
            (input)="onChangeInput($event, 'currency')"
            [dropdown]="true"
            [forceSelection]="true"
            [placeholder]="'Selecciona una moneda'"
            (onSelect)="onSelect($event, 'currency')"
            [style]="{ width: '100%' }"
            [field]="'text'"
          ></p-autoComplete>
        </div>
      </div>

      <div class="field-container">
        <!-- Sueldo básico -->
        <div class="field">
          <label for="basicSalary" class="font-semibold">Sueldo Básico</label>
          <p-inputGroup>
            <p-inputGroupAddon>
              <span *ngIf="selectedCurrencyCode" class="phone-prefix">{{
                selectedCurrencyCode
              }}</span>
            </p-inputGroupAddon>
            <p-inputNumber
              id="basicSalary"
              mode="decimal"
              [minFractionDigits]="2"
              [maxFractionDigits]="2"
              inputId="withoutgrouping"
              [useGrouping]="false"
              [(ngModel)]="basicSalary"
              (ngModelChange)="onChangeInput($event, 'basicSalary')"
              [placeholder]="'Ingresa sueldo básico'"
            ></p-inputNumber>
          </p-inputGroup>
          <div
            *ngIf="!basicSalary && hasInteracted['basicSalary']"
            style="color: red; font-size: 0.875rem; margin-top: 2px"
          >
            Este campo es obligatorio.
          </div>
        </div>

        <!-- Bono de cumplimiento -->
        <div class="field">
          <label for="performanceBonus" class="font-semibold"
            >Bono Cumplimiento</label
          >
          <p-inputGroup>
            <p-inputGroupAddon>
              <span *ngIf="selectedCurrencyCode" class="phone-prefix">{{
                selectedCurrencyCode
              }}</span>
            </p-inputGroupAddon>
            <p-inputNumber
              id="performanceBonus"
              mode="decimal"
              [minFractionDigits]="2"
              [maxFractionDigits]="2"
              inputId="withoutgrouping"
              [useGrouping]="false"
              [(ngModel)]="performanceBonus"
              (ngModelChange)="onChangeInput($event, 'performanceBonus')"
              [placeholder]="'Ingresa bono productividad'"
            ></p-inputNumber>
          </p-inputGroup>
          <div
            *ngIf="!performanceBonus && hasInteracted['performanceBonus']"
            style="color: red; font-size: 0.875rem; margin-top: 2px"
          >
            Este campo es obligatorio.
          </div>
        </div>
      </div>

      <div
        class="field-container"
        *ngIf="
          selectedWorkingMode?.key == '1' || selectedWorkingMode?.key == '3'
        "
      >
        <!-- Bono de PC -->
        <div class="field">
          <label for="computerAllowance" class="font-semibold"
            >Bono de PC</label
          >
          <p-inputGroup>
            <p-inputGroupAddon>
              <span *ngIf="selectedCurrencyCode" class="phone-prefix">{{
                selectedCurrencyCode
              }}</span>
            </p-inputGroupAddon>
            <p-inputNumber
              id="computerAllowance"
              mode="decimal"
              [minFractionDigits]="2"
              [maxFractionDigits]="2"
              inputId="withoutgrouping"
              [useGrouping]="false"
              [(ngModel)]="computerAllowance"
              (ngModelChange)="onChangeInput($event, 'computerAllowance')"
              [placeholder]="'Ingresa bono de PC'"
            ></p-inputNumber>
          </p-inputGroup>
          <div
            *ngIf="!computerAllowance && hasInteracted['computerAllowance']"
            style="color: red; font-size: 0.875rem; margin-top: 2px"
          >
            Este campo es obligatorio.
          </div>
        </div>

        <!-- Bono de Internet -->
        <div class="field">
          <label for="internetAllowance" class="font-semibold"
            >Bono de Internet</label
          >
          <p-inputGroup>
            <p-inputGroupAddon>
              <span *ngIf="selectedCurrencyCode" class="phone-prefix">{{
                selectedCurrencyCode
              }}</span>
            </p-inputGroupAddon>
            <p-inputNumber
              id="internetAllowance"
              mode="decimal"
              [minFractionDigits]="2"
              [maxFractionDigits]="2"
              inputId="withoutgrouping"
              [useGrouping]="false"
              [(ngModel)]="internetAllowance"
              (ngModelChange)="onChangeInput($event, 'internetAllowance')"
              [placeholder]="'Ingresa bono de internet'"
            ></p-inputNumber>
          </p-inputGroup>
          <div
            *ngIf="!internetAllowance && hasInteracted['internetAllowance']"
            style="color: red; font-size: 0.875rem; margin-top: 2px"
          >
            Este campo es obligatorio.
          </div>
        </div>
      </div>

      <div
        class="field-container"
        *ngIf="
          selectedWorkingMode?.key == '1' ||
          selectedWorkingMode?.key == '2' ||
          selectedWorkingMode?.key == '3'
        "
      >
        <!-- Bono de luz -->
        <div
          class="field"
          *ngIf="
            selectedWorkingMode?.key == '1' || selectedWorkingMode?.key == '3'
          "
        >
          <label for="electricityAllowance" class="font-semibold"
            >Bono de luz</label
          >
          <p-inputGroup>
            <p-inputGroupAddon>
              <span *ngIf="selectedCurrencyCode" class="phone-prefix">{{
                selectedCurrencyCode
              }}</span>
            </p-inputGroupAddon>
            <p-inputNumber
              id="electricityAllowance"
              mode="decimal"
              [minFractionDigits]="2"
              [maxFractionDigits]="2"
              inputId="withoutgrouping"
              [useGrouping]="false"
              [(ngModel)]="electricityAllowance"
              (ngModelChange)="onChangeInput($event, 'electricityAllowance')"
              [placeholder]="'Ingresa bono de luz'"
            ></p-inputNumber>
          </p-inputGroup>
          <div
            *ngIf="
              !electricityAllowance && hasInteracted['electricityAllowance']
            "
            style="color: red; font-size: 0.875rem; margin-top: 2px"
          >
            Este campo es obligatorio.
          </div>
        </div>

        <!-- Bono de Movilidad -->
        <div
          class="field"
          *ngIf="
            selectedWorkingMode?.key == '2' || selectedWorkingMode?.key == '3'
          "
        >
          <label for="transportationAllowance" class="font-semibold"
            >Bono de Movilidad</label
          >
          <p-inputGroup>
            <p-inputGroupAddon>
              <span *ngIf="selectedCurrencyCode" class="phone-prefix">{{
                selectedCurrencyCode
              }}</span>
            </p-inputGroupAddon>
            <p-inputNumber
              id="transportationAllowance"
              mode="decimal"
              [minFractionDigits]="2"
              [maxFractionDigits]="2"
              inputId="withoutgrouping"
              [useGrouping]="false"
              [(ngModel)]="transportationAllowance"
              (ngModelChange)="onChangeInput($event, 'transportationAllowance')"
              [placeholder]="'Ingresa bono de movilidad'"
            ></p-inputNumber>
          </p-inputGroup>
          <div
            *ngIf="
              !transportationAllowance &&
              hasInteracted['transportationAllowance']
            "
            style="color: red; font-size: 0.875rem; margin-top: 2px"
          >
            Este campo es obligatorio.
          </div>
        </div>
      </div>

      <div class="field-container">
        <!-- Salario -->
        <div class="field">
          <label for="salary" class="font-semibold">Salario Total</label>
          <p-inputGroup>
            <p-inputGroupAddon>
              <span *ngIf="selectedCurrencyCode" class="phone-prefix">{{
                selectedCurrencyCode
              }}</span>
            </p-inputGroupAddon>
            <p-inputNumber
              id="salary"
              mode="decimal"
              [minFractionDigits]="2"
              [maxFractionDigits]="2"
              inputId="withoutgrouping"
              [useGrouping]="false"
              [(ngModel)]="salary"
              (ngModelChange)="onChangeInput($event, 'salary')"
              [disabled]="true"
            ></p-inputNumber>
          </p-inputGroup>
        </div>
      </div>

      <!-- Confirmación de continuidad -->
      <div class="field">
        <label for="confirmation">¿Proseguirá el empleado en la empresa?</label>
        <div *ngFor="let continue of continues" class="confirmation">
          <p-checkbox
            [ngModel]="selectedContinuity?.key === continue.key"
            inputId="confirmation-{{ continue.key }}"
            [label]="continue.text"
            [binary]="true"
            [value]="continue"
            (onChange)="onCheckboxChange(continue)"
          />
        </div>
      </div>

      <!-- Carga de PDF -->
      <div
        class="field"
        *ngIf="
          selectedStatusContract?.key?.toString() !== '1' &&
          selectedStatusContract?.key?.toString() !== '4' &&
          selectedStatusContract?.key?.toString() !== '7'
        "
      >
        <label for="selectedFileName" class="font-semibold"
          >Subir contrato</label
        >
        <div
          class="file-container"
          (dragover)="onDragOver($event)"
          (dragleave)="onDragLeave($event)"
          (drop)="onDrop($event)"
        >
          <div class="file-preview" (click)="openFileUpload('pdf')">
            <div class="custom-file-display">
              <div class="custom-file-top">
                <i
                  [ngClass]="
                    selectedFileName ? 'pi pi-file-pdf' : 'pi pi-plus-circle'
                  "
                ></i>
                <span>{{ selectedFileName || "Subir un archivo" }}</span>
              </div>

              <p-divider *ngIf="!selectedFileName" align="center" type="solid">
                o
              </p-divider>

              <span *ngIf="!selectedFileName">Arrástralo aquí</span>
            </div>
          </div>
        </div>

        <div
          style="
            color: grey;
            font-size: 0.75rem;
            text-align: center;
            display: flex;
            flex-direction: column;
          "
        >
          <p style="padding: 0; margin: 0">
            Formatos admitidos: pdf. Tamaño máximo de archivo: 10 MB.
          </p>
        </div>

        <p-fileUpload
          #pdfUploader
          [accept]="'application/pdf'"
          [maxFileSize]="10000000"
          (onSelect)="onChangeFile($event)"
          [style]="{ display: 'none' }"
        ></p-fileUpload>
      </div>
    </div>
  </div>

  <div *ngIf="!isLoading" style="display: flex; justify-content: center">
    <!-- Botón Guardar Cambios -->
    <button
      type="button"
      pButton
      (click)="onSaveChanges()"
      [disabled]="isDisabledContract"
      label="Guardar cambios"
      class="btn"
    ></button>
  </div>
</p-dialog>
