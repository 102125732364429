import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  OnChanges,
  ViewEncapsulation,
} from '@angular/core';
import { IComboBase } from '../../../interfaces/base/base.interface';
import { CompanyService } from '../../../services/company.service';
import { EmployeeService } from '../../../services/employee.service';
import { DialogService } from 'primeng/dynamicdialog';
import { forkJoin } from 'rxjs';
import { CurrencyService } from '../../../services/currency.service';
import { CountryService } from '../../../services/country.service';
import { CityService } from '../../../services/city.service';
import { TypeDocumentService } from '../../../services/type-document.service';
import { JobPositionService } from '../../../services/job-position.service';
import { WorkingModeService } from '../../../services/working-modes.service';
import { StateContractService } from '../../../services/state-contracts.service';
import { ContinuityContractsService } from '../../../services/continuity-contracts.service';
import { AmountRenewalContractsService } from '../../../services/amount-renewal-contracts.service';

@Component({
  selector: 'app-employee-detail-modal',
  templateUrl: './employee-detail-modal.component.html',
  styleUrls: ['./employee-detail-modal.component.scss'],
  providers: [DialogService],
})
export class EmployeeDetailModalComponent implements OnChanges {
  @Input() visible: boolean = false;
  @Input() dataToView: any | null = null;
  @Output() visibleChange = new EventEmitter<boolean>();

  constructor(
    private currencyService: CurrencyService,
    private companyService: CompanyService,
    private employeeService: EmployeeService,
    private countryService: CountryService,
    private cityService: CityService,
    private typeDocumentService: TypeDocumentService,
    private jobPositionService: JobPositionService,
    private workingModeService: WorkingModeService,
    private stateContractService: StateContractService,
    private continuityContractsService: ContinuityContractsService,
    private amountRenewalContractsService: AmountRenewalContractsService,
    private cdr: ChangeDetectorRef
  ) {}

  isLoading: boolean = false;
  filteredOptions: IComboBase[] = [];

  // Listas de opciones
  companies: IComboBase[] = [];
  country: IComboBase[] = [];
  city: IComboBase[] = [];
  typeDocuments: IComboBase[] = [];
  statusEmployee: IComboBase[] = [];
  genders: IComboBase[] = [];
  currencies: IComboBase[] = [];
  jobPositions: IComboBase[] = [];
  typeContracts: IComboBase[] = [];
  workingModes: IComboBase[] = [];
  statusContracts: IComboBase[] = [];
  continues: IComboBase[] = [];
  renewContracts: IComboBase[] = [];

  // Valores seleccionados
  selectedCompany: IComboBase | null = null;
  selectedCountry: IComboBase | null = null;
  selectedCity: IComboBase | null = null;
  selectedTypeDocument: IComboBase | null = null;
  selectedStatusEmployee: IComboBase | null = null;
  selectedGender: IComboBase | null = null;
  selectedCurrency: IComboBase | null = null;
  selectedJobPosition: IComboBase | null = null;
  selectedTypeContract: IComboBase | null = null;
  selectedWorkingMode: IComboBase | null = null;
  selectedContinuity: IComboBase | null = null;
  selectedRenewContract: IComboBase | null = null;
  selectedPhoneCode: string | null = null;

  // Datos del empleado
  name: string | null = null;
  lastname: string | null = null;
  document: string | null = null;
  phone: string | null = null;
  phoneEmergency: string | null = null;
  address: string | null = null;
  email: string | null = null;
  birthDate: Date | null = null;
  selectedPhoto: string | null = null;

  ngOnChanges(): void {
    if (this.visible && this.dataToView) {
      this.isLoading = true;
      const employeeId = this.dataToView.employeeId;

      this.employeeService.getEmployeeById(employeeId).subscribe({
        next: (employee) => {
          const countryId = employee.countryId;
          const observables = {
            companies: this.companyService.comboCompany(),
            country: this.countryService.comboCountry(),
            city: this.cityService.comboCity(countryId),
            currencies: this.currencyService.comboCurrency(),
            jobPositions: this.jobPositionService.comboJobPosition(),
            workingModes: this.workingModeService.comboWorkingMode(),
            statusContracts: this.stateContractService.comboStateContract(),
            continues:
              this.continuityContractsService.comboContinuityContracts(),
            renewContracts:
              this.amountRenewalContractsService.comboAmountRenewalContracts(),
            typeDocuments:
              this.typeDocumentService.comboTypeDocument(countryId),
          };

          forkJoin(observables).subscribe({
            next: (data) => {
              this.companies = data.companies;
              this.country = data.country;
              this.city = data.city;
              this.currencies = data.currencies;
              this.jobPositions = data.jobPositions;
              this.workingModes = data.workingModes;
              this.statusContracts = data.statusContracts;
              this.continues = data.continues;
              this.renewContracts = data.renewContracts;
              this.typeDocuments = data.typeDocuments;

              this.statusEmployee = [
                { key: '1', text: 'Activo' },
                { key: '2', text: 'Inactivo' },
              ];

              this.genders = [
                { key: '1', text: 'Femenino' },
                { key: '2', text: 'Masculino' },
              ];

              this.selectedCompany =
                this.companies.find(
                  (c) => c.key.toString() === employee.companyId.toString()
                ) || null;

              this.selectedCountry =
                this.country.find(
                  (c) => c.key.toString() === employee.countryId.toString()
                ) || null;

              this.selectedCity =
                this.city.find(
                  (c) => c.key.toString() === employee.cityId.toString()
                ) || null;

              this.selectedJobPosition =
                this.jobPositions.find(
                  (c) => c.key.toString() === employee.jobPositionId.toString()
                ) || null;

              this.selectedWorkingMode =
                this.workingModes.find(
                  (t) => t.key.toString() === employee.workingModeId.toString()
                ) || null;

              this.selectedTypeDocument =
                this.typeDocuments.find(
                  (t) => t.key.toString() === employee.typedocumentid.toString()
                ) || null;

              type Gender = 'F' | 'M';

              const genderMap: Record<Gender, string> = {
                F: '1', // Femenino
                M: '2', // Masculino
              };

              const genderKey = genderMap[employee.gender as Gender];

              this.selectedGender =
                this.genders.find((g) => g.key === genderKey) || null;

              const statusMap = {
                true: '1', // Activo
                false: '2', // Inactivo
              };

              const statusKey =
                statusMap[
                  employee.employeeState.toString() as keyof typeof statusMap
                ];

              this.selectedStatusEmployee =
                this.statusEmployee.find((s) => s.key === statusKey) || null;

              this.name = employee.names;
              this.lastname = employee.lastNames;
              this.document = employee.employeeDocumentNumber;
              this.selectedPhoneCode = employee.phoneCode;
              this.phone = employee.phone;
              this.phoneEmergency = employee.phoneEmergency;
              this.address = employee.address;
              this.email = employee.email;
              this.birthDate = employee.birthDate;
              this.selectedPhoto = employee.imageEmployee;

              this.isLoading = false;
              this.cdr.detectChanges();
            },
            error: (err) => {
              this.isLoading = false;
              console.error('Error al cargar las listas de opciones:', err);
            },
          });
        },
        error: (err) => {
          this.isLoading = false;
          console.error('Error al cargar los datos del empleado:', err);
        },
      });
    }
  }

  closeDialog(): void {
    this.visible = false;
    this.visibleChange.emit(this.visible);
  }
}
