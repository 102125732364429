<!-- Título -->
<h2 class="title">Contratos y renovaciones</h2>

<!-- Botones, combo y barra de búsqueda -->
<app-page-acciones
  [hasButton]="false"
  [comboOptions]="monthOptions"
  [comboSelected]="currentMonth"
  (comboSelectedChange)="onMonthChange($event)"
  [searchQuery]="searchTerm"
  (searchQueryChange)="onSearch($event)"
>
</app-page-acciones>

<!-- Tabs y tabla dinámica -->
<app-page-container
  [tabs]="tabs"
  [displayedColumns]="displayedColumns"
  [dataSource]="dataSource"
  [actionButtons]="actionButtons"
  [headerClass]="currentHeaderClass"
  [showPreviewCard]="false"
  [isLoading]="isLoading"
  [customTemplates]="{
    employeeName: employeeNameTemplate,
    companyName: companyNameTemplate,
    jobPositionName: jobPositionNameTemplate,
    salary: salaryTemplate,
    startDate: startDateTemplate,
    endDate: endDateTemplate,
    fileContract: fileContractTemplate
  }"
  (tabSelected)="onTabSelected($event)"
  (rowSelected)="onRowSelected($event)"
  [hasCheckbox]="true"
>
  <ng-template #employeeNameTemplate let-row>
    <span class="truncate-text">{{ row.employeeName | capitalize }}</span>
  </ng-template>
  <ng-template #companyNameTemplate let-row>
    <span class="truncate-text">{{ row.companyName | capitalize }}</span>
  </ng-template>
  <ng-template #jobPositionNameTemplate let-row>
    <span class="truncate-text">{{ row.jobPositionName | capitalize }}</span>
  </ng-template>
  <ng-template #salaryTemplate let-row> S/. {{ row.salary }} </ng-template>
  <ng-template #startDateTemplate let-row>
    {{ formatDate(row.startDate) }}
  </ng-template>
  <ng-template #endDateTemplate let-row>
    {{ formatDate(row.endDate) }}
  </ng-template>
  <ng-template #fileContractTemplate let-row>
    <span class="truncate-text">
      <!-- Botón Aceptar -->
      <button
        type="button"
        pButton
        (click)="onDetail(row)"
        [disabled]="selectedRowId !== row.contractId"
        class="btn-detail"
      >
        <i class="pi pi-eye"></i>Ver detalle
      </button>
    </span>
  </ng-template>
</app-page-container>

<!-- Modal del detalle -->

<div class="contract-edit-modal">
  <app-contract-edit-modal
    [(visible)]="mostrarModalEditar"
    [dataToEdit]="dataToEdit"
    (visibleChange)="onDialogClose()"
    (editComplete)="onEditComplete()"
  ></app-contract-edit-modal>
</div>

<div class="contract-validate-modal">
  <app-contract-validate-modal
    [(visible)]="mostrarModalValidar"
    [dataToView]="dataToEdit"
    (visibleChange)="onDialogDetailClose()"
    (updateStateComplete)="onUpdateStateComplete()"
  >
  </app-contract-validate-modal>
</div>

<div class="modal-not-permission">
  <app-modal-not-permission
    [(visible)]="viewDeleted"
    [id]="contractId"
    (visibleChange)="onDialogDetailClose()"
  ></app-modal-not-permission>
</div>
