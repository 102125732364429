import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogService } from 'primeng/dynamicdialog';
import { CalendarModule } from 'primeng/calendar';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ButtonModule } from 'primeng/button';
import { MatPseudoCheckboxModule } from '@angular/material/core';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { CheckboxModule } from 'primeng/checkbox';
import { EmployeeContainerComponent } from './employee-container.component';
import { EmployeeRoutingModule } from './employee-routing.module';
import { EmployeeDetailModalComponent } from './components/employee-detail-modal/employee-detail-modal.component';
import { InputTextModule } from 'primeng/inputtext';
import { EmployeeRegisterComponent } from './components/employee-register/employee-register.component';
import { EmployeeComponent } from './components/employee/employee.component';
import { ContractComponent } from './components/contract/contract.component';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { ContractRegisterComponent } from './components/contract-register/contract-register.component';
import { DividerModule } from 'primeng/divider';
import { ContractDetailComponent } from './components/contract-detail/contract-detail.component';
import { BadgeModule } from 'primeng/badge';
import { AvatarModule } from 'primeng/avatar';
import { TabViewModule } from 'primeng/tabview';
import { EmployeeEditModalComponent } from './components/employee-edit-modal/employee-edit-modal.component';
import { ContractEditModalComponent } from './components/contract-edit-modal/contract-edit-modal.component';
import { UpdateStateContractModalComponent } from './components/contract-validate-modal/contract-validate-modal.component';


@NgModule({
  declarations: [EmployeeContainerComponent,
    EmployeeComponent,
    ContractComponent,
    EmployeeRegisterComponent,
    ContractRegisterComponent,
    EmployeeDetailModalComponent,
    ContractDetailComponent,
    EmployeeEditModalComponent,
    ContractEditModalComponent,
    UpdateStateContractModalComponent
  ],
  imports: [
    CommonModule,
    // BrowserModule,
    EmployeeRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    CalendarModule,
    MatPseudoCheckboxModule,
    AutoCompleteModule,
    ButtonModule,
    InputSwitchModule,
    CheckboxModule,
    InputTextModule,
    InputGroupModule,
    InputGroupAddonModule,
    DividerModule,
    BadgeModule,
    AvatarModule,
    TabViewModule
  ],
  providers: [DialogService],
})
export class EmployeeModule {}
