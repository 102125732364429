import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { monthOptions } from '../../../utils/helpers';
import { IComboBase } from '../../../interfaces/base/base.interface';
import { CompanyService } from '../../../services/company.service';
import { EmployeeLocalService } from '../../services/employee.service';

@Component({
  selector: 'app-contract',
  templateUrl: './contract.component.html',
  styleUrls: ['./contract.component.scss'],
})
export class ContractComponent {
  dataSource: any[] = [];
  mostrar: boolean = false;
  dataToEdit: any = null;
  mostrarModalValidar: boolean = false;
  mostrarModalEditar: boolean = false;
  mostrarModalDetalle: boolean = false;
  dataDetail: any = null;
  dataDetailContract: any = null;

  viewDeleted: boolean = false;
  contractId: number = 0;

  // Opciones del combo
  monthOptions: IComboBase[] = [{ key: '00', text: 'Todos' }, ...monthOptions];
  currentMonth: IComboBase | null = null;

  isLoading: boolean = true;

  // Término de búsqueda
  searchTerm = '';

  // Mes seleccionado
  selectedMonth: IComboBase | null = null;
  userData: any = null;

  // Fila seleccionada
  selectedRowId: number | null = null;

  headerClasses: { [key: string]: string } = {
    '1': 'header-keola',
    '2': 'header-inresort',
    '3': 'header-inclub',
    '4': 'header-ribera',
    '5': 'header-keolafood',
    '6': 'header-valle',
  };

  currentHeaderClass: string = 'header-keola';

  constructor(
    private employeeLocalService: EmployeeLocalService,
    private companyService: CompanyService,
    private router: Router
  ) {
    this.currentMonth = this.monthOptions[0];
    this.selectedMonth = { key: '00', text: 'Todos' };
  }

  tabs: IComboBase[] = [];
  selectedTabKey: string | null = null;

  displayedColumns = [
    { key: 'employeeName', header: 'Empleado' },
    { key: 'companyName', header: 'Empresa' },
    { key: 'jobPositionName', header: 'Cargo' },
    { key: 'salary', header: 'Sueldo' },
    { key: 'startDateEmployee', header: 'F. Inicio' },
    { key: 'endDate', header: 'F. Fin' },
    { key: 'fileContract', header: 'Contrato' },
    { key: 'stateContract', header: 'Status' },
  ];

  actionButtons = [
    {
      icon: (row: any) =>
        row.stateContractId === 3 ? 'pi pi-eye' : 'pi pi-check-square',
      color: '#ED672A',
      tooltip: (row: any) =>
        row.stateContractId === 3 ? 'Detalle' : 'Validar',
      action: (row: any) => {
        this.dataToEdit = {
          ...row,
          empresa: this.tabs.find((tab) => tab.key === this.selectedTabKey),
        };
        this.mostrarModalValidar = true;
      },
      visible: (row: any) => true,
    },
    {
      icon: 'pi pi-pen-to-square',
      color: '#ED672A',
      tooltip: 'Editar',
      action: (row: any) => {
        this.dataToEdit = {
          ...row,
          empresa: this.tabs.find((tab) => tab.key === this.selectedTabKey),
        };
        this.mostrarModalEditar = true;
      },
      visible: (row: any) => row.stateContractId !== 3,
    },
    {
      icon: 'pi pi-trash',
      color: '#ED672A',
      tooltip: 'Eliminar',
      action: (row: any) => {
        this.contractId = row.solicitudId;
        this.viewDeleted = true;
      },
      visible: (row: any) => row.stateContractId !== 3,
    },
  ];

  ngOnInit(): void {
    const userDataString = localStorage.getItem('access');
    if (userDataString) {
      this.userData = JSON.parse(userDataString);
    }
    this.companyService.comboCompany().subscribe({
      next: (data) => {
        this.tabs = data;
        this.selectedTabKey = this.tabs[0].key;
        this.onTabSelected(this.selectedTabKey);
      },
      error: (err) => {
        console.error('Error al obtener la lista de empresas:', err);
        this.filterData();
      },
    });
  }

  onDialogClose() {
    this.mostrar = false;
  }

  onDialogDetailClose() {
    this.mostrarModalDetalle = false;
    this.mostrarModalValidar = false;
    this.dataDetail = null;
  }

  onMonthChange(selected: IComboBase): void {
    this.selectedMonth = selected;
    this.filterData();
  }

  onSearch(query: string): void {
    this.searchTerm = query;
    this.filterData();
  }

  filterData(): void {
    this.isLoading = true;

    const filters = {
      search: this.searchTerm,
      month: this.selectedMonth?.key == '00' ? null : this.selectedMonth?.key,
      companyId: this.selectedTabKey || null,
    };

    this.employeeLocalService.postContractByCompany(filters).subscribe({
      next: (data: any[]) => {
        this.dataSource = data;
        this.isLoading = false;
      },
      error: (err: any) => {
        this.isLoading = false;
        console.error('Error al obtener los datos:', err);
      },
    });
  }

  onTabSelected(tabKey: string): void {
    this.selectedTabKey = tabKey;
    this.currentHeaderClass = this.headerClasses[tabKey] || 'header-keola';
    this.filterData();
  }

  onRowSelected(row: any): void {
    this.selectedRowId = row.contractId;
  }

  onRegisterComplete(): void {
    this.filterData();
  }

  onUpdateStateComplete(): void {
    this.filterData();
  }

  onEditComplete(): void {
    this.filterData();
  }

  onDeleteComplete(): void {
    this.filterData();
  }

  formatDate(dateString: string): string {
    if (!dateString) return '';

    // Mapeo de nombres de meses en inglés a números
    const monthsMap: { [key: string]: string } = {
      January: '01',
      February: '02',
      March: '03',
      April: '04',
      May: '05',
      June: '06',
      July: '07',
      August: '08',
      September: '09',
      October: '10',
      November: '11',
      December: '12',
    };

    // Extraer los datos de la fecha usando una expresión regular
    const dateParts = dateString.match(/(\w+)\s(\d+)\sdel\s(\d+)/);
    if (!dateParts) return dateString;

    const month = monthsMap[dateParts[1]];
    const day = dateParts[2].padStart(2, '0');
    const year = dateParts[3].slice(-2);

    return `${day}/${month}/${year}`;
  }

  extractFileName(fileUrl: string): string {
    const lastDashIndex = fileUrl.lastIndexOf('-', fileUrl.lastIndexOf('.'));
    const fileName = fileUrl.substring(lastDashIndex + 1);
    return fileName;
  }

  onDetail(row: any) {
    this.dataDetailContract = row;
    this.router.navigate([
      `/dashboard/employee/details/${this.dataDetailContract.employeeId}`,
    ]);
  }
}
