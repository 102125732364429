<!-- Título -->
<h2 class="title">Lista de Empleados</h2>

<!-- Botones, combo y barra de búsqueda -->
<app-page-acciones
  [buttonText]="'Exportar data'"
  [buttonIcon]="'pi pi-file-export'"
  [buttonColor]="'#04a563'"
  (buttonClick)="onExport()"
  [buttonTextSecond]="'Agregar empleado'"
  [buttonIconSecond]="'pi pi-plus'"
  [buttonColorSecond]="'#04a563'"
  (buttonClickSecond)="onNewRegister()"
  [hasButtonSecond]="true"
  [comboOptions]="monthOptions"
  [comboSelected]="currentMonth"
  (comboSelectedChange)="onMonthChange($event)"
  [hasComboAux]="true"
  [comboAuxOptions]="renewOptions"
  [comboAuxSelected]="currentRenew"
  (comboAuxSelectedChange)="onRenewChange($event)"
  [searchQuery]="searchTerm"
  (searchQueryChange)="onSearch($event)"
>
</app-page-acciones>

<!-- Tabs y tabla dinámica -->
<app-page-container
  [tabs]="tabs"
  [displayedColumns]="displayedColumns"
  [dataSource]="dataSource"
  [actionButtons]="actionButtons"
  [headerClass]="currentHeaderClass"
  [showPreviewCard]="false"
  [isLoading]="isLoading"
  [customTemplates]="{
    names: namesTemplate,
    lastNames: lastnamesTemplate,
    phoneEmergency: phoneEmergencyTemplate,
    email: emailTemplate,
    employeeState: employeeStateTemplate,
    startDate: startDateTemplate
  }"
  (tabSelected)="onTabSelected($event)"
  (rowSelected)="onRowSelected($event)"
  [hasCheckbox]="true"
>
  <ng-template #namesTemplate let-row>
    {{ row.names | capitalize }}
  </ng-template>
  <ng-template #lastnamesTemplate let-row>
    {{ row.lastNames | capitalize }}
  </ng-template>
  <ng-template #phoneEmergencyTemplate let-row>
    +{{ row.phoneCode }} {{ row.phone }}
  </ng-template>
  <ng-template #emailTemplate let-row>
    <span class="truncate-text">{{ row.email }}</span>
  </ng-template>
  <ng-template #employeeStateTemplate let-row>
    {{ row.employeeState == true ? "Activo" : "Inactivo" }}
  </ng-template>
  <ng-template #startDateTemplate let-row>
    {{ formatDate(row.startDate) }}
  </ng-template>
</app-page-container>

<!-- Modal del detalle -->
<!-- EmployeeEditModalComponent -->
<div class="employee-edit-modal">
  <app-employee-edit-modal
    [(visible)]="mostrarModalEditar"
    [dataToEdit]="dataToEdit"
    (visibleChange)="onDialogClose()"
    (editComplete)="onEditComplete()"
  ></app-employee-edit-modal>
</div>

<!-- EmployeeDetailModalComponent -->
<div class="employee-detail-modal">
  <app-employee-detail-modal
    [(visible)]="mostrarModalDetalle"
    [dataToView]="dataDetail"
    (visibleChange)="onDialogDetailClose()"
  ></app-employee-detail-modal>
</div>

<!-- ModalNotPermissionComponent -->
<div class="modal-not-permission">
  <app-modal-not-permission
    [(visible)]="viewDeleted"
    [id]="employeeId"
    (visibleChange)="onDialogDetailClose()"
  ></app-modal-not-permission>
</div>
