<div class="card">
  <div class="column">
    <h2 class="label">
      <button type="button" pButton (click)="goBack()" class="btn-back">
        <i class="pi pi-arrow-circle-left"></i>
      </button>
      Ver detalle
    </h2>

    <!-- Mat-Tab con selección dinámica -->
    <mat-tab-group
      class="tab-group"
      [selectedIndex]="selectedTabKey - 1"
      (selectedIndexChange)="onTabSelected($event)"
    >
      <mat-tab
        *ngFor="let tab of tabs"
        [label]="tab.text | capitalize"
      ></mat-tab>
    </mat-tab-group>

    <div *ngIf="selectedTabKey === 1" class="contract-status">
      <div class="row">
        <div class="column preview-column">
          <!-- Preview Card (única) -->
          <div class="preview-card">
            <!-- Loading spinner mientras se carga el documento -->
            <div class="loading-spinner" *ngIf="isLoading">
              <p-progressSpinner
                styleClass="custom-spinner"
              ></p-progressSpinner>
              <p>Cargando documento...</p>
            </div>

            <!-- Contenido del iframe -->
            <div class="preview-content" *ngIf="selectedDocument && !isLoading">
              <iframe
                [src]="selectedDocument"
                width="100%"
                height="100%"
                frameborder="0"
                title="PDF Preview"
                (load)="onDocumentLoad()"
                (error)="onDocumentError()"
              ></iframe>
            </div>
       
            <div
              class="preview-content"
              *ngIf="!selectedDocument && !isLoading "
            >
              Previsualización no disponible
            </div>
          </div>
        </div>

        <div class="column">
          <div class="loading-spinner" *ngIf="isLoading">
            <p-progressSpinner styleClass="custom-spinner"></p-progressSpinner>
          </div>

          <div class="column" *ngIf="!isLoading">
            <div class="field">
              <label for="employee" class="font-semibold">Empleado</label>
              <span>{{ detailContract?.employeeName | capitalize }}</span>
            </div>

            <div class="field-container">
              <div class="field">
                <label class="font-semibold">Ingreso a planilla</label>
                <span class="font-bold">
                  {{ detailContract?.startDateEmployee | date : "dd/MM/yyyy" }}
                </span>
              </div>
              <div class="field">
                <label class="font-semibold">Empresa</label>
                <span class="font-bold">{{
                  detailContract?.companyName | capitalize
                }}</span>
              </div>
            </div>

            <!-- Lista de contratos -->
            <div
              class="contract-item"
              *ngFor="let contract of detailContract?.contracts; let i = index"
              [class.selected]="selectedContractIndex === i"
            >
              <div class="contract-info">
                <div class="row">
                  <p-checkbox
                    [(ngModel)]="isSelected[i]"
                    [binary]="true"
                    inputId="confirmation"
                    (onChange)="onContractSelect(i, contract?.fileContract)"
                  />
                  <div class="column">
                    <label for="startDate">Fecha</label>
                    <span class="start-date">{{
                      contract.startDateContract | date : "dd/MM/yyyy"
                    }}</span>
                  </div>
                  <div class="column">
                    <label for="status">Status</label>
                    <span
                      class="status"
                      [ngClass]="getStatusClass(contract.stateContractId)"
                    >
                      {{ contract.stateContract }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Botón de Renovar -->
          <div style="display: flex; justify-content: center; gap: 10px">
            <button
              type="button"
              pButton
              label="Renovar contrato"
              (click)="onNewRenewalContract()"
              class="renew-button"
            ></button>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="selectedTabKey === 2">
      <!-- Botones, combo y barra de búsqueda -->
      <app-page-acciones
        [hasButton]="false"
        [comboOptions]="monthOptions"
        [comboSelected]="currentMonth"
        (comboSelectedChange)="onMonthChange($event)"
        [searchQuery]="searchTerm"
        (searchQueryChange)="onSearch($event)"
      >
      </app-page-acciones>
      <!-- Tabs y tabla dinámica -->
      <app-page-container
        [displayedColumns]="displayedColumns"
        [dataSource]="historyContract"
        [showPreviewCard]="false"
        [isLoading]="isLoadingHistory"
        [customTemplates]="{
          companyName: companyNameTemplate,
          jobPositionName: jobPositionNameTemplate,
          salary: salaryTemplate,
          startDate: startDateTemplate,
          endDate: endDateTemplate
        }"
        (rowSelected)="onRowSelected($event)"
        [hasCheckbox]="false"
      >
        <ng-template #companyNameTemplate let-row>
          <span class="truncate-text">{{ row.companyName | capitalize }}</span>
        </ng-template>
        <ng-template #jobPositionNameTemplate let-row>
          <span class="truncate-text">{{
            row.jobPositionName | capitalize
          }}</span>
        </ng-template>
        <ng-template #salaryTemplate let-row>
          S/. {{ row.salary }}
        </ng-template>
        <ng-template #startDateTemplate let-row>
          {{ formatDate(row.startDate) }}
        </ng-template>
        <ng-template #endDateTemplate let-row>
          {{ formatDate(row.endDate) }}
        </ng-template>
      </app-page-container>
    </div>
  </div>
</div>
